import React, {FunctionComponent} from 'react'
import cn from 'classnames'

export type DividerProps = {
  tall?: boolean
  className?: string
}

const defaultProps = {
  tall: false,
}

const Divider: FunctionComponent<DividerProps> = ({tall, className, ...rest}) => <div className={cn('common-divider', tall && 'tall', className)} {...rest} />

Divider.defaultProps = defaultProps

export default Divider
