import React, {FunctionComponent, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import BaseLayout from '../../../layouts/BaseLayout/BaseLayout'
import {Link} from '../../../components'
import {MainContentHeader} from '../../../components/layout/MainContentHeader'
import {MainContentWrapper} from '../../../components/layout/MainContentWrapper'
import {setNavigation} from '../../../store/navigation/actions'
import {PageMetadata} from '../../../components/PageMetadata'
import {metadata} from '../../../constants/metadata'
import {DonationActionButton} from '../../../components/DonationActionButton'
import {RootState} from '../../../store'
import {userSelector} from '../../../store/selectors'
import {UserState} from '../../../store/user/types'
// import { Document, Page } from 'react-pdf'
// import Feb1st2023pdf from '../../../public/images/prismic/WCG_Newsletter_Feb_1_2023.pdf'

const NotFound: FunctionComponent = () => {
  const dispatch = useDispatch()
  const {loggedIn} = useSelector<RootState, UserState>(userSelector)
  useEffect(() => {
    dispatch(setNavigation({section: 'Home', type: 'main', highlight: ''}))
  }, [])
  return (
    <BaseLayout>
      <PageMetadata {...metadata.newsletters} />
      <MainContentHeader title="The World Community Grid Newsletters" />
      <br />
      <MainContentWrapper>
        <Link href={`${process.env.PUBLIC_URL}/images/prismic/WCG_Newsletter_Feb_1_2023.pdf`} target="_blank">
          2023-02-01 - February WCG Newsletter
        </Link>
        <br />
        <Link href={`${process.env.PUBLIC_URL}/images/prismic/WCG_Newsletter_April_2023.pdf`} target="_blank">
          2023-04-13 - April WCG Newsletter
        </Link>
        <br />
        <Link href={`${process.env.PUBLIC_URL}/images/prismic/WCG_Newsletter_Oct_2023.pdf`} target="_blank">
          2023-10-06 - October WCG Newsletter
        </Link>
      </MainContentWrapper>
    </BaseLayout>
  )
}

export default NotFound
