import React, {FunctionComponent} from 'react'
import {Button} from 'carbon-components-react'
import {ArrowRight20} from '@carbon/icons-react'
import {useHistory} from 'react-router'
import cn from 'classnames'
import {useStatus} from '../../pages/Home/components/sections/HeroSection/helpers'
import {internal} from '../../constants'
import {Status} from '../../pages/Home/components/sections/HeroSection/types'
import {goByUrl} from '../../utils'
import {JoinActionButtonProps} from './types'

const JoinActionButton: FunctionComponent<JoinActionButtonProps> = ({className, loggedIn, currentProjectShortName, signedUpProject}) => {
  const history = useHistory()
  const [status] = useStatus(loggedIn, signedUpProject)
  const handleJoinActionButtonClick = (status: Status, signedUpProject: string | undefined) => () => {
    const action = {
      [Status.notLoggedIn]: () => {
        goByUrl(`${internal.Join.url}?projectToAdd=${currentProjectShortName}`, history)
      },
      [Status.loggedIn]: () => {
        currentProjectShortName && goByUrl(internal['Support this project'].url(currentProjectShortName), history)
      },
      [Status.loggedInAndSignedUp]: () => {
        goByUrl(`${internal['Project News'].url(signedUpProject)}`, history)
      },
    }
    action[status]()
  }
  const getJoinActionButtonText = (status: Status) => {
    const text = {
      [Status.notLoggedIn]: 'Join',
      [Status.loggedIn]: 'Support this project',
      [Status.loggedInAndSignedUp]: 'Catch up on the latest news',
    }
    return text[status]
  }
  return (
    <Button className={cn('join-card__action', className)} renderIcon={ArrowRight20} onClick={handleJoinActionButtonClick(status, signedUpProject)}>
      {getJoinActionButtonText(status)}
    </Button>
  )
}

export default JoinActionButton
