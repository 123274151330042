import {Projects, PrismicProjects} from '../../services/types'

export enum ACTION_TYPE {
  SET_PROJECTS = 'SET_PROJECTS',
  SET_PRISMIC_PROJECTS = 'SET_PRISMIC_PROJECTS',
}

export interface ProjectsState {
  projects: any[]
  prismicProjects: any[]
}

export interface SetProjectsAction {
  type: typeof ACTION_TYPE.SET_PROJECTS
  payload: Projects
}

export interface SetPrismicProjectsAction {
  type: typeof ACTION_TYPE.SET_PRISMIC_PROJECTS
  payload: PrismicProjects
}

export type ProjectActions = SetProjectsAction | SetPrismicProjectsAction
