export const metadata = {
  default: {
    title: 'World Community Grid',
    description:
      'World Community Grid enables anyone with a computer, smartphone or tablet to donate their unused computing power to advance cutting-edge scientific research on topics related to health, poverty and sustainability.',
    type: 'website',
    site: '@WCGrid',
    image: '/images/logo_images/wcg_logo_square_new_200x200.png',
  },
  //Utility pages
  newsletters: {
    title: 'Newsletter | World Community Grid',
    description: 'News and developments at the WCG as a digest.',
  },
  donations: {
    title: 'Donations | World Community Grid',
    description: 'Donate to the scientific endeavours of World Community Grid projects via UHN.',
  },
  join: {
    title: 'Join | World Community Grid',
  },
  login: {
    title: 'Log in | World Community Grid',
  },
  notFound: {
    title: 'Page not found | World Community Grid',
    description: 'This page was not found',
  },
  download: {
    title: 'Download | World Community Grid',
    description: 'Instructions to download and install the World Community Grid software',
  },
  //Member pages
  contribution: {
    overview: {
      title: 'Overview | World Community Grid',
      description: "Overview of the member's contribution to World Community Grid",
    },
    history: {
      title: 'History | World Community Grid',
      description: "A history of the member's contribution statistics",
    },
    milestones: {
      title: 'Milestones | World Community Grid',
      description: "A history of the member's achievements while contributing to World Community Grid",
    },
    devices: {
      title: 'Devices | World Community Grid',
      description: "A devices of the member's contribution to World Community Grid",
    },
    device: {
      title: 'Devices | World Community Grid',
      description: "A device history and statistics of the member's contribution to World Community Grid",
    },
    results: {
      title: 'Results | World Community Grid',
      description: "A results of the member's contribution to World Community Grid",
    },
    workunit: {
      title: 'Workunit | World Community Grid',
      description: 'Details about a workunit processed on World Community Grid',
    },
  },
}
