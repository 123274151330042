import {internal} from '../../constants'
import nav from '../../constants/navigation/navigation'
import {NavItem} from '../../constants/navigation/types'

export interface SideNavMenuNavItems {
  title: string
  contextual?: NavItem
  additional: {is: boolean}
}
interface Header {
  [key: string]: any
  topNavMenu: {items: NavItem[]}
  sideNavMenu?: {[key: string]: unknown; items: SideNavMenuNavItems[]}
  accountMenu: {
    loggedIn: {items: NavItem[]}
  }
}

export const header: Header = {
  wcgLogo: {url: internal.WCG.url},
  topNavMenu: {
    items: nav,
  },
  accountMenu: {
    loggedIn: {
      items: [
        {title: 'My contribution', url: internal['My contribution'].url},
        {title: 'Profile and settings', url: internal['Profile and settings'].url},
        {title: 'Download', url: internal.Download.url},
        {title: 'Admin', url: internal.Admin.url},
        {title: 'Log out', url: internal['Log Out'].url},
      ],
    },
  },
}
