export enum TextNameAccordance {
  White = 'hero-text-white',
  Black = 'hero-text-black',
}

export enum OverlayNameAccordance {
  None = '',
  Glow = 'hero-glow',
  Shadow = 'hero-shadow',
  'Light Red' = 'hero-light-red-accent',
}

export enum Status {
  loggedInAndSignedUp,
  loggedIn,
  notLoggedIn,
}

export type TextNameAccordanceTypes = keyof typeof TextNameAccordance
export type OverlayNameAccordanceTypes = keyof typeof OverlayNameAccordance
export type AccordanceTypes = TextNameAccordanceTypes | OverlayNameAccordanceTypes | undefined
export type AccordanceMaps = typeof TextNameAccordance | typeof OverlayNameAccordance

export interface Paragraph {
  type: string
  text: string
  spans?: null[] | null
}

export interface HomeSectionProps {
  loggedIn: boolean
  signedUpProject?: string
  learnLink?: string
  imageOverlayStyle?: OverlayNameAccordanceTypes
  textColor?: TextNameAccordanceTypes
  heroImageUrl?: string
  titleParagraphs?: Paragraph[]
  text?: string
  heroShortName?: string
}
