import {User} from '../../services/types'

export type UserState = User
export enum ACTION_TYPE {
  SET_USER = 'SET_USER',
  DEL_USER = 'DEL_USER',
}

export interface SetUserAction {
  type: typeof ACTION_TYPE.SET_USER
  payload: User
}

export interface DelUserAction {
  type: typeof ACTION_TYPE.DEL_USER
}

export type UserActions = SetUserAction | DelUserAction
