import React, {FunctionComponent, useEffect, useState} from 'react'
import cn from 'classnames'
import _filter from 'lodash.filter'
import {Add20} from '@carbon/icons-react'
import {Card} from '../Card'
import {Link} from '../../../../../../components/Link'
import {internal} from '../../../../../../constants'
import {api} from '../../../../../../services'
import {Projects} from '../../../../../../services/types'
import {Project} from '../../../../../../services/api/types'

export interface ComponentProps {
  className?: string
}

const lengthOfProjects = 3

const ProjectsCard: FunctionComponent<ComponentProps> = ({className, ...rest}) => {
  const [projects, setProjects] = useState<Projects | undefined>(undefined)

  useEffect(() => {
    const getData = async () => {
      const [projectPreferences, projectsResponse] = await Promise.all([api.getProjectPreferences('default'), api.getProjects()])
      const preferredProjectShortNames = projectPreferences && projectPreferences.preferredProjectList
      const projects = projectsResponse && projectsResponse.data
      const filteredProjects = _filter(
        projects,
        (project) => project.status === 'Active' && Array.isArray(preferredProjectShortNames) && preferredProjectShortNames.includes(project.shortName),
      )
      filteredProjects.length = lengthOfProjects
      setProjects(filteredProjects)
    }
    getData()
  }, [])

  return (
    <Card className={cn('milestones-card', className)} {...rest}>
      <Card.InnerWrapper>
        <Card.Header className="milestones-card-horizontal-wrapper">
          <Card.Title className="milestones-card-title">Projects</Card.Title>
          <Link href={internal['My projects'].url}>
            <Card.Text>View all</Card.Text>
          </Link>
        </Card.Header>
        <Card.List>
          {projects ? (
            projects.map((project: Project, index: number) => {
              return <Card.ListRow key={index} text={project.name} icon={`/images/icons/${project.shortName}.svg`} />
            })
          ) : (
            <Card.ListSkeleton />
          )}
          <Card.ListRow
            className="projects-list-row__add-project"
            text={
              <Link href={internal['My projects'].url} className="projects-link">
                <Add20 color="blue" className="icon" />
                Add projects
              </Link>
            }
          />
        </Card.List>
      </Card.InnerWrapper>
    </Card>
  )
}

export default ProjectsCard
