import React, {FunctionComponent, useState} from 'react'
import cn from 'classnames'
import _sortBy from 'lodash.sortby'
import Pagination from 'carbon-components-react/lib/components/Pagination'
import {compose} from 'redux'
import {Card} from '../Card'
import {convertBadgeTimeToDaysOrYearString, convertRecruitingBadgeLevel} from '../../../../../../utils'
import {BadgesContainer} from './components/BadgesContainer'
import {Badge} from './components/Badge'

const ROWS = 2
const PAGE_SIZES: [6, 7, 8, 9, 10, 11, 12, 13, 14] = [6, 7, 8, 9, 10, 11, 12, 13, 14]
type PageSize = typeof PAGE_SIZES[number]

const getColumnBadgesFromBadges = (array: any) =>
  array &&
  array.reduce((result: Array<any>, value: any, index: any, array: any) => {
    if (index % ROWS === 0) {
      result.push(array.slice(index, index + ROWS))
    }
    return result
  }, [])

const getBadgeIconUrl = (badgeType: string, projectShortName: string, badgeLevel: string): string | undefined => {
  if (badgeType === undefined || badgeLevel === undefined) return
  return `/images/pb/${badgeType === 'P' ? projectShortName : '9999'}_${badgeLevel}_72.png `
}

const getBadgeTooltipText = (badgeType: string, badgeLevel: number, duration: number, projectName: string): string => {
  if (badgeType === undefined) return ''
  return badgeType === 'R'
    ? `${convertRecruitingBadgeLevel(badgeLevel || 0)} badge for recruiting`
    : `${convertBadgeTimeToDaysOrYearString(duration || 0)} badge for ${projectName}`
}

const fillWithEmptyBadges = (array: any, fillAmount: number) => {
  const arrayCopy = [...array]
  const emptyElements = new Array(fillAmount).fill({badgeType: undefined})
  Array.prototype.push.apply(arrayCopy, emptyElements)
  return arrayCopy
}

const getBadgesContainerSkeleton = (number: number) => {
  return Array.from(Array(number / ROWS).keys()).map((_, index) => (
    <BadgesContainer.Column key={index}>
      {Array.from(Array(2).keys()).map((_, index) => (
        <BadgesContainer.Element key={index}>
          <Badge.Skeleton />
        </BadgesContainer.Element>
      ))}
    </BadgesContainer.Column>
  ))
}

interface BadgesCardProps {
  className?: string
  stats: any
}

const sortBadges = (badges: any[]): typeof badges => {
  return badges && _sortBy(badges, ['dateEarned'])
}

const BadgesCard: FunctionComponent<BadgesCardProps> = ({className, stats, ...rest}) => {
  const badges = stats.badges && sortBadges(stats.badges).reverse()
  const [currentPageSize, setCurrentPageSize] = useState<PageSize>(14)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const handlePageChange = ({page}: {page: number; pageSize: number}) => {
    setCurrentPage(page)
  }

  const handleResize = (childrenOffsetWidth: number, containerContentWidth: number, firstChildWidth: number) => {
    const fittedChildrenCountPerPage = Math.floor(containerContentWidth / firstChildWidth)
    const fittedBadgesCountPerPage = fittedChildrenCountPerPage * ROWS
    if (isFinite(fittedBadgesCountPerPage)) {
      setCurrentPageSize(fittedBadgesCountPerPage as PageSize)
    }
  }

  const getDisplayedBadges = (badges: any) => {
    const firstDisplayedBadgeIndex = (currentPage - 1) * currentPageSize
    const missingBadgesAmount = badges ? currentPageSize - (badges.length % currentPageSize) : 0
    return badges && fillWithEmptyBadges(badges, missingBadgesAmount).slice(firstDisplayedBadgeIndex, firstDisplayedBadgeIndex + currentPageSize)
  }

  return (
    <Card className={cn('badges-card', className)} {...rest}>
      <Card.InnerWrapper>
        <Card.Title className="milestones-card-title">Top badges</Card.Title>
      </Card.InnerWrapper>
      <Card.Divider />
      <div className="card-content">
        <BadgesContainer onResize={handleResize}>
          {badges
            ? compose(
                getColumnBadgesFromBadges,
                getDisplayedBadges,
              )(badges).map((columnBadges: any[], index: number) => (
                <BadgesContainer.Column key={index}>
                  {columnBadges.map(({badgeType, projectShortName, badgeLevel, badgeRuntime, projectName}: any, index: number) => (
                    <BadgesContainer.Element key={index}>
                      <Badge
                        tooltipText={getBadgeTooltipText(badgeType, badgeLevel, badgeRuntime, projectName)}
                        icon={getBadgeIconUrl(badgeType, projectShortName, badgeLevel)}
                        level={badgeLevel}
                      />
                    </BadgesContainer.Element>
                  ))}
                </BadgesContainer.Column>
              ))
            : getBadgesContainerSkeleton(currentPageSize)}
        </BadgesContainer>
      </div>
      <Card.Divider />
      <div className="card-footer horizontal-wrapper align-self-end">
        <Pagination
          pageSizes={PAGE_SIZES}
          pageSize={currentPageSize}
          page={currentPage}
          totalItems={(badges && badges.length) || 0}
          onChange={handlePageChange}
        />
      </div>
    </Card>
  )
}

export default BadgesCard
