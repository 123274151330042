import {api} from '../../services'
import {AppThunkAction} from '../index'
//import {getPrismic} from '../../services/api/content/prismic-projects-by-shortname'
import {filterActiveProjects} from '../../utils'
import {ACTION_TYPE} from './types'

export const setProjects = (projects: any) => ({
  type: ACTION_TYPE.SET_PROJECTS,
  payload: projects,
})

export const setPrismicProjects = (projects = []) => ({
  type: ACTION_TYPE.SET_PRISMIC_PROJECTS,
  payload: projects,
})

export const fetchProjects = (): AppThunkAction<void> => async (dispatch) => {
  const projectsResponse = await api.getProjects()
  const projects = (projectsResponse && projectsResponse.data) || []
  dispatch(setProjects(projects))

  const activeShortNames = filterActiveProjects(projects).map((project) => project.shortName)
  const prismicResponse: any = await api.getPrismicProjectsByShortNames(activeShortNames)
  const prismic = prismicResponse && prismicResponse.results.map((result: any) => result.data)
  dispatch(setPrismicProjects(prismic))
}
