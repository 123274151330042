import {Reducer} from 'redux'
import {ACTION_TYPE, AppStateActions, AppErrorState} from './types'

const initialState: AppErrorState = {
  hasError: false,
  errorSource: '',
}

export const appStateReducer: Reducer<AppErrorState, AppStateActions> = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SET_ERROR:
      return {...state, ...action.payload, hasError: true}
    case ACTION_TYPE.CLEAR_ERROR:
      return initialState
    default:
      return state
  }
}
