import React, {FunctionComponent, ReactElement} from 'react'
import cn from 'classnames'
import {CarbonIconType} from '@carbon/icons-react'
import {DiscoverCard} from '../DiscoverCard'

interface FeatureCardProps {
  icon: CarbonIconType
  title: string
  text: string
  className?: string
}

const defaultProps = {
  icon: () => null,
  title: '',
  text: '',
}

const FeatureCard: FunctionComponent<FeatureCardProps> = ({icon, title, text, className, ...rest}) => {
  const IconComponent = icon
  return (
    <div className={cn('card_feature', className)} {...rest}>
      <p className="card-description__title">{title}</p>
      <div className="card-image-container">
        <IconComponent className="card-image pictogram" />
      </div>
      <div className="card-description">
        <p className="card-description__text">{text}</p>
      </div>
    </div>
  )
}

DiscoverCard.defaultProps = defaultProps

interface FeatureCardGroupProps {
  className?: string
  children: ReactElement[]
}

const FeatureCardGroup: FunctionComponent<FeatureCardGroupProps> = ({children, className}) => {
  return (
    <div className={cn('card-group_feature', className)}>
      {React.Children.map(children, (child) => (
        //@ts-ignore
        <div className="card-group__card-wrapper">{React.cloneElement(child)}</div>
      ))}
    </div>
  )
}

export {FeatureCard, FeatureCardGroup}
