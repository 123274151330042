import React, {useState, useEffect} from 'react'
import {Accordion, AccordionItem, AccordionSkeleton, Checkbox} from 'carbon-components-react'
import _without from 'lodash.without'
import _union from 'lodash.union'
import _map from 'lodash.map'
import {Header, Panel} from './components'
import {SelectableAccordionComponent} from './types'

const SelectableAccordion: SelectableAccordionComponent = ({isLoading, items, renderItemHeader, renderItemPanel, onChange, config}) => {
  const [checkedValues, setCheckedValues] = useState(config.initialValues || [])

  const getValuesFromItems = () => _map(items, config.valueField)

  const addCheckboxToChecked = (id: string) => {
    setCheckedValues(_union(checkedValues, [id]))
  }
  const removeCheckboxFromChecked = (id: string) => {
    setCheckedValues(_without(checkedValues, id))
  }

  const selectAllCheckboxes = () => {
    setCheckedValues(getValuesFromItems() || [])
  }

  const deselectAllCheckboxes = () => {
    setCheckedValues([])
  }

  const handleChangeSelection = (checked: boolean, id: string) => {
    checked ? addCheckboxToChecked(id) : removeCheckboxFromChecked(id)
  }

  const handleAllProjectsButtonClick = (checked: boolean) => {
    checked ? selectAllCheckboxes() : deselectAllCheckboxes()
  }

  useEffect(() => {
    onChange(checkedValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedValues])

  const handleItemHeaderProps = ({item, checked, onChange, id}: {item: any; checked: boolean; onChange: any; id: string}) => ({
    ...config.getItemHeaderProps({item}),
    checked,
    onChange,
    id,
  })

  const handleItemPanelProps = ({item}: {item: any}) => ({...config.getItemPanelProps({item})})

  const isChecked = (name: string) => Array.isArray(checkedValues) && checkedValues.includes(name)
  const isAllChecked = () => Array.isArray(checkedValues) && checkedValues.length === getValuesFromItems().length
  const isSomeChecked = () => Array.isArray(checkedValues) && checkedValues.length !== getValuesFromItems().length && checkedValues.length !== 0

  return (
    <div>
      {isLoading || !items ? (
        <AccordionSkeleton open count={3} />
      ) : (
        <Accordion>
          <Checkbox
            indeterminate={isSomeChecked()}
            wrapperClassName="bx--accordion__title"
            labelText={<span>Select all projects</span>}
            id="all-projects-checkbox"
            checked={isAllChecked()}
            onChange={handleAllProjectsButtonClick}
          />
          {items &&
            items.map((item: any, index: number) => {
              return (
                <AccordionItem
                  key={index}
                  title={renderItemHeader(
                    handleItemHeaderProps({
                      item,
                      id: item[config.valueField],
                      checked: isChecked(item[config.valueField]),
                      onChange: handleChangeSelection,
                    }),
                  )}
                >
                  {renderItemPanel(handleItemPanelProps({item}))}
                </AccordionItem>
              )
            })}
        </Accordion>
      )}
    </div>
  )
}
SelectableAccordion.Header = Header
SelectableAccordion.Panel = Panel

SelectableAccordion.defaultProps = {
  isLoading: false,
  items: undefined,
  renderItemHeader: () => null,
  renderItemPanel: () => null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: () => {},
  config: {
    valueField: 'id',
    initialValues: [],
    getItemHeaderProps: () => ({}),
    getItemPanelProps: () => ({}),
  },
}

export default SelectableAccordion
