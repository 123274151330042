import React, {FunctionComponent} from 'react'
import {Milestone} from '../../../../../services/api/types/milestones-api'

export interface AnniversaryMilestoneContentProps {
  milestone: Milestone
}

const AnniversaryMilestoneContent: FunctionComponent<AnniversaryMilestoneContentProps> = ({milestone}) => {
  return (
    <>
      <h4>Happy {milestone.year}-year anniversary!</h4>
      <p>Thanks for supporting humanitarian scientific research.</p>
    </>
  )
}

export default AnniversaryMilestoneContent
