import React, {FunctionComponent} from 'react'
import {OrderedList, ListItem} from 'carbon-components-react'
import {ContentHeader, ContentText, Paragraph} from '../../../index'
import {CodeSnippet, Link} from '../../../../../../../components'
import {external, internal} from '../../../../../../../constants'

const Fedora: FunctionComponent = () => {
  return (
    <>
      <ContentHeader>Download for Fedora</ContentHeader>
      <ContentText>
        We recommend installing the BOINC client from the{' '}
        <Link underlined initialSize href={external.Fedora.url} target="_blank">
          Extra Packages for Enterprise Linux
        </Link>{' '}
        repository.
      </ContentText>
      <OrderedList className="ordered-list_code-snippet">
        <ListItem>
          <Paragraph>In a terminal window, run the following command:</Paragraph>
          <CodeSnippet className="code-snippet">sudo yum install boinc-client boinc-manager</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>
            If you can&apos;t find the BOINC libraries, follow{' '}
            <Link underlined initialSize href={external.Fedora.url} target="_blank">
              these steps
            </Link>{' '}
            to enable the Extra Packages for Enterprise Linux repository.
          </Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph>Set the BOINC client to automatically start after you restart your computer:</Paragraph>
          <CodeSnippet className="code-snippet">sudo systemctl enable boinc-client</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>Start the BOINC client:</Paragraph>
          <CodeSnippet className="code-snippet">sudo systemctl start boinc-client</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>Allow group access to client access file:</Paragraph>
          <CodeSnippet className="code-snippet">sudo chmod g+r /var/lib/boinc/gui_rpc_auth.cfg</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>Add your Linux user to the BOINC group to allow the BOINC Manager to communicate with the BOINC client:</Paragraph>
          <CodeSnippet className="code-snippet">sudo usermod -a -G boinc $USER</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>Allow your terminal to pick up the privileges of the new group:</Paragraph>
          <CodeSnippet className="code-snippet">exec su $USER</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>In the same terminal window, start the BOINC Manager:</Paragraph>
          <CodeSnippet className="code-snippet">boincmgr -d /var/lib/boinc</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>
            When BOINC Manager opens, select World Community Grid from the list of BOINC projects then enter your World Community Grid username and password.
          </Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph>
            When these steps are completed, you should see a screen to confirm that you&apos;ve been successfully signed up to World Community Grid.
          </Paragraph>
        </ListItem>
      </OrderedList>
      <p>
        You have downloaded the Fedora software.{' '}
        <Link underlined initialSize href={internal.Download.url}>
          Download for other platforms
        </Link>
        .
      </p>
    </>
  )
}

export default Fedora
