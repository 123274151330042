import React, {FunctionComponent} from 'react'

const Grid: FunctionComponent = ({children, ...rest}) => {
  return (
    <div className="grid" {...rest}>
      {children}
    </div>
  )
}

export default Grid
