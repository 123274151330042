import _map from 'lodash.map'
import {internal} from '../urls'
import {NavItem} from './types'
import {projectsToMenuItemsDictionary, ShortNames, ProjectItems} from './projects'

const generateProjectItemsArray = (projectItems: ProjectItems[], shortName: ShortNames) =>
  projectItems.map((title) => {
    return {
      title,
      url: internal[`Project ${title}`].url(shortName),
    }
  })

const navStandalone: NavItem[] = [
  {
    title: 'Settings',
    items: [
      {title: 'Communications', url: internal.Settings.url},
      {title: 'Profile', url: internal.Profile.url},
      {title: 'Data sharing', url: internal['Data Sharing'].url},
      {title: 'Password', url: internal.Password.url},
      {
        title: 'Devices',
        items: [
          {title: 'Device management', url: internal['Device Management'].url},
          {title: 'Device profiles', url: internal['Device Profiles'].url},
        ],
      },
      {
        title: 'Integrations',
        items: [
          {title: 'Widget', url: internal.Widget.url},
          {title: 'Twitter', url: internal.SettingsTwitter.url},
        ],
      },
      {title: 'Download data', url: internal['Download data'].url},
      {title: 'Delete account', url: internal['Delete Account'].url},
    ],
  },
]

const projectsNavStandalone = _map(projectsToMenuItemsDictionary, (projectItems: ProjectItems[], projectShortName: ShortNames) => ({
  title: projectShortName,
  shortName: projectShortName,
  items: generateProjectItemsArray(projectItems, projectShortName),
}))
Array.prototype.push.apply(navStandalone, projectsNavStandalone)
export default navStandalone
