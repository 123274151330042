import React from 'react'
import content from '../../../content'
import {FeatureCard, FeatureCardGroup} from '../../index'
import {MainContentWrapper} from '../../../../../components'

const FeaturesSection = () => {
  const cards = content.featuresSection.cards
  return (
    <MainContentWrapper noGutter noBottomPadding invertColors header="Features" fullWidthContainerClassName="features-section-container">
      <FeatureCardGroup>
        {cards.map((card, index) => {
          return <FeatureCard key={index} {...card} />
        })}
      </FeatureCardGroup>
    </MainContentWrapper>
  )
}

export default FeaturesSection
