import React, {FunctionComponent, useState, useCallback, useEffect} from 'react'
import {Checkbox, Dropdown, OnChangeData, Button} from 'carbon-components-react'
import {api} from '../../../../../services'
import {CheckboxType, Domain, FilterProps} from './types'
import {devices, projects, serverStatusInit, outcomeInit, validationStatusInit, DEFAULT_DEVICE_ID, DEFAULT_PROJECT_ID} from './content'

const findDomainItem = (id: string, items: Domain[]): Domain => {
  const item = items.find((item) => item.id === id)
  if (item === undefined) {
    return items[0]
  }
  return item
}

const updateSelections = (ids: string[], items: CheckboxType[]): CheckboxType[] => {
  const newItems: CheckboxType[] = []
  items.forEach((item) => {
    const newItem = {
      id: item.id,
      valueId: item.valueId,
      value: item.value,
      isChecked: ids === null ? false : ids.includes(item.valueId),
    }
    newItems.push(newItem)
  })
  return newItems
}

// eslint-disable-next-line max-lines-per-function
const Filters: FunctionComponent<any> = ({updateTable, setIsShown, deviceId, projectId, serverStatusIds, outcomeIds, validationStatusIds}) => {
  const [filter, setFilter] = useState<boolean>(false)
  const [device, setDevice] = useState<string>(deviceId === null ? DEFAULT_DEVICE_ID : deviceId)
  const [project, setProject] = useState<string>(projectId === null ? DEFAULT_PROJECT_ID : projectId)

  const [deviceDomain, setDeviceDomain] = useState<Domain[]>(devices)
  const [projectDomain, setProjectDomain] = useState<Domain[]>(projects)
  const [serverStatus, setServerStatus] = useState<CheckboxType[]>(updateSelections(serverStatusIds, serverStatusInit))
  const [outcome, setOutcome] = useState<CheckboxType[]>(updateSelections(outcomeIds, outcomeInit))
  const [validationStatus, setValidationStatus] = useState<CheckboxType[]>(updateSelections(validationStatusIds, validationStatusInit))

  const handleDeviceSelect = useCallback(
    ({selectedItem}: OnChangeData<Domain>) => {
      selectedItem && setDevice(selectedItem.id)
    },
    [setDevice],
  )

  useEffect(() => {
    const getData = async () => {
      const respDevice = await api.getDeviceDomain()
      if (respDevice.data && respDevice.data.items) {
        respDevice.data.items.unshift(devices[0])
        setDeviceDomain(respDevice.data.items)
      }
    }
    getData()
  }, [])

  const handleProjectSelect = useCallback(
    ({selectedItem}: OnChangeData<Domain>) => {
      selectedItem && setProject(selectedItem.id)
    },
    [setProject],
  )

  useEffect(() => {
    const getData = async () => {
      const respProject = await api.getProjectDomain()
      if (respProject.data && respProject.data.items) {
        respProject.data.items.unshift(projects[0])
        setProjectDomain(respProject.data.items)
      }
    }
    getData()
  }, [])

  const getServerStatus = (checkbox: CheckboxType[], id: string, checked: boolean): CheckboxType[] => {
    return checkbox.map((item) => {
      return item.id === id ? {id: item.id, valueId: item.valueId, value: item.value, isChecked: checked} : item
    })
  }

  const handleChecked = (checked: boolean, id: string): void => {
    if (id.includes('server-status')) {
      setServerStatus(getServerStatus(serverStatus, id, checked))
    } else if (id.includes('outcome')) {
      setOutcome(getServerStatus(outcome, id, checked))
    } else if (id.includes('validation-status')) {
      setValidationStatus(getServerStatus(validationStatus, id, checked))
    }
  }

  const addToList = (checkboxes: CheckboxType[]) => {
    const ids: string[] = []
    if (checkboxes) {
      checkboxes.forEach((item) => {
        if (item.isChecked) {
          ids.push(item.valueId)
        }
      })
    }
    return ids
  }

  const handleApplyFilters = () => {
    const serverStatusIds = addToList(serverStatus)
    const outcomeIds = addToList(outcome)
    const validationStatusIds = addToList(validationStatus)
    setFilter(!filter)
    const filterResult: FilterProps = {
      deviceId: device,
      projectId: project,
      serverStatusIds,
      outcomeIds,
      validationStatusIds,
      updateTableFlag: !filter,
    }

    updateTable(filterResult)
    setIsShown(false)
  }

  const handleResetFilters = () => {
    setDevice(DEFAULT_DEVICE_ID)
    setProject(DEFAULT_PROJECT_ID)
    setServerStatus(serverStatusInit)
    setOutcome(outcomeInit)
    setValidationStatus(validationStatusInit)
  }
  return (
    <div>
      <div style={{marginBottom: '1rem'}}>
        <div className="wcg-filter-panel-dropdown">
          <Dropdown<{text: string; id: string}>
            light
            ariaLabel="Device Dropdown"
            selectedItem={findDomainItem(device, deviceDomain)}
            label="Device"
            titleText="Device"
            id="device-dropdown"
            helperText=""
            items={deviceDomain}
            itemToString={(item) => (item ? item.text : '')}
            onChange={handleDeviceSelect}
          />
        </div>
      </div>
      <div style={{marginBottom: '3rem'}}>
        <div className="wcg-filter-panel-dropdown">
          <Dropdown<{text: string; id: string}>
            light
            ariaLabel="Project Dropdown"
            selectedItem={findDomainItem(project, projectDomain)}
            label="Project"
            titleText="Project"
            id="project-dropdown"
            helperText=""
            items={projectDomain}
            itemToString={(item) => (item ? item.text : '')}
            onChange={handleProjectSelect}
          />
        </div>
      </div>
      <div style={{marginBottom: '1rem'}}>
        <fieldset>
          <legend>Server status</legend>
          {serverStatus.map((item) => (
            <Checkbox key={item.id} labelText={item.value} id={item.id} value={item.valueId} onChange={handleChecked} checked={item.isChecked} />
          ))}
        </fieldset>
      </div>
      <div style={{marginBottom: '1rem'}}>
        <fieldset>
          <legend>Outcome</legend>
          {outcome.map((item) => (
            <Checkbox key={item.id} labelText={item.value} id={item.id} value={item.valueId} onChange={handleChecked} checked={item.isChecked} />
          ))}
        </fieldset>
      </div>
      <div style={{marginBottom: '1rem'}}>
        <fieldset>
          <legend>Validation status</legend>
          {validationStatus.map((item) => (
            <Checkbox key={item.id} labelText={item.value} id={item.id} value={item.valueId} onChange={handleChecked} checked={item.isChecked} />
          ))}
        </fieldset>
      </div>
      <Button className="button" kind="secondary" onClick={handleResetFilters}>
        <span className="button__text">Reset filters</span>
      </Button>
      <Button className="button" onClick={handleApplyFilters}>
        <span className="button__text">Apply filters</span>
      </Button>
    </div>
  )
}

export default Filters
