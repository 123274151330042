import React, {FunctionComponent, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Dropdown} from 'carbon-components-react'
import BaseLayout from '../../../layouts/BaseLayout/BaseLayout'
import {WithContextMenuWrapper} from '../../../layouts'
import {setNavigation} from '../../../store/navigation/actions'
import {api} from '../../../services'
import {MemberStatsYesterday} from '../../../services/api/types/member-api'
import {PageMetadata} from '../../../components/PageMetadata'
import {metadata} from '../../../constants/metadata'
import {LastUpdated} from '../../../components/LastUpdated'
import {Grid, PageHeader, Row, Section} from './components/layout'
import {GridElement} from './components/layout/GridElement'
import {MyContributionSection, MyProjectContributionSection, OverviewSection, ShareSection, ActivityCard, MyActivitySection} from './components'
import {MyTeamSection} from './components/sections/MyTeamSection'

enum option {
  all = 'All-time',
  last30days = 'Past 30 days',
  yesterday = 'Yesterday',
}

type OptionKeys = keyof typeof option
type OptionValues = typeof option[OptionKeys]

const MyContribution: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [currentOption, setCurrentOption] = useState<OptionValues>(option.all)
  const [stats, setStats] = useState<any>({})
  const [periodStats, setPeriodStats] = useState<any>(undefined)
  const [selectedStat, setSelectedStat] = useState<MemberStatsYesterday | undefined>(undefined)

  useEffect(() => {
    dispatch(setNavigation({section: 'My contribution', type: 'main', highlight: 'Overview'}))
    const getData = async () => {
      const [{data: allStats}] = await Promise.all([api.getStats()])
      setStats(allStats)
      setPeriodStats({[option.all]: allStats.memberStats})

      const [{data: stats30Days}, {data: statsYesterday}] = await Promise.all([api.getStats30Day(), api.getStatsYesterday()])

      setPeriodStats({
        [option.all]: allStats.memberStats,
        [option.last30days]: stats30Days,
        [option.yesterday]: statsYesterday,
      })
    }

    getData()
  }, [])

  useEffect(() => {
    switch (currentOption) {
      case option.all:
        periodStats && setSelectedStat(periodStats[option.all])
        break
      case option.last30days:
        periodStats && setSelectedStat(periodStats[option.last30days])
        break
      case option.yesterday:
        periodStats && setSelectedStat(periodStats[option.yesterday])
        break
      default:
        break
    }
  }, [currentOption, periodStats])

  return (
    <BaseLayout>
      <PageMetadata {...metadata.contribution.overview} />
      <WithContextMenuWrapper>
        <LastUpdated time={stats.statsUpdated} className="last-updated__my-contribution-header" />
        <PageHeader>Overview</PageHeader>
        <Section>
          <Grid>
            <Row>
              <OverviewSection stats={stats} />
            </Row>
          </Grid>
        </Section>
        <Section
          title={
            <Section.Header>
              <Section.Title className="section-title__expand">My contribution</Section.Title>{' '}
              <div className="dropdown-wrapper__my-contribution">
                <Dropdown
                  light
                  type="inline"
                  ariaLabel="Dropdown"
                  id="time-dropdown"
                  onChange={(data) => {
                    setCurrentOption(() => data.selectedItem as OptionValues)
                  }}
                  selectedItem={currentOption}
                  items={[option.all, option.last30days, option.yesterday]}
                  label="Time range"
                  titleText=""
                />
              </div>
            </Section.Header>
          }
        >
          <Grid>
            <Row>
              <MyContributionSection selectedStat={selectedStat} />
            </Row>
          </Grid>
        </Section>
        <MyActivitySection stats={stats} />
        <Section
          title={
            <Section.Header>
              <Section.Title>My activity over time</Section.Title>
            </Section.Header>
          }
        >
          <Grid>
            <Row>
              <GridElement withGutterLeftMdToLg lg={3} md={2} sm={2}>
                <ActivityCard />
              </GridElement>
            </Row>
          </Grid>
        </Section>
        <Section
          title={
            <Section.Header>
              <Section.Title className="section-title__expand">My project contribution</Section.Title>
            </Section.Header>
          }
        >
          <Grid>
            <Row>
              <GridElement withGutterLeftMdToLg noMinHeight lg={3} md={2} sm={2}>
                <MyProjectContributionSection stats={stats} />
              </GridElement>
            </Row>
          </Grid>
        </Section>
        <Section
          title={
            <Section.Header>
              <Section.Title className="section-title__expand">My team history</Section.Title>
            </Section.Header>
          }
        >
          <Grid>
            <Row>
              <GridElement withGutterLeftMdToLg noMinHeight lg={3} md={2} sm={2}>
                <MyTeamSection stats={stats} />
              </GridElement>
            </Row>
          </Grid>
        </Section>
        <ShareSection stats={stats} />
      </WithContextMenuWrapper>
    </BaseLayout>
  )
}

export default MyContribution
