import React, {FunctionComponent} from 'react'
import {Header, Footer, SystemError} from '../../components'

const BaseLayout: FunctionComponent = ({children, ...rest}) => {
  return (
    <div className="wcg-layout" role="main" {...rest}>
      <Header />
      <div className="wcg-content-wrapper">
        <SystemError>{children}</SystemError>
      </div>
      <Footer />
    </div>
  )
}

export default BaseLayout
