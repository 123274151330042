import React from 'react'
import {Link as CarbonLink, ListItem, UnorderedList} from 'carbon-components-react'

import {imageUrls} from '../../constants/imageUrls'
import {Link} from '../../components'
import {FooterLinksGroup} from './components'
import {footer} from './content'

const {boincLink, lists} = footer

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="bx--grid">
          <div className="bx--row first-footer-row">
            <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-0 first-footer-logo">
              <FooterLinksGroup withDivider>
                <Link href={footer.krembilLink.url} className="footer-logo" target="_blank">
                  <img src={imageUrls.krembilLogo} alt="Krembil Logo" />
                </Link>
              </FooterLinksGroup>
            </div>
            <div className="bx--col-md-0 bx--col-sm-4">
              <FooterLinksGroup>
                <Link href={footer.krembilLink.url} className="footer-logo" target="_blank">
                  <img src={imageUrls.krembilLogo} alt="Krembil Logo" />
                </Link>
              </FooterLinksGroup>
            </div>
            <div className="bx--col-md-0 bx--col-sm-4">
              <FooterLinksGroup withDivider>
                <Link href={footer.jurisicaLabLink.url} className="footer-logo" target="_blank">
                  <img src={imageUrls.jurisicaLogo} alt="Jurisica Lab Logo" />
                </Link>
              </FooterLinksGroup>
            </div>
            <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-4">
              <FooterLinksGroup title={lists.research.title} links={lists.research.links} withDivider />
            </div>
            <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-4">
              <FooterLinksGroup title={lists.about.title} links={lists.about.links} withDivider />
            </div>
            <div className="bx--col-lg-3 bx--col-md-2 bx--col-sm-4">
              <FooterLinksGroup title={lists.contribute.title} links={lists.contribute.links} withDivider />
            </div>
            <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-0">
              <FooterLinksGroup withDivider>
                <Link href={footer.jurisicaLabLink.url} className="footer-logo" target="_blank">
                  <img src={imageUrls.jurisicaLogo} alt="Jurisica Lab Logo" />
                </Link>
              </FooterLinksGroup>
            </div>
            <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-4">
              <FooterLinksGroup title={lists.community.title} links={lists.community.links} withDivider />
            </div>
            <div className="bx--col-lg-4 bx--col-md-2 bx--col-sm-4">
              <FooterLinksGroup title={lists.followUs.title} links={lists.followUs.links} target="_blank" withDivider />
            </div>
            <div className="bx--col-lg-2 bx--col-md-2 bx--col-sm-4">
              <FooterLinksGroup>
                <CarbonLink href={boincLink.url} className="boinc-logo-link footer-logo" target="_blank">
                  <img src={imageUrls.boincLogo} alt="BOINC Logo" />
                </CarbonLink>
                <p className="boinc-promo-text">
                  World Community Grid is powered by{' '}
                  <Link href="https://boinc.berkeley.edu/" target="_blank">
                    BOINC
                  </Link>
                </p>
              </FooterLinksGroup>
            </div>
          </div>
        </div>
      </div>
      <footer role="contentinfo" area-label="WCG">
        <div id="wcg-footer">
          <div className="bx--grid">
            <div className="bx--row">
              <div className="bx--col-lg-12 bx--col-md-6 bx--offset-lg-4 bx--offset-md-2">
                <UnorderedList>
                  {lists.basement.links.map(({title, url}, index) => (
                    <ListItem key={index} className="bx--type-body-long-01">
                      <Link href={url}>{title}</Link>
                    </ListItem>
                  ))}
                </UnorderedList>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
