import React, {FunctionComponent} from 'react'

import {Grid, Row, GridElement} from '../../../MyContribution/components/layout'
import {ResourceCard} from '../../../../../components/ResourceCard'
import {internal} from '../../../../../constants'
import {imageUrls} from '../../../../../constants/imageUrls'

const Boxes: FunctionComponent = () => {
  return (
    <Grid>
      <Row>
        <GridElement lg={1} md={1} sm={2}>
          <ResourceCard
            url={internal['Device Management'].url}
            leftIcon={<img className="wcg-logo-link-tile-left-logo__img__my-contribution" src={imageUrls.wcgLaptop} alt="Laptop icon" />}
            title="Device manager"
          />
        </GridElement>
      </Row>
    </Grid>
  )
}
export default Boxes
