import React, {useEffect} from 'react'
import {BrowserRouter as Router, Switch, Route, useLocation, Redirect} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {
  Home,
  Join,
  Login,
  MyContribution,
  History,
  DownloadAgain,
  NotFound,
  Devices,
  DeviceHistory,
  Results,
  Workunit,
  Milestones,
  ResultsLog,
  Donations,
  Newsletters,
} from './pages'
import {PrivateRoute} from './components/PrivateRoute'
import {RootState} from './store'
import {GoogleTagManager, WCGAnalytics, PageMetadata} from './components'
import {userSelector} from './store/selectors'

interface SelectorProps {
  loggedIn: boolean
}

const ScrollToTop = () => {
  const {pathname} = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const App = () => {
  window.scrollTo(0, 0)
  const {loggedIn} = useSelector<RootState, SelectorProps>(userSelector)

  return (
    <Router>
      <PageMetadata />
      <GoogleTagManager />
      <WCGAnalytics />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/join" component={Join} />
        <Route path="/donations" component={Donations} />
        <Route path="/newsletters" component={Newsletters} />
        <Redirect exact from="/contribution" to="/contribution/overview" />
        <PrivateRoute isPassing={loggedIn} path="/contribution/overview" component={MyContribution} redirectTo="/login" />
        <PrivateRoute isPassing={loggedIn} path="/contribution/history" component={History} redirectTo="/login" />
        <PrivateRoute isPassing={loggedIn} path="/contribution/devices" component={Devices} redirectTo="/login" />
        <PrivateRoute isPassing={loggedIn} path="/contribution/device" component={DeviceHistory} redirectTo="/login" />
        <PrivateRoute isPassing={loggedIn} path="/contribution/milestones" component={Milestones} redirectTo="/login" />
        <PrivateRoute isPassing={loggedIn} path="/contribution/results/:id/log" component={ResultsLog} redirectTo="/login" />
        <PrivateRoute isPassing={loggedIn} path="/contribution/results" component={Results} redirectTo="/login" />
        <PrivateRoute isPassing={loggedIn} path="/contribution/workunit/:id" component={Workunit} redirectTo="/login" />
        <PrivateRoute isPassing={loggedIn} path="/download" component={DownloadAgain} redirectTo="/login" />
        <Route path="/login" component={Login} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default App
