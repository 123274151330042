import React, {FunctionComponent} from 'react'
import {SkeletonText} from 'carbon-components-react'
import cn from 'classnames'
import {formatDatetimeFromString} from '../../utils'
export interface LastUpdatedProps {
  time: string | undefined
  className?: string
}

const LastUpdated: FunctionComponent<LastUpdatedProps> = ({time, className}) => {
  return (
    <div className={cn('wcg-last-updated', className)}>
      {time === undefined ? <SkeletonText width="10rem" className="wcg-last-updated-skeleton" /> : <>Last updated: {formatDatetimeFromString(time)}</>}
    </div>
  )
}

export default LastUpdated
