import React, {FunctionComponent} from 'react'
import {GridElement} from '../../layout'
import {OverviewCard} from '../../cards/OverviewCard'
import {MilestonesCard} from '../../cards/MilestonesCard'
import {ProjectsCard} from '../../cards/ProjectsCard'

export interface OverviewSectionProps {
  stats: any
}

const OverviewSection: FunctionComponent<OverviewSectionProps> = ({stats}) => {
  return (
    <>
      <GridElement>
        <OverviewCard stats={stats} />
      </GridElement>
      <GridElement>
        <MilestonesCard />
      </GridElement>
      <GridElement>
        <ProjectsCard />
      </GridElement>
    </>
  )
}

export default OverviewSection
