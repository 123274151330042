import React from 'react'
import {DiscoverCard, DiscoverCardGroup} from '../../index'
import content from '../../../content'
import {MainContentWrapper} from '../../../../../components'

const WithYourHelpSection = () => {
  return (
    <MainContentWrapper className="with-your-help-section" expand noGutter header="With your help, World Community Grid researchers have…">
      <DiscoverCardGroup>
        {content.withYourHelpSection.cards.map(({url, text, src}, index) => {
          return <DiscoverCard className="wcg-outline" key={index} url={url} text={text} src={src} />
        })}
      </DiscoverCardGroup>
    </MainContentWrapper>
  )
}

export default WithYourHelpSection
