import React, {FunctionComponent} from 'react'
import {Row, Column, SkeletonPlaceholder, SkeletonText} from 'carbon-components-react'
import {CloudUpload24, Events24, FaceActivated24, Recommend24, Star24} from '@carbon/icons-react'
import {TeamChallengeMilestoneContent} from '../TeamChallengeMilestoneContent'
import {Milestone} from '../../../../../services/api/types/milestones-api'
import {BadgeMilestoneContent} from '../BadgeMilestoneContent'
import {AnniversaryMilestoneContent} from '../AnniversaryMilestoneContent'
import {FirstResultMilestoneContent} from '../FirstResultMilestoneContent'
import {JoinedMilestoneContent} from '../JoinedMilestoneContent'
import {formatDateFromString} from '../../../../../utils'

export interface MilestoneItemProps {
  milestone: Milestone
  last: boolean
}

const getIcon = (type: string) => {
  if (type === 'B') {
    return <Recommend24 />
  } else if (type === 'A') {
    return <Star24 />
  } else if (type === 'R') {
    return <CloudUpload24 />
  } else if (type === 'J') {
    return <FaceActivated24 />
  } else if (type === 'T') {
    return <Events24 />
  } else {
    return <SkeletonPlaceholder className="wcg-milestone-item-icon" />
  }
}

const MilestoneItem: FunctionComponent<MilestoneItemProps> = ({milestone, last}) => {
  let skeleton = false
  let content
  if (milestone.type === 'T') {
    content = <TeamChallengeMilestoneContent milestone={milestone} />
  } else if (milestone.type === 'B') {
    content = <BadgeMilestoneContent milestone={milestone} />
  } else if (milestone.type === 'A') {
    content = <AnniversaryMilestoneContent milestone={milestone} />
  } else if (milestone.type === 'R') {
    content = <FirstResultMilestoneContent />
  } else if (milestone.type === 'J') {
    content = <JoinedMilestoneContent />
  } else if (milestone.type === 'S') {
    skeleton = true
  } else {
    content = <div>{JSON.stringify(milestone)}</div>
  }

  const milestoneDate = skeleton ? (
    <SkeletonText className="wcg-milestone-skeleton" />
  ) : (
    <span className="wcg-milestone-date">{formatDateFromString(milestone.date)}</span>
  )
  const containerClasses = last ? 'wcg-milestone-container wcg-milestone-item-last' : 'wcg-milestone-container'

  return (
    <Row>
      <Column sm={0} md={2}>
        {milestoneDate}
      </Column>
      <Column>
        <div className={containerClasses}>
          <div className="wcg-milestone-icon-container">
            <div className="wcg-milestone-icon-wrapper">{getIcon(milestone.type)}</div>
            <div className="wcg-milestone-connector">&nbsp;</div>
          </div>
          <div className="wcg-milestone-content-container">
            {milestoneDate}
            {skeleton ? <SkeletonText className="wcg-milestone-skeleton" /> : content}
          </div>
        </div>
      </Column>
    </Row>
  )
}

export default MilestoneItem
