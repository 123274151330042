import React, {FunctionComponent, ReactNode} from 'react'
import cn from 'classnames'

export interface SectionProps {
  className?: string
  title?: string | ReactNode
}

export interface SectionTitleProps {
  className?: string
}

export interface SectionHeaderProps {
  className?: string
}

const SectionHeader: FunctionComponent<SectionHeaderProps> = ({children, className, ...rest}) => {
  return (
    <div className={cn('section-header', className)} {...rest}>
      {children}
    </div>
  )
}

const SectionTitle: FunctionComponent<SectionTitleProps> = ({children, className, ...rest}) => {
  return (
    <div className={cn('section-title', className)} {...rest}>
      {children}
    </div>
  )
}

const Section: FunctionComponent<SectionProps> & {
  Header: typeof SectionHeader
  Title: typeof SectionTitle
} = ({children, title, className, ...rest}) => {
  const Title = title
  return (
    <div className={cn('section', className)} {...rest}>
      {title && typeof title === 'string' ? <h4 className="section-title">{title}</h4> : Title}
      <div className="section-content">{children}</div>
    </div>
  )
}

Section.Title = SectionTitle
Section.Header = SectionHeader

export default Section
