import React, {FunctionComponent, useEffect, useState} from 'react'
import {Information16} from '@carbon/icons-react'
import cn from 'classnames'
import {handleOutsideClick, makeId} from '../../utils'
interface TooltipProps {
  customTrigger?: React.ReactNode
  children?: React.ReactNode
  className?: string
  direction?: 'top' | 'bottom' | 'left' | 'right'
  label?: React.ReactNode
}

const InteractiveTooltip: FunctionComponent<TooltipProps> = ({customTrigger, className, label, children}) => {
  const [isShown, setIsShown] = useState(false)
  const [id] = useState(`${makeId(20)}`)
  const handleClick = () => {
    setIsShown((prev) => !prev)
  }

  const handleTooltipOutsideClick = handleOutsideClick(`#${id}`, (e) => {
    setIsShown(false)
  })
  useEffect(() => {
    window.addEventListener('click', handleTooltipOutsideClick)
    return () => {
      window.removeEventListener('click', handleTooltipOutsideClick)
    }
  }, [])

  return (
    <div id={id} className="bx--tooltip__label">
      <span>{label}</span>
      <div className={cn('bx--tooltip__trigger', className)}>
        <button className="bx--tooltip__trigger-content" onClick={handleClick}>
          {customTrigger || <Information16 />}
        </button>
        <div className="wcg--tooltip__outer-wrapper">
          <div role="button" className={cn('bx--tooltip bx--tooltip--align-center', isShown && 'bx--tooltip--shown')}>
            <span className="bx--tooltip__caret" />
            <div className="bx--tooltip__content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

InteractiveTooltip.defaultProps = {}

export default InteractiveTooltip
