import React, {FunctionComponent, useState, useEffect} from 'react'
import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Pagination,
} from 'carbon-components-react'

import {api} from '../../../../../services'
import {formatResults, formatRuntime} from '../../../../../utils'
import {Download} from '../../../../../components/Download'
import {DailyStatsItem} from '../../../../../services/api/types'

const PAGE_SIZES: [10, 25, 50, 100] = [10, 25, 50, 100]

interface headerType {
  header: string
  key: string
}

interface TableProps {
  rows: any
  headers: any
  getHeaderProps: any
  getRowProps: any
  getTableProps: any
  getToolbarProps: any
  getTableContainerProps: any
}

const dataHeaders: headerType[] = [
  {
    header: 'Statistics date',
    key: 'date',
  },
  {
    header: 'Runtime',
    key: 'runtimeInSeconds',
  },
  {
    header: 'Points earned',
    key: 'points',
  },
  {
    header: 'Results returned',
    key: 'results',
  },
]

interface historyRow {
  id: string
  date: string
  points: number
  results: number
  runtimeInSeconds: number
}

const historyToDataRow = (item: DailyStatsItem) => {
  const row: historyRow = {
    id: `${item.results}${item.date}`,
    date: item.date,
    runtimeInSeconds: item.runtimeInSeconds,
    points: item.points,
    results: item.results,
  }
  return row
}

const renderCell = (cell: any) => {
  const column = cell.info.header
  if (column === 'results') {
    return formatResults(cell.value)
  } else if (column === 'runtimeInSeconds') {
    return formatRuntime(cell.value)
  }
  return cell.value
}

export interface ComponentProps {
  id: string | null
  type: string | null
}

const HistoryTable: FunctionComponent<ComponentProps> = ({id, type}) => {
  const [historyItems, setHistoryItems] = useState<DailyStatsItem[]>()
  const [maxRecordsAvailable, setMaxRecordsAvailable] = useState<number>(1)
  const [currentPageSize, setCurrentPageSize] = useState<number>(25)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const download = () => {
    api.getDevicesHistoryForDownload(id, type)
  }

  const handlePageChange = (data: {page: number; pageSize: number}) => {
    setCurrentPage(data.page)
    setCurrentPageSize(data.pageSize)
  }

  useEffect(() => {
    const getData = async () => {
      const resp = await api.getDevicesHistory(id, type, currentPage, currentPageSize)
      if (resp && resp.data && resp.data.items) {
        setHistoryItems(resp.data.items)
      }
      if (resp && resp.data && resp.data.metadata && resp.data.metadata.recordsAvailable) {
        setMaxRecordsAvailable(resp.data.metadata.recordsAvailable)
      }
    }
    getData()
  }, [currentPage, currentPageSize, id, type])

  if (historyItems === undefined) {
    return <DataTableSkeleton />
  }
  const dataRows = historyItems.map(historyToDataRow)
  return (
    <div>
      <DataTable rows={dataRows} headers={dataHeaders} isSortable>
        {/* @ts-ignore */}
        {({rows, headers, getHeaderProps, getRowProps, getTableProps, getToolbarProps, getTableContainerProps}: TableProps) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar {...getToolbarProps()}>
              <TableToolbarContent>
                <Download download={download} label="Download device" />
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header: headerType) => (
                    <TableHeader key={header.key} {...getHeaderProps({header})}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: {id: string | number | null | undefined; cells: any[]}) => (
                  <TableRow key={row.id} {...getRowProps({row})}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{renderCell(cell)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <div className="pagination-table">
        <Pagination pageSizes={PAGE_SIZES} pageSize={currentPageSize} page={currentPage} totalItems={maxRecordsAvailable || 0} onChange={handlePageChange} />
      </div>
    </div>
  )
}

export default HistoryTable
