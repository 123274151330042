import React, {FunctionComponent} from 'react'
import cn from 'classnames'
import {MainContentWrapper} from '../MainContentWrapper'
import {MainContentHeaderProps} from './types'

const MainContentHeader: FunctionComponent<MainContentHeaderProps> = ({title, description, withDivider, className, ...rest}) => {
  return (
    <MainContentWrapper withDivider={withDivider} className={cn('main-content-header__inner-wrapper', className)} {...rest}>
      <div className="main-content-header__description">
        {title && <h1 className="main-content-header__title">{title}</h1>}
        {description && <p className="main-content-header__text">{description}</p>}
      </div>
    </MainContentWrapper>
  )
}

export default MainContentHeader
