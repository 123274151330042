import React, {useState} from 'react'

import {Switch, Redirect, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RouteComponentProps} from 'react-router'
import _get from 'lodash.get'
import {internal} from '../../../constants'
import {PageMetadata, MainContentWrapper} from '../../../components'
import {BaseLayout} from '../../../layouts/BaseLayout'
import {metadata} from '../../../constants/metadata'
import {RootState} from '../../../store'
import {UserState} from '../../../store/user/types'
import {userSelector} from '../../../store/selectors'
import {SignUp, Projects, Download, Install, JoinProccessIndicatorBar, ProgressPrivateRoute, ProgressRoute} from './components'
import {isDownloadStep} from './helpers'

enum Step {
  'First',
  'Second',
  'Third',
  'Fourth',
}

const Join = ({match}: RouteComponentProps) => {
  const {loggedIn} = useSelector<RootState, UserState>(userSelector)
  const location = useLocation()
  const [progressStepIndex, setProgressStepIndex] = useState<number>(Step.First)

  return (
    <BaseLayout>
      <PageMetadata {...metadata.join} />
      <MainContentWrapper noBottomPadding className="progress-bar-wrapper_join">
        <JoinProccessIndicatorBar step={progressStepIndex} />
      </MainContentWrapper>
      <Switch>
        {loggedIn && _get(location, 'state.prevPath') !== 'join' ? (
          <Redirect from={`${match.path}`} to={{...location, pathname: internal['Contribution Overview'].url}} />
        ) : null}
        <Redirect exact from={`${match.path}`} to={{...location, pathname: `${match.path}/signup`}} />
        <ProgressPrivateRoute
          progressIndex={Step.First}
          setStep={setProgressStepIndex}
          isPassing={!loggedIn}
          path={`${match.path}/signup`}
          component={SignUp}
          redirectTo={{...location, pathname: `${match.path}/projects`}}
        />
        <ProgressPrivateRoute
          progressIndex={Step.Second}
          setStep={setProgressStepIndex}
          isPassing={loggedIn}
          path={`${match.path}/projects`}
          component={Projects}
          redirectTo={{...location, pathname: `${match.path}/signup`}}
        />
        <ProgressPrivateRoute
          progressIndex={Step.Third}
          setStep={setProgressStepIndex}
          isPassing={loggedIn}
          path={`${match.path}/download`}
          component={Download}
          redirectTo={{...location, pathname: `${match.path}/signup`}}
        />
        <ProgressRoute
          progressIndex={isDownloadStep ? Step.Fourth : Step.Third}
          setStep={setProgressStepIndex}
          path={`${match.path}/:os(debian||windows||fedora||macos||android||raspberry)`}
          component={Install}
        />
      </Switch>
    </BaseLayout>
  )
}

export default Join
