import React, {FunctionComponent} from 'react'
import {Result} from '../../../../../services/api/types'
import {Link} from '../../../../../components'
import {internal} from '../../../../../constants'

export interface StatusProps {
  result: Result
}

const hasNoStderrAvailable = (result: Result) => {
  return result.status === 0 || result.status === 2 || result.status >= 8
}

const Status: FunctionComponent<StatusProps> = ({result}) => {
  if (hasNoStderrAvailable(result)) {
    return <span>{result.statusName}</span>
  }

  return <Link href={internal['Results log'].url(result.resultId)}>{result.statusName}</Link>
}

export default Status
