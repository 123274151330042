//This file contains a list of URLs for content on the website. If a legacy page
//should redirect to the page, then the proprety 'legacy' and a list of pages that
//should be redirect to the page are provided

const internal = {
  'Active research': {url: '/research/projects.s'},
  'Completed research': {url: '/research/projects.s?proj=comp'},
  'Submit a proposal': {url: '/research/proposal.s'},
  'About us': {url: '/about/about.s'},
  Security: {url: '/about/security.s'},
  'Our partners': {url: '/about/partners.do'},
  'Become a partner': {url: '/about/become.s'},
  'Spread the word': {url: '/about/spread.s'},
  Newsletters: {url: '/newsletters'},
  News: {url: '/about_us/news.s'},
  'Support this project': {url: (shortName) => `/ms/contribute.action?projectToAdd=${shortName}`},
  'Global statistics': {url: '/stat/viewGlobal.do'},
  'Global statistics history': {url: '/stat/viewGlobalHistory.do'},
  'By project': {url: '/stat/viewProjects.do'},
  'project name': {url: '/stat/viewProject.do?projectShortName={project short name}'},
  'By team': {url: '/stat/viewTeams.do'},
  'By member': {url: '/stat/viewMembers.do'},
  Geography: {url: '/stat/viewGeography.do'},
  'My contribution': {url: '/contribution/overview', legacy: ['/ms/viewMyMemberPage.do']},
  'My history': {url: '/contribution/history', legacy: ['/ms/viewMemberStatHistory.do']},
  'Device statistics': {url: '/contribution/devices', legacy: ['/ms/device/viewStatisticsByDevice.do']},
  'Device statistics history': {url: '/contribution/device', legacy: ['/ms/device/viewDeviceStatHistory.do']},
  'My projects': {url: '/ms/viewMyProjects.do'},
  'Results status': {url: '/ms/team/viewMyTeam.do'},
  Results: {url: '/contribution/results', legacy: ['/ms/viewBoincResults.do']},
  'Results log': {url: (resultId) => `/contribution/results/${resultId}/log`},
  Workunit: {url: '/contribution/workunit', legacy: ['/ms/device/viewWorkunitStatus.do']},
  'My team': {url: '/ms/team/viewMyTeam.do'},
  'Find a team': {url: '/team/viewSearchTeams.do'},
  'Get to know your team': {url: (teamId) => `/team/viewTeamInfo.do?teamId=${teamId}`},
  'Create a new team': {url: '/ms/team/viewCreateTeam.do'},
  Challenge: {url: (challengeId) => `/team/challenge/viewTeamChallenge.do?challengeId=${challengeId}`},
  'Beta testing': {url: '/ms/device/viewBetaProfiles.do'},
  'Profile and settings': {url: '/ms/viewNewsPreference.action'},
  Profile: {url: '/ms/viewMyProfile.do'},
  SettingsTwitter: {url: '/ms/sm/viewSocialMedia.do'},
  'Device Management': {url: '/ms/device/viewDevices.do'},
  'Device Profiles': {url: '/ms/device/viewProfiles.do'},
  'Data Sharing': {url: '/ms/viewDataSharing.action'},
  Password: {url: '/ms/login/viewChangePassword.do'},
  'Download data': {url: '/ms/downloadData.t'},
  'Delete Account': {url: '/ms/viewDeleteAccount.action'},
  Widget: {url: '/ms/viewGetWidget.do'},
  Download: {url: '/download', legacy: ['/ms/viewDownloadAgain.action']},
  Donations: {url: '/donations'},
  WCG: {url: '/', legacy: ['/discover.action', '/home.do', '/homepage.action', '/index.jsp']},
  'How it works': {url: '/about_us/viewGridComputingBasics.do'},
  Join: {url: '/join', legacy: ['/join.action']},
  'Join Projects': {url: '/join/projects'},
  Settings: {url: '/ms/viewNewsPreference.action'},
  'Forgot password': {url: '/login/forgotPassword.do'},
  Forums: {url: '/forums/wcg/index'},
  Help: {url: '/help/viewHelp.do'},
  Contact: {url: '/viewContactUs.do'},
  'Privacy statement': {url: '/viewMemberPolicy.do'},
  'Terms of use': {url: '/endUser.jsp'},
  'Site map': {url: '/viewSitemap.do'},
  'Log in': {url: '/login', legacy: ['/viewLogin.do', '/viewLoginError.do']},
  Admin: {url: '/manage/viewAdminMain.do'},
  'Log Out': {url: '/forums/wcg/logout'},
  'Join Download': {url: '/join/download'},
  'Install guideline': {url: (currentOsShortName) => `/join/${currentOsShortName}`},
  'Project News': {url: (projectShortName) => `/research/${projectShortName}/news.do`},
  'Project Overview': {url: (projectShortName) => `/research/${projectShortName}/overview.do`},
  'Project Details': {url: (projectShortName) => `/research/${projectShortName}/details.do`},
  'Project Researchers': {url: (projectShortName) => `/research/${projectShortName}/researchers.do`},
  'Project FAQs': {url: (projectShortName) => `/research/${projectShortName}/faq.do`},
  'Project Statistics': {url: (projectShortName) => `/stat/viewProject.do?projectShortName=${projectShortName}`},
  'Project Spread the word': {url: (projectShortName) => `/research/${projectShortName}/spread.do`},
  'Project Tell a friend': {url: (projectShortName) => `/research/${projectShortName}/share.do`},
  'Help view topic': {url: (projectShortName) => `/help/viewTopic.do?shortName=${projectShortName}`},
  'Contribution Milestones': {url: '/contribution/milestones'},
  'Contribution Overview': {url: '/contribution/overview'},
  'Contribution Projects': {url: '/contribution/projects'},
  'Contribution Projects Edit': {url: '/contribution/projects/edit'},
  'Share team': {
    url: (recruiterId, teamId) => `https://join.worldcommunitygrid.org?recruiterId=${recruiterId}${teamId ? `&teamId=${teamId}` : ''}`,
  },
}

const external = {
  Krembil: {url: 'https://www.uhn.ca/Research/Research_Institutes/Krembil'},
  JurisicaLab: {url: 'https://www.cs.toronto.edu/~juris/'},
  Twitter: {url: 'https://twitter.com/WCGrid'},
  Facebook: {url: 'https://www.facebook.com/worldcommunitygrid.org'},
  YouTube: {url: 'https://www.youtube.com/channel/UCZRC_76WE8K9etVjKvIThQQ'},
  LinkedIn: {url: 'https://www.linkedin.com/company/80032632'},
  Boinc: {url: 'https://boinc.berkeley.edu/'},
  Fedora: {url: 'https://fedoraproject.org/wiki/EPEL'},
  BoincApp: {url: 'https://play.google.com/store/apps/details?id=edu.berkeley.boinc'},
  forbes: {
    url: 'https://www.forbes.com/sites/robinseatonjefferson/2017/08/30/ibm-scientists-seek-publics-help-with-millions-of-virtual-experiments-mapping-the-human-microbiome/#be0f3162f86a',
  },
  'Fast Company': {
    url: 'https://www.fastcompany.com/90505726/your-laptops-unused-computing-power-can-help-scientists-find-covid-19-treatments',
  },
  ctv: {url: 'https://toronto.ctvnews.ca/video?clipId=1675312'},
  UHNFoundation: {url: 'https://support.uhnfoundation.ca/site/Donation2?2740.donation=form1&df_id=2740&mfc_pref=T&set.SingleDesignee=2001'},
}

module.exports = {
  internal,
  external,
}
