import {Domain, CheckboxType} from './types'

export const DEFAULT_DEVICE_ID = '0'
export const DEFAULT_PROJECT_ID = '-1'
export const DEFAULT_PAGE_SIZE = 25
export const DEFAULT_PAGE_NUMBER = 1

export const devices: Domain[] = [
  {
    id: DEFAULT_DEVICE_ID,
    text: 'All devices',
  },
]

export const projects: Domain[] = [
  {
    id: DEFAULT_PROJECT_ID,
    text: 'All projects',
  },
]
export const serverStatusInit: CheckboxType[] = [
  {id: 'server-status-1', valueId: '4', value: 'In progress', isChecked: false},
  {id: 'server-status-2', valueId: '5', value: 'Completed', isChecked: false},
]

export const outcomeInit: CheckboxType[] = [
  {id: 'outcome-1', valueId: '1', value: 'Success', isChecked: false},
  {id: 'outcome-2', valueId: '3', value: 'Error', isChecked: false},
  {id: 'outcome-3', valueId: '4', value: 'No reply', isChecked: false},
  {id: 'outcome-4', valueId: '6', value: 'Validation error', isChecked: false},
  {id: 'outcome-5', valueId: '7', value: 'Abandoned', isChecked: false},
]

export const validationStatusInit: CheckboxType[] = [
  {id: 'validation-status-1', valueId: '0', value: 'Pending validation', isChecked: false},
  {id: 'validation-status-2', valueId: '1', value: 'Valid', isChecked: false},
  {id: 'validation-status-3', valueId: '2', value: 'Invalid', isChecked: false},
  {id: 'validation-status-4', valueId: '3', value: 'Workunit error', isChecked: false},
  {id: 'validation-status-5', valueId: '4', value: 'Pending verification', isChecked: false},
  {id: 'validation-status-6', valueId: '5', value: 'Too late', isChecked: false},
]
