import React, {FunctionComponent, PropsWithChildren, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Breadcrumb, BreadcrumbItem, Column, Grid, Row} from 'carbon-components-react'
import {BaseLayout, WithContextMenuWrapper} from '../../../layouts'
import {Link, PageMetadata} from '../../../components'
import {metadata} from '../../../constants/metadata'
import {setNavigation} from '../../../store/navigation/actions'
import {api} from '../../../services'
import {ResultWithLog} from '../../../services/api/types'
import {PageHeader} from '../MyContribution/components'
import {internal} from '../../../constants'

interface ResultsLogProps extends PropsWithChildren<any> {
  id: number
}

const ResultsLog: FunctionComponent<ResultsLogProps> = (props) => {
  const dispatch = useDispatch()
  const [result, setResult] = useState<ResultWithLog | undefined>(undefined)
  const [notFound, setNotFound] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setNavigation({section: 'My contribution', type: 'main', highlight: 'Results'}))
  }, [])

  useEffect(() => {
    const getData = async () => {
      const respAll = await api.getResult(props.match.params.id)
      if (respAll && respAll.data && respAll.data) {
        setResult(respAll.data)
      } else if (respAll === undefined) {
        setNotFound(true)
      }
    }
    getData()
  }, [props.match.params.id])

  return (
    <BaseLayout>
      <PageMetadata {...metadata.contribution.results} />
      <WithContextMenuWrapper>
        <div className="section-breadcrumb">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link href={internal.Results.url}>Results</Link>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <PageHeader>Results log</PageHeader>
        <Grid className="bx--no-gutter">
          <Row>
            <Column>
              {result && (
                <p className="result-error-log">
                  {result.errorLog &&
                    result.errorLog.split('\n').map((item, index) => (
                      <span key={index}>
                        {item}
                        <br />
                      </span>
                    ))}
                </p>
              )}
              {notFound && <p>Result not found</p>}
            </Column>
          </Row>
        </Grid>
      </WithContextMenuWrapper>
    </BaseLayout>
  )
}

export default ResultsLog
