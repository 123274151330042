import React, {FunctionComponent} from 'react'
import {Grid, GridElement, Row, Section} from '../../layout'
import {FeatureCard} from '../../cards/FeatureCard'
import {ResourceCard} from '../../../../../../components/ResourceCard'
import {imageUrls} from '../../../../../../constants/imageUrls'
import {internal} from '../../../../../../constants'

export interface ShareSectionProps {
  stats: any
}

const ShareSection: FunctionComponent<ShareSectionProps> = ({stats}) => {
  return (
    <Section
      title={
        <Section.Header className="no-display-down-md">
          <Section.Title>Sharing</Section.Title>
        </Section.Header>
      }
    >
      <Grid>
        <Row>
          <GridElement withGutterLeftMdToLg noGutterRightLg className="feature-element-wrapper" lg={2} md={2} sm={2}>
            <FeatureCard stats={stats} />
          </GridElement>
        </Row>
        <Row>
          <GridElement noMinHeight withGutterLeftMdToLg noGutterRightMdToLg noGutterRightLg lg={1} md={1} sm={2}>
            <ResourceCard
              external
              url={internal.Widget.url}
              leftIcon={<img className="wcg-logo-link-tile-left-logo__img__my-contribution" src={imageUrls.wcgIcon} alt="World Community Grid icon" />}
              title="Create widget"
            />
          </GridElement>
        </Row>
      </Grid>
    </Section>
  )
}

export default ShareSection
