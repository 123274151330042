// eslint-disable-next-line import/no-unresolved
import ApiSearchResponse from 'prismic-javascript/types/ApiSearchResponse'

export const prismicHomePageContent: ApiSearchResponse = {
  page: 1,
  results_per_page: 20,
  results_size: 2,
  total_results_size: 2,
  total_pages: 1,
  next_page: 'null',
  prev_page: 'null',
  results: [
    {
      id: 'Xvyn1REAACMAsLcN',
      uid: 'null',
      type: 'home_page_updates',
      href: 'https://world-community-grid.cdn.prismic.io/api/v2/documents/search?ref=X_XcGxEAACMAsRlP&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22Xvyn1REAACMAsLcN%22%29+%5D%5D',
      tags: [],
      first_publication_date: '2020-07-01T15:12:26+0000',
      last_publication_date: '2021-01-06T15:49:47+0000',
      slugs: ['africa-rainfall-project-makes-plans-for-data-storing-and-sharing', 'this-is-how-a-global-community-unites-to-find-cancer-biomarkers.'],
      lang: 'en-us',
      alternate_languages: [],
      data: {
        body: [
          {
            primary: {
              subject: 'Research update from the MCM team (May 2024)',
              summary:
                'We continue to characterise lung cancer biomarkers identified in the MCM1 project. KLF5 is a transcription factor, which is expressed across numerous organs and tissues.',
              date: '2024-05-13',
              image: {
                url: 'https://lh7-us.googleusercontent.com/dxsLSPRzc4Qwf5mayAnf43FEBezdm4p4gswi-Um-QnMPFVjCH3BdJKzw8NELNEHtl7KvWcGWc3m-9yv-oSW6NbImclRniuvNrsMRknvUOAbsgBHSEHrTVEeK_uP2TmYFn4G2B1AC6Hb5Hwm0L8x6d3g',
              },
              link: 'https://www.worldcommunitygrid.org/about_us/article.s?articleId=812',
            },
          },
          {
            primary: {
              subject: 'Research update from the MCM team (March 2024)',
              summary: 'HSD17B11, a gene associated with lung cancer survival.',
              date: '2024-03-15',
              image: {
                url: 'https://lh7-us.googleusercontent.com/2LkNCc84EcTeebNbBWaJVIMsetylOI_uMuktZKt66sG6kT0AcjwzSjgF25n492BNrojWeIGfFuB3erx26_XzvmKTciydt7jgyJVrfV9AaSC7yvEwodRPco2VQFh_T7_7gjqyaotlMSjAPYFoHJk-UUY',
              },
              link: 'https://www.worldcommunitygrid.org/about_us/article.s?articleId=809',
            },
          },
          {
            primary: {
              subject: 'Research Update from the OPN Team (February 2024)',
              summary: 'New work units targeting the DNA polymerase of cytomegalovirus.',
              date: '2024-02-14',
              image: {
                url: 'https://lh7-us.googleusercontent.com/8YFL_jgxtB7LPXDIjdvJOTZcq2BzT5Kxj3YN8BnZi9r5zmBSJr2qzh3M2jKA25JEpIOelLzAvuFFjDSeXuidfAe6nJKlKImFV40MxJGUEsAhWigvwb6tLno9CfUcgWSgZ1SjpEehTyp64Uqi8cQ5vF8',
              },
              link: 'https://www.worldcommunitygrid.org/about_us/article.s?articleId=806',
            },
          },
        ],
      },
    },
    {
      id: 'Xvu1qxEAACMArIvY',
      uid: 'null',
      type: 'home_page_hero',
      href: 'https://world-community-grid.cdn.prismic.io/api/v2/documents/search?ref=X_XcGxEAACMAsRlP&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22Xvu1qxEAACMArIvY%22%29+%5D%5D',
      tags: [],
      first_publication_date: '2020-06-30T22:02:11+0000',
      last_publication_date: '2021-01-05T19:32:17+0000',
      slugs: ['home--world-community-grid', 'kevin--home--world-community-grid', 'aids-is-constantly-evolving.so-are-the-tools-to-fight-it.'],
      lang: 'en-us',
      alternate_languages: [],
      data: {
        page_title: 'Home | World Community Grid',
        page_description:
          'World Community Grid enables anyone with a computer or Android device to donate their unused computing power to advance cutting-edge scientific research on topics related to health and sustainability.',
        social_image: '/images/logo_images/wcg_logo_square_new_200x200.png',
        background_image: {
          url: '/images/prismic/transitionBig.jpg',
        },
        headline: [
          {
            type: 'paragraph',
            text: 'Celebrating 19 years of World Community Grid',
            spans: [],
          },
          {
            type: 'paragraph',
            text: '',
            spans: [],
          },
        ],
        summary: 'Accelerating science by creating a supercomputer empowered by a global community of volunteers.',
        text_color: 'White',
        image_overlay_style: 'None',
        body: [
          {
            slice_type: 'learn_more',
            primary: {
              link: 'https://www.worldcommunitygrid.org/about_us/viewNewsArticle.do?articleId=757',
            },
          },
          /* prettier-ignore */
          /* This enables the "join" a project button on the home page, uncomment to add it back
          {
            slice_type: 'contribute_link',
            primary: {
              project_short_name: 'opn1',
            },
          },*/
        ],
      },
    },
  ],
}
