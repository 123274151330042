import React, {FunctionComponent, useState} from 'react'
import {RadioButton, RadioButtonGroup} from 'carbon-components-react'

import _get from 'lodash.get'
import _map from 'lodash.map'

import {currentOsName, findOsNameData, OS_NAMES} from '../../../../constants/osNames'
import {MainContentHeader, MainContentWrapper} from '../../../../components/layout'

import {NextButton} from '../../Join/components/NextButton'

const Download: FunctionComponent = () => {
  const [selectedOsName, setSelectedOsName] = useState(currentOsName)
  const selectedDownloadName = _get(selectedOsName, 'download')
  const selectedShortName = _get(selectedOsName, 'short')

  const handleRadioButtonSelection = (newSelection: string) => {
    setSelectedOsName(findOsNameData(OS_NAMES, {short: newSelection}))
  }

  return (
    <>
      <MainContentHeader title="Download" withDivider />
      <MainContentWrapper header="Select platform">
        <RadioButtonGroup defaultSelected={selectedShortName} name="os" orientation="vertical" onChange={handleRadioButtonSelection}>
          {_map(OS_NAMES, ({text: itemLabelText, short: itemShortName}, itemName) => {
            return <RadioButton key={itemName} value={itemShortName} id={`radio-${itemShortName}`} role="group" labelText={itemLabelText} />
          })}
        </RadioButtonGroup>
      </MainContentWrapper>
      <MainContentWrapper>
        <NextButton
          isDownloadableView={selectedDownloadName !== ''}
          disabled={!selectedShortName}
          redirectTo={`/download/${selectedShortName}`}
          pathState={{prevPath: 'select'}}
          platformNameToDownload={selectedDownloadName}
        />
      </MainContentWrapper>
    </>
  )
}

export default Download
