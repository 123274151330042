import React, {FunctionComponent, useCallback, useEffect, useState} from 'react'
import {User20} from '@carbon/icons-react'
import cn from 'classnames'
import {HeaderGlobalAction} from 'carbon-components-react/lib/components/UIShell'
import {header} from '../../content'
import {internal} from '../../../../constants/urls'
import {Roles} from '../../../../services/types'
import {adminRoles} from '../../../../constants/userRoles'
import {handleOutsideClick} from '../../../../utils'
const {accountMenu} = header

interface AccountActionProps {
  loggedIn: boolean
  roles?: Roles[]
}

export interface CustomOverflowMnuProps {
  children: any
  loggedIn: boolean
}
const AccountActionWithDropdown = ({children, loggedIn}: CustomOverflowMnuProps) => {
  const [id] = useState(`dropdownUserMenu`)

  const [isShown, setIsShown] = useState(false)

  const toggle = useCallback(() => {
    setIsShown((prev) => !prev)
  }, [])

  useEffect(() => {
    const handleDropdownOutsideClick = handleOutsideClick(`#${id}`, (e) => {
      setIsShown(false)
    })

    window.addEventListener('click', handleDropdownOutsideClick)
    return () => {
      window.removeEventListener('click', handleDropdownOutsideClick)
    }
  }, [id])

  return (
    <div id={id} style={{width: 'auto', height: '100%'}} className="bx--overflow-menu bx--overflow-menu--account">
      <HeaderGlobalAction
        className={cn('bx--header__action  bx--header__action--account wcg--action', isShown && 'active')}
        aria-label="Account"
        tooltipAlignment="center"
        onClick={toggle}
      >
        {loggedIn ? (
          <div className="account-icon-wrapper">
            <User20 />
          </div>
        ) : (
          <User20 />
        )}
      </HeaderGlobalAction>
      <div className={cn('wcg--action__dropdown bx--overflow-menu-options bx--overflow-menu-options--open', isShown && 'show')}>{children}</div>
    </div>
  )
}

const DropdownItem: FunctionComponent<any> = ({text, href, className}) => {
  return (
    <li className={cn('bx--overflow-menu-options__option', className)}>
      <a tabIndex={0} className="bx--overflow-menu-options__btn" href={href}>
        <div className="bx--overflow-menu-options__option-content">{text}</div>
      </a>
    </li>
  )
}

const AccountAction: FunctionComponent<AccountActionProps> = ({loggedIn, roles}: AccountActionProps) => {
  const isAdmin = roles && roles.some((role) => adminRoles.includes(role as any))
  return (
    <div data-floating-menu-container="true">
      {/* eslint-disable-next-line no-negated-condition */}
      {loggedIn ? (
        <AccountActionWithDropdown loggedIn={loggedIn}>
          {accountMenu.loggedIn.items
            .filter((menuItem) => (isAdmin ? true : menuItem.title !== 'Admin'))
            .map(({title, url}, index) => {
              // return <OverflowMenuItem className="bx--header__menu-item" key={index} href={url} itemText={title} />
              return <DropdownItem key={index} href={url} text={title} />
            })}
        </AccountActionWithDropdown>
      ) : (
        <AccountActionWithDropdown loggedIn={loggedIn}>
          <DropdownItem href={internal['Log in'].url} text="Log in" />
        </AccountActionWithDropdown>
      )}
    </div>
  )
}

export default AccountAction
