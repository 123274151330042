import React, {FunctionComponent} from 'react'
import {Milestone} from '../../../../../services/api/types/milestones-api'
import {internal} from '../../../../../constants/urls'
import {Link} from '../../../../../components/Link'

export interface TeamChallengeMilestoneContentProps {
  milestone: Milestone
}

const TeamChallengeMilestoneContent: FunctionComponent<TeamChallengeMilestoneContentProps> = ({milestone}) => {
  return (
    <>
      <h4>{milestone.teamName}&apos;s victory</h4>
      <p>
        Your team won the <Link href={internal.Challenge.url(milestone.challengeId)}>{milestone.challengeName}</Link> team challenge!
      </p>
    </>
  )
}

export default TeamChallengeMilestoneContent
