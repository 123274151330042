import React, {FunctionComponent, useState, useCallback, useEffect} from 'react'
import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Dropdown,
  OnChangeData,
  Pagination,
} from 'carbon-components-react'

import {api} from '../../../../../services'
import {formatISODateTimeFromTime, formatResults, formatRuntime} from '../../../../../utils'
import {Download} from '../../../../../components/Download'
import {DeviceObject as Device} from '../../../../../services/api/types'

import {InstallationDates, LastResults} from './types'
import {installationDates, lastResults} from './content'

const PAGE_SIZES: [10, 25, 50, 100] = [10, 25, 50, 100]

interface headerType {
  header: string
  key: string
}

interface TableProps {
  rows: any
  headers: any
  getHeaderProps: any
  getRowProps: any
  getTableProps: any
  getToolbarProps: any
  getTableContainerProps: any
}

const dataHeaders: headerType[] = [
  {
    header: 'Device name',
    key: 'name',
  },
  {
    header: 'Last result',
    key: 'lastResult',
  },
  {
    header: 'Runtime',
    key: 'runtimeInSeconds',
  },
  {
    header: 'Results returned',
    key: 'results',
  },
  {
    header: 'Points earned',
    key: 'points',
  },
]

const getParamId = (data: any) => {
  switch (data.id) {
    case 'label':
      return 7
    case 'last-3-days':
      return 3
    case 'last-7-days':
      return 7
    case 'last-14-days':
      return 14
    case 'last-30-days':
      return 30
    case 'last-60-days':
      return 60
    case 'last-90-days':
      return 90
    default:
      return undefined
  }
}

interface deviceRow {
  id: string
  name: string
  lastResult: any
  runtimeInSeconds: number
  points: number
  results: number
}

const deviceToDataRow = (device: Device) => {
  const row: deviceRow = {
    id: device.id.toString(),
    name: device.name,
    lastResult: device.lastResult,
    runtimeInSeconds: device.runtimeInSeconds,
    points: device.points,
    results: device.results,
  }
  return row
}

const formatDate = (device: Device | undefined) => {
  if (device && device.lastResult) {
    const lastResult = formatISODateTimeFromTime(device.lastResult)
    return (
      <span>
        <span className="wcg-team-table-nobr">{lastResult}</span>
      </span>
    )
  }
  return 'No results'
}

const formatDeviceName = (device: Device | undefined) => {
  if (device) {
    return (
      <a className="wcg-no-underline" href={`/contribution/device?id=${device.id}&type=${device.type}`}>
        {device.name}
      </a>
    )
  }
  return 'Error'
}

const renderCell = (id: string | number | null | undefined, cell: any, devices: Device[]) => {
  const device = devices.find((device) => device.id.toString() === id)
  const column = cell.info.header
  if (column === 'results') {
    return formatResults(cell.value)
  } else if (column === 'runtimeInSeconds') {
    return formatRuntime(cell.value)
  } else if (column === 'lastResult') {
    return formatDate(device)
  } else if (column === 'name') {
    return formatDeviceName(device)
  }
  return cell.value
}

const download = () => {
  api.getDevicesForDownload()
}

const DevicesTable: FunctionComponent = () => {
  const [devices, setDevices] = useState<Device[]>()

  const [installationDate, setInstallationDate] = useState<InstallationDates>(installationDates[3])
  const [lastResult, setLastResult] = useState<LastResults>(lastResults[3])

  const [maxDevices, setMaxDevices] = useState<number>(1)
  const [currentPageSize, setCurrentPageSize] = useState<number>(25)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const handleInstallationDatesSelect = useCallback(
    ({selectedItem}: OnChangeData<InstallationDates>) => {
      selectedItem && setInstallationDate(selectedItem)
    },
    [setInstallationDate],
  )
  const handleLastResultSelect = useCallback(
    ({selectedItem}: OnChangeData<LastResults>) => {
      selectedItem && setLastResult(selectedItem)
    },
    [setLastResult],
  )
  const handlePageChange = (data: {page: number; pageSize: number}) => {
    setCurrentPage(data.page)
    setCurrentPageSize(data.pageSize)
  }

  useEffect(() => {
    const getData = async () => {
      const resp = await api.getDevices(getParamId(installationDate), getParamId(lastResult), currentPage, currentPageSize)
      if (resp.data && resp.data.items) {
        setDevices(resp.data.items)
      }
      if (resp.data && resp.data.metadata && resp.data.metadata.recordsAvailable) {
        setMaxDevices(resp.data.metadata.recordsAvailable)
      }
    }
    getData()
  }, [installationDate, lastResult, currentPage, currentPageSize])

  if (devices === undefined) {
    return <DataTableSkeleton />
  }
  const dataRows = devices.map(deviceToDataRow)
  return (
    <div>
      <DataTable rows={dataRows} headers={dataHeaders} isSortable>
        {/* @ts-ignore */}
        {({rows, headers, getHeaderProps, getRowProps, getTableProps, getToolbarProps, getTableContainerProps}: TableProps) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar {...getToolbarProps()}>
              <TableToolbarContent>
                <div className="selectors">
                  <Dropdown
                    light
                    type="inline"
                    ariaLabel="Last Result Dropdown"
                    label="Last result returned"
                    titleText=""
                    id="last-result-dropdown"
                    helperText=""
                    items={lastResults}
                    itemToString={(item) => (item ? item.text : '')}
                    onChange={handleLastResultSelect}
                  />
                  <Dropdown
                    light
                    type="inline"
                    ariaLabel="Installation Date Dropdown"
                    label="Install date"
                    titleText=""
                    id="installation-dates-dropdown"
                    helperText=""
                    items={installationDates}
                    itemToString={(item) => (item ? item.text : '')}
                    onChange={handleInstallationDatesSelect}
                  />
                </div>
                <Download download={download} label="Download devices" />
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header: headerType) => (
                    <TableHeader key={header.key} {...getHeaderProps({header})}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: {id: string | number | null | undefined; cells: any[]}) => (
                  <TableRow key={row.id} {...getRowProps({row})}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{renderCell(row.id, cell, devices)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <div className="pagination-table">
        <Pagination pageSizes={PAGE_SIZES} pageSize={currentPageSize} page={currentPage} totalItems={maxDevices || 0} onChange={handlePageChange} />
      </div>
    </div>
  )
}

export default DevicesTable
