import React, {FunctionComponent} from 'react'
import {ListItem, UnorderedList} from 'carbon-components-react'
import {Link} from '../../../../../../../components'
import {ContentHeader, ContentSubHeader, ContentText, RatioImage} from '../../../index'
import {internal} from '../../../../../../../constants'

const Android: FunctionComponent = () => {
  return (
    <>
      <ContentHeader>Download for Android</ContentHeader>
      <ContentText>In order to contribute to World Community Grid, install the BOINC software application.</ContentText>
      <ContentSubHeader>1. Start installation</ContentSubHeader>
      <ContentText>Click the &quot;Open&quot; on the software download</ContentText>
      <RatioImage url="/images/install/android_install_1.jpg" />
      <ContentSubHeader>2. Change settings</ContentSubHeader>
      <ContentText>Click on &quot;Settings&quot; to make the changes required to allow installation</ContentText>
      <RatioImage url="/images/install/android_install_2.jpg" />
      <ContentSubHeader>3. Allow installation of unknown apps</ContentSubHeader>
      <ContentText>
        Click on the slider to allow installation of unknown apps from Chrome. This a required step so that you can install the BOINC software for Android from
        the World Community Grid website.
      </ContentText>
      <RatioImage url="/images/install/android_install_3.jpg" />
      <ContentSubHeader>4. Install</ContentSubHeader>
      <ContentText>Click on the text &quot;Install&quot; to start the installation of the BOINC software for Android</ContentText>
      <RatioImage url="/images/install/android_install_4.jpg" />
      <ContentSubHeader>5. Open software</ContentSubHeader>
      <ContentText>Click on the text &quot;Open&quot; to launch the BOINC software for Android</ContentText>
      <RatioImage url="/images/install/android_install_5.jpg" />
      <ContentSubHeader>6. Add projects</ContentSubHeader>
      <ContentText>
        Click on the <img height="16" width="16" src="/images/ic_icons/chevron--right.svg" alt="right chevron" /> next to Add Projects
      </ContentText>
      <RatioImage url="/images/install/android_install_6.jpg" />
      <ContentSubHeader>7. Select World Community Grid</ContentSubHeader>
      <ContentText>Check the box to select World Community Grid and then click on the Continue button.</ContentText>
      <RatioImage url="/images/install/android_install_7.jpg" />
      <ContentSubHeader>8. Enter your account information</ContentSubHeader>
      <ContentText>
        <UnorderedList className="shift-unordered-list">
          <ListItem>Enter the email address you use for World Community Grid for both the eMail and Name fields</ListItem>
          <ListItem>Enter your World Community Grid password for the Password field</ListItem>
          <ListItem>Click continue</ListItem>
        </UnorderedList>
      </ContentText>
      <RatioImage url="/images/install/android_install_8.jpg" />
      <ContentSubHeader>9. Start contributing</ContentSubHeader>
      <ContentText>
        Congratulations, you are now connected to World Community Grid and ready to start contributing! Just continue using your device as you normally would,
        and World Community Grid will harness your spare power for scientific research.
      </ContentText>
      <ContentSubHeader>10. Disable installation from unknown sources</ContentSubHeader>
      <ContentText>
        These instructions are based on a Pixel 5 running Android 12. The necessary steps may be different for you depending on your phone model and the version
        of Android it is running.
        <br />
        <br />
        <UnorderedList className="shift-unordered-list">
          <ListItem>Swipe up from the bottom of your screen to the top. If you get All Apps, tap it.</ListItem>
          <ListItem>
            Type &quot;Unknown&quot; into the search box and then click on &quot;Install unknown apps&quot; (in some versions it could be under Biometrics and
            Safety)
          </ListItem>
          <ListItem>Click on &quot;Install unknown apps&quot;</ListItem>
          <ListItem>Click on Chrome</ListItem>
          <ListItem>Click on the slider to disable installation from unknown sources</ListItem>
        </UnorderedList>
      </ContentText>
      <ContentText>
        You have downloaded the Android software.{' '}
        <Link underlined initialSize href={internal.Download.url}>
          Download for other platforms.
        </Link>
      </ContentText>
    </>
  )
}

export default Android
