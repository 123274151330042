import _find from 'lodash.find'
import {osName, isMacOs, isWindows} from 'react-device-detect'
import _get from 'lodash.get'
import {OS_NAMES} from './osNames'

const findOsNameData = (osNamesData: typeof OS_NAMES, criterion: any) => _find(osNamesData, criterion)

const currentOsName = findOsNameData(OS_NAMES, {name: osName})
const currentShortName = _get(currentOsName, 'short')
const currentDownloadName = _get(currentOsName, 'download')

const os = {
  isMacOs,
  isWindows,
}

export {currentShortName, currentDownloadName, currentOsName, findOsNameData, os}
