import React, {FunctionComponent, useState} from 'react'
import {Button, Checkbox, Form, Loading, TextInput} from 'carbon-components-react'
import {useFormik} from 'formik'
import {useDispatch} from 'react-redux'
import _get from 'lodash.get'
import {v4 as uuid} from 'uuid'
import {ArrowRight20} from '@carbon/icons-react'
import {useHistory, useLocation} from 'react-router'
import cn from 'classnames'
import {validator} from '../../../../../../../utils'
import {fetchSignUp} from '../../../../../../../store/user/actions'
import {NotificationsList} from '../../../../../../../components/notifications/NotificationsList'
import {Divider, FormMainContentWrapper, Link, FormGroup, ErrorMessage} from '../../../../../../../components'
import {internal} from '../../../../../../../constants'
import {SignUpError} from './types'

export interface SignUpFormProps {
  noHeader?: boolean
  className?: string
}

const SignUpForm: FunctionComponent<SignUpFormProps> = ({noHeader, className}) => {
  const [isFetching, setIsFetching] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      emailAddress: '',
      password: '',
      acceptTerms: false,
    },
    onSubmit: async (values, form) => {
      try {
        setIsFetching(true)
        await dispatch(fetchSignUp(values))
        history.push({...location, pathname: internal['Join Projects'].url}, {prevPath: 'join'})
      } catch (e) {
        const responseErrors = e && e.validationErrors
        responseErrors &&
          form.setStatus({
            notifications: responseErrors.map((error: SignUpError) => ({
              text: <div dangerouslySetInnerHTML={{__html: error.message}} />,
              id: uuid(),
            })),
          })
      } finally {
        setIsFetching(false)
      }
    },

    validate: (values) =>
      validator.rules({
        emailAddress: validator.validate(values.emailAddress).required('Email address is required').email(),
        password: validator
          .validate(values.password)
          .required('Password is required')
          .minLength(6, 'Password must be between 6 and 100 characters')
          .maxLength(100, 'Password must be between 6 and 100 characters'),
        acceptTerms: validator.validate(values.acceptTerms).positive('You must agree to our license agreement in order to proceed'),
      }),
    validateOnMount: true,
  })
  return (
    <Form className={cn('sign-up-form', className)} onSubmit={formik.handleSubmit}>
      <Loading active={isFetching} withOverlay={true} />
      <FormMainContentWrapper>
        <FormGroup className="form__header-group">
          {!noHeader && (
            <>
              <h4 className="main-content-header__title">Create account</h4>
              <p className="form__short-text">
                Already have an account?{' '}
                <Link underlined href={internal['Log in'].url}>
                  Log in
                </Link>
              </p>
            </>
          )}
          {_get(formik, 'status.notifications') ? (
            <div className="notifications-list-wrapper">
              {' '}
              <NotificationsList data={_get(formik, 'status.notifications')} />
            </div>
          ) : (
            <div className="notifications-list-wrapper__empty" />
          )}
        </FormGroup>
        <Divider />
        <FormGroup>
          <TextInput
            id="emailAddress"
            name="emailAddress"
            labelText="Email address"
            placeholder="example@email.com"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.emailAddress}
            invalid={formik.touched.emailAddress && !!formik.errors.emailAddress}
            invalidText={formik.errors.emailAddress}
          />
        </FormGroup>
        <FormGroup>
          <TextInput.PasswordInput
            id="password"
            name="password"
            labelText="Password"
            placeholder="enter password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            invalid={formik.touched.password && !!formik.errors.password}
            invalidText={formik.errors.password}
          />
        </FormGroup>
        <FormGroup>
          <Checkbox
            labelText={
              <>
                Accept{' '}
                <Link underlined href={internal['Terms of use'].url} target="_blank" rel="noopener noreferrer">
                  end user license agreement
                </Link>
              </>
            }
            id="acceptTerms"
            checked={formik.values.acceptTerms}
            onBlur={formik.handleBlur}
            onChange={(_, __, event) => {
              formik.handleChange(event)
            }}
            name="acceptTerms"
          />
          <ErrorMessage show={!!(formik.touched.acceptTerms && formik.errors.acceptTerms)} text={formik.errors.acceptTerms || ''} />
        </FormGroup>
      </FormMainContentWrapper>
      <Button renderIcon={ArrowRight20} kind="primary" tabIndex={0} type="submit" disabled={!formik.isValid || formik.isValidating || isFetching}>
        {isFetching ? <>Signing Up</> : <>Sign Up</>}
      </Button>
    </Form>
  )
}

export default SignUpForm
