export enum ACTION_TYPE {
  SET_NAVIGATION = 'SET_NAVIGATION',
}

export interface NavigationState {
  type?: string
  section?: string
  highlight?: string
}

export interface SetNavigationAction {
  type: typeof ACTION_TYPE.SET_NAVIGATION
  payload: NavigationState
}

export type NavigationActions = SetNavigationAction
