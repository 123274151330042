import {internal, external} from '../../constants'

export interface Link {
  title: string
  url: string
}

export type Links = Array<Link>

export interface List {
  title: string
  links: Links
}

export type Lists = Array<List>

export const footer = {
  boincLink: {
    url: external.Boinc.url,
  },
  wcgLink: {
    url: internal.WCG.url,
  },
  krembilLink: {
    url: external.Krembil.url,
  },
  jurisicaLabLink: {
    url: external.JurisicaLab.url,
  },
  lists: {
    research: {
      title: 'Research',
      links: [
        {
          title: 'Active Research',
          url: internal['Active research'].url,
        },
        {
          title: 'Completed Research',
          url: internal['Completed research'].url,
        },
        {
          title: 'Submit a proposal',
          url: internal['Submit a proposal'].url,
        },
      ],
    },
    about: {
      title: 'About',
      links: [
        {
          title: 'How it works',
          url: internal['How it works'].url,
        },
        {
          title: 'Our Partners',
          url: internal['Our partners'].url,
        },
        {
          title: 'Become a Partner',
          url: internal['Become a partner'].url,
        },
        {
          title: 'Spread the word',
          url: internal['Spread the word'].url,
        },
      ],
    },
    contribute: {
      title: 'Contribute',
      links: [
        {
          title: 'Join',
          url: internal.Join.url,
        },
        {
          title: 'My contribution',
          url: internal['My contribution'].url,
        },
        {
          title: 'Download',
          url: internal.Download.url,
        },
        {
          title: 'Settings',
          url: internal.Settings.url,
        },
      ],
    },
    community: {
      title: '',
      links: [
        {
          title: 'Community',
          url: internal['Global statistics'].url,
        },
        {
          title: 'News',
          url: internal.News.url,
        },
        {
          title: 'Forums',
          url: internal.Forums.url,
        },
        {
          title: 'Help',
          url: internal.Help.url,
        },
        {
          title: 'Contact',
          url: internal.Contact.url,
        },
      ],
    },
    followUs: {
      title: 'Follow us',
      links: [
        {
          title: 'Twitter',
          url: external.Twitter.url,
        },
        {
          title: 'Facebook',
          url: external.Facebook.url,
        },
      ],
    },
    basement: {
      links: [
        {
          title: 'Privacy statement',
          url: internal['Privacy statement'].url,
        },
        {
          title: 'Terms of use',
          url: internal['Terms of use'].url,
        },
        {
          title: 'Site map',
          url: internal['Site map'].url,
        },
      ],
    },
  },
}
