import React from 'react'
import content from '../../../content'
import {MainContentWrapper} from '../../../../../components'

const ResearchersSaySection = () => {
  const section = content.researchersSaySection
  return (
    <MainContentWrapper header={section.title} className="researchers-say-section" expand withDivider contentClassName="bx--col-lg-7 bx--col-md-7">
      <figure>
        <p>
          <q className="quotation">{section.quote.text}</q>
        </p>
        <figcaption>
          <cite className="author" dangerouslySetInnerHTML={{__html: section.quote.cite}} />
        </figcaption>
      </figure>
    </MainContentWrapper>
  )
}

export default ResearchersSaySection
