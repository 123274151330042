import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {fetchLoginStatus} from '../../../../store/user/actions'

const LoginStatus = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchLoginStatus())
  }, [location])
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}

export default LoginStatus
