import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'

import BaseLayout from '../../../layouts/BaseLayout/BaseLayout'

import {setNavigation} from '../../../store/navigation/actions'
import {PageMetadata} from '../../../components/PageMetadata'
import {metadata} from '../../../constants/metadata'
import {Install} from './Install'
import {Download} from './Download'

const DownloadAgain = ({match}: RouteComponentProps) => {
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setNavigation({section: '', type: 'main', highlight: 'Download'}))
  })

  return (
    <BaseLayout>
      <PageMetadata {...metadata.download} />
      <Switch>
        <Redirect exact from={`${match.path}`} to={{...location, pathname: `${match.path}/select`}} />
        <Route path={`${match.path}/select`} component={Download} />
        <Route path={`${match.path}/:os(debian||windows||fedora||macos||android||raspberry)`} component={Install} />
        <Route render={(routeProps) => <Redirect {...routeProps} to="/notfound" />} />
      </Switch>
    </BaseLayout>
  )
}

export default DownloadAgain
