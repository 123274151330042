import React, {FunctionComponent, useEffect, useState} from 'react'
import {AccordionSkeleton} from 'carbon-components-react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router'
import {internal} from '../../../../../constants'
import {fetchProjects} from '../../../../../store/projects/actions'
import {MainContentWrapper, Link} from '../../../../../components'
import {NextButton} from '../NextButton'
import {api} from '../../../../../services'
import {RootState} from '../../../../../store'
import {ProjectsState} from '../../../../../store/projects/types'
import {currentDownloadName, currentShortName as currentOsShortName} from '../../../../../constants/osNames'
import {isDownloadStep} from '../../helpers'
import {useQuery} from '../../../../../utils'
import {JoinMainHeader} from '../index'
import {projectsSelector} from '../../../../../store/selectors'
import {SelectableAccordion} from './components'

const Projects: FunctionComponent = () => {
  const dispatch = useDispatch()
  const urlParams = useQuery(useLocation())
  const projectFromUrlToSelect = urlParams.get('projectToAdd')
  const [isLoadingProjects, setIsLoadingProjects] = useState(false)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true)
  const {projects} = useSelector<RootState, ProjectsState>(projectsSelector)

  useEffect(() => {
    const getActiveProjectsAndProjectPreferences = async () => {
      setIsLoadingProjects(true)
      await dispatch(fetchProjects())
      setIsLoadingProjects(false)
    }
    getActiveProjectsAndProjectPreferences()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredProjects =
    projects && projects.filter((project) => project.status === 'Active').sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
  const handleCheckboxesChange = (values: any) => {
    setIsNextButtonDisabled(values.length === 0)
    const getProjectPreferences = async () => {
      values.length !== 0 && (await api.putProjectPreferences('default', values))
    }
    getProjectPreferences()
  }
  return (
    <>
      <JoinMainHeader title="Select projects" />
      <MainContentWrapper noVerticalPaddings>
        <div className="projects-page__project-list-wrapper">
          {isLoadingProjects ? (
            <AccordionSkeleton open count={3} />
          ) : (
            <SelectableAccordion
              isLoading={isLoadingProjects}
              items={filteredProjects}
              config={{
                initialValues: projectFromUrlToSelect ? [projectFromUrlToSelect] : [],
                valueField: 'shortName',
                getItemHeaderProps: ({item}: {item: any}) => ({
                  title: item.name,
                  shortName: item.shortName,
                }),
                getItemPanelProps: ({item}: {item: any}) => ({
                  shortName: item.shortName,
                  prismic: item.prismic,
                  platforms: {
                    supportsWindows: item.supportsWindows,
                    supportsMac: item.supportsMac,
                    supportsLinux: item.supportsLinux,
                    supportsAndroid: item.supportsAndroid,
                    supportsRaspberryPi: item.supportsRaspberryPi,
                  },
                }),
              }}
              onChange={handleCheckboxesChange}
              renderItemHeader={(props) => <SelectableAccordion.Header {...props} />}
              renderItemPanel={(props) => <SelectableAccordion.Panel {...props} />}
            />
          )}
        </div>
        <div className="projects-page__app-block">
          <div className="projects-page__buttons-block">
            <NextButton
              disabled={isNextButtonDisabled}
              isDownloadableView={!isDownloadStep}
              redirectTo={isDownloadStep ? internal['Join Download'].url : internal['Install guideline'].url(currentOsShortName)}
              pathState={{prevPath: 'join'}}
              platformNameToDownload={currentDownloadName}
            />
          </div>

          <p>
            Already have the app?{' '}
            <Link underlined inheritFontSize href={internal['My contribution'].url}>
              Go to your account
            </Link>
          </p>
        </div>
      </MainContentWrapper>
    </>
  )
}

export default Projects
