import React, {FunctionComponent} from 'react'
import {Button} from 'carbon-components-react'
import {ArrowRight20} from '@carbon/icons-react'
import {useHistory} from 'react-router'
import cn from 'classnames'
import {useStatus} from '../../pages/Home/components/sections/HeroSection/helpers'
import {external} from '../../constants'
import {Status} from '../../pages/Home/components/sections/HeroSection/types'
import {goByUrl} from '../../utils'
import {DonationActionButtonProps} from './types'

const DonationActionButton: FunctionComponent<DonationActionButtonProps> = ({className, loggedIn, signedUpProject}) => {
  const history = useHistory()
  const [status] = useStatus(loggedIn, signedUpProject)
  const handleDonationActionButtonClick = (status: Status, signedUpProject: string | undefined) => () => {
    const action = {
      [Status.notLoggedIn]: () => {
        goByUrl(external.UHNFoundation.url, history)
      },
      [Status.loggedIn]: () => {
        goByUrl(external.UHNFoundation.url, history)
      },
      [Status.loggedInAndSignedUp]: () => {
        goByUrl(external.UHNFoundation.url, history)
      },
    }
    action[status]()
  }
  const getDonationActionButtonText = (status: Status) => {
    const text = {
      [Status.notLoggedIn]: 'Click here to donate directly to the World Community Grid via UHN Foundation',
      [Status.loggedIn]: 'Click here to donate directly to the World Community Grid via UHN Foundation',
      [Status.loggedInAndSignedUp]: 'Click here to donate directly to the World Community Grid via UHN Foundation',
    }
    return text[status]
  }
  return (
    <Button className={cn('donation-via-uhn__action', className)} renderIcon={ArrowRight20} onClick={handleDonationActionButtonClick(status, signedUpProject)}>
      {getDonationActionButtonText(status)}
    </Button>
  )
}

export default DonationActionButton
