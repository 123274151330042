import React, {FunctionComponent, ReactNode, MouseEvent} from 'react'
import {Link} from 'carbon-components-react'
import cn from 'classnames'
import SideNavLinkText from 'carbon-components-react/lib/components/UIShell/SideNavLinkText'

interface NavTabProps {
  label: string
  isActive: boolean
  children?: ReactNode
  onChangeActiveTab: (label: string) => void
}

const NavTab: FunctionComponent<NavTabProps> = ({label, isActive, children, onChangeActiveTab, ...rest}) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    onChangeActiveTab(label)
  }

  return (
    <li className={cn({'wcg--side-nav__item--current': isActive}, 'wcg--side-nav__item')} {...rest}>
      <Link onClick={handleClick} className={cn('bx--link bx--side-nav__link', isActive && 'bx--side-nav__link--current')}>
        <SideNavLinkText>{label}</SideNavLinkText>
      </Link>
    </li>
  )
}
export default NavTab
