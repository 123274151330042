import React, {FunctionComponent, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  DataTable,
  DataTableSkeleton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react'
import {DataTableHeader} from 'carbon-components-react/lib/components/DataTable/DataTable'
import {Download, PageMetadata} from '../../../components'
import {metadata} from '../../../constants/metadata'
import {BaseLayout, WithContextMenuWrapper} from '../../../layouts'
import {Grid, GridElement, PageHeader, Row} from '../MyContribution/components'
import {setNavigation} from '../../../store/navigation/actions'
import {api} from '../../../services'
import {DailyStatsItem} from '../../../services/api/types'
import {formatResults, formatRuntime} from '../../../utils'

const PAGE_SIZES: [14, 30, 90, 180, 365] = [14, 30, 90, 180, 365]

interface TableProps {
  rows: any
  headers: any
  getHeaderProps: any
  getRowProps: any
  getTableProps: any
  getToolbarProps: any
  getTableContainerProps: any
}

const dataHeaders: DataTableHeader[] = [
  {
    header: 'Statistics date',
    key: 'date',
  },
  {
    header: 'Runtime',
    key: 'runtimeInSeconds',
  },
  {
    header: 'Points earned',
    key: 'points',
  },
  {
    header: 'Results returned',
    key: 'results',
  },
]

interface historyRow {
  id: string
  date: string
  points: number
  results: number
  runtimeInSeconds: number
}

const historyToDataRow = (item: DailyStatsItem) => {
  const row: historyRow = {
    id: `${item.results}${item.date}`,
    date: item.date,
    runtimeInSeconds: item.runtimeInSeconds,
    points: item.points,
    results: item.results,
  }
  return row
}

const renderCell = (cell: any) => {
  const column = cell.info.header
  if (column === 'results') {
    return formatResults(cell.value)
  } else if (column === 'runtimeInSeconds') {
    return formatRuntime(cell.value)
  }
  return cell.value
}

const History: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [historyItems, setHistoryItems] = useState<DailyStatsItem[]>()
  const [maxRecordsAvailable, setMaxRecordsAvailable] = useState<number>(1)
  const [currentPageSize, setCurrentPageSize] = useState<number>(14)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const download = () => {
    api.getStatsHistoryForDownload()
  }

  const handlePageChange = (data: {page: number; pageSize: number}) => {
    setCurrentPage(data.page)
    setCurrentPageSize(data.pageSize)
  }

  useEffect(() => {
    const getData = async () => {
      const resp = await api.getStatsHistory(currentPage, currentPageSize)
      if (resp && resp.data && resp.data.items) {
        setHistoryItems(resp.data.items)
      }
      if (resp && resp.data && resp.data.metadata && resp.data.metadata.recordsAvailable) {
        setMaxRecordsAvailable(resp.data.metadata.recordsAvailable)
      }
    }
    getData()
  }, [currentPage, currentPageSize])

  useEffect(() => {
    dispatch(setNavigation({section: 'My contribution', type: 'main', highlight: 'History'}))

    const getData = async () => {
      const resp = await api.getStatsHistory(currentPage, currentPageSize)
      if (!resp) {
        setHistoryItems([])
      } else if (resp.data && resp.data.items) {
        setHistoryItems(resp.data.items)
      }
    }
    getData()
  }, [])

  if (historyItems === undefined) {
    return (
      <BaseLayout>
        <PageMetadata {...metadata.contribution.history} />
        <WithContextMenuWrapper>
          <PageHeader>History</PageHeader>
          <Grid>
            <Row>
              <GridElement noMinHeight lg={3} md={2} sm={2}>
                <DataTableSkeleton />
              </GridElement>
            </Row>
          </Grid>
        </WithContextMenuWrapper>
      </BaseLayout>
    )
  }

  const dataRows = historyItems.map(historyToDataRow)
  return (
    <BaseLayout>
      <PageMetadata {...metadata.contribution.history} />
      <WithContextMenuWrapper>
        <PageHeader>History</PageHeader>
        <Grid>
          <Row>
            <GridElement noMinHeight lg={3} md={2} sm={2}>
              <DataTable rows={dataRows} headers={dataHeaders}>
                {/* @ts-ignore */}
                {({rows, headers, getHeaderProps, getRowProps, getTableProps, getToolbarProps, getTableContainerProps}: TableProps) => (
                  <TableContainer {...getTableContainerProps()}>
                    <TableToolbar {...getToolbarProps()}>
                      <TableToolbarContent>
                        <Download download={download} label="Download device" />
                      </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {headers.map((header: DataTableHeader) => (
                            <TableHeader key={header.key} {...getHeaderProps({header})}>
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row: {id: string | number | null | undefined; cells: any[]}) => (
                          <TableRow key={row.id} {...getRowProps({row})}>
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>{renderCell(cell)}</TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </DataTable>
              <div className="pagination-table">
                <Pagination
                  pageSizes={PAGE_SIZES}
                  pageSize={currentPageSize}
                  page={currentPage}
                  totalItems={maxRecordsAvailable || 0}
                  onChange={handlePageChange}
                />
              </div>
            </GridElement>
          </Row>
        </Grid>
      </WithContextMenuWrapper>
    </BaseLayout>
  )
}
export default History
