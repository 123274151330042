import React, {FunctionComponent, useEffect, useState} from 'react'
import cn from 'classnames'
import {Recommend20, Star20, CloudUpload20, FaceActivated20, Events20} from '@carbon/icons-react'
import {internal} from '../../../../../../constants/urls'
import {Card} from '../Card'
import {Link} from '../../../../../../components/Link'
import {api} from '../../../../../../services'
import {Milestone} from '../../../../../../services/api/types/milestones-api'
import {convertBadgeTimeToDaysOrYearString, convertRecruitingBadgeLevel} from '../../../../../../utils'

export interface MilestonesCardProps {
  className?: string
}

const milestoneType = {
  B: Recommend20,
  A: Star20,
  R: CloudUpload20,
  J: FaceActivated20,
  T: Events20,
}

const lengthOfMilestones = 4

const description = (milestone: Milestone) => {
  if (milestone.type === 'B') {
    if (milestone.badgeType === 'R') {
      return `${convertRecruitingBadgeLevel(milestone.badgeLevel || 0)} badge for recruiting`
    } else {
      return `${convertBadgeTimeToDaysOrYearString(milestone.badgeRuntime || 0)} badge for ${milestone.projectName}`
    }
  } else if (milestone.type === 'A') {
    return `${milestone.year} year anniversary`
  } else if (milestone.type === 'T') {
    return "Your team's victory"
  } else if (milestone.type === 'R') {
    return 'Your first result'
  } else if (milestone.type === 'J') {
    return 'Joined World Community Grid'
  }
  return 'Unknown'
}

const MilestonesCard: FunctionComponent<MilestonesCardProps> = ({className, ...rest}) => {
  const [milestones, setMilestones] = useState<Milestone[] | undefined>(undefined)
  useEffect(() => {
    const getData = async () => {
      const resp = await api.getMilestones(lengthOfMilestones)
      const milestones = resp.data
      const milestonesItems = milestones && milestones.items
      if (milestonesItems && milestonesItems.length > lengthOfMilestones) {
        milestonesItems.length = lengthOfMilestones
      }
      setMilestones(milestonesItems)
    }
    getData()
  }, [])

  return (
    <Card className={cn('milestones-card', className)} {...rest}>
      <Card.InnerWrapper>
        <Card.Header className="milestones-card-horizontal-wrapper">
          <Card.Title className="milestones-card-title">Milestones</Card.Title>
          <Link href={internal['Contribution Milestones'].url}>
            <Card.Text>View all</Card.Text>
          </Link>
        </Card.Header>
        <Card.List>
          {milestones ? (
            milestones.map((milestone: Milestone, index: number) => {
              return <Card.ListRow key={index} text={description(milestone)} icon={milestoneType[milestone.type]} />
            })
          ) : (
            <Card.ListSkeleton />
          )}
        </Card.List>
      </Card.InnerWrapper>
    </Card>
  )
}

export default MilestonesCard
