import {Reducer} from 'redux'
import {ACTION_TYPE, LoginRedirectActions, LoginRedirectState} from './types'

const initialState: LoginRedirectState = {
  location: '/contribution/overview',
}

export const loginRedirectReducer: Reducer<LoginRedirectState, LoginRedirectActions> = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SET_LOGIN_REDIRECT:
      return {...state, ...action.payload}
    case ACTION_TYPE.DEL_LOGIN_REDIRECT:
      return initialState
    default:
      return state
  }
}
