import React, {FunctionComponent, ReactNode, ReactNodeArray, useCallback, useEffect, useState, ReactElement} from 'react'
import cn from 'classnames'

import TabBarNavItem from './TabBarNavItem'
import {RenderTabsProps, TabBarProps} from './types'

const TabBar: FunctionComponent<TabBarProps> = ({children, className, navContainerClassName, contentContainerClassName, isRenderInnerTabs, renderTabs}) => {
  const [activeTabLabel, setActiveTabLabel] = useState<string>('')

  const getChildrenLabels = useCallback(
    (children: ReactElement<{label: string}>) =>
      Array.isArray(children) ? children.map((child: ReactElement<{label: string}>) => child && child.props.label) : [],
    [],
  )

  useEffect(() => {
    const labels = getChildrenLabels(children)
    const initialTabLabel = labels[0]
    setActiveTabLabel(initialTabLabel)
  }, [children, getChildrenLabels, setActiveTabLabel])

  const handleRenderTabs = (): ReactNode => {
    const labels = getChildrenLabels(children)
    return renderTabs({labels, activeTabLabel, setActiveTabLabel})
  }

  return (
    <div className={cn('tab-bar', className)}>
      {isRenderInnerTabs && <div className={cn('tab-bar-nav-container', navContainerClassName)}>{handleRenderTabs()}</div>}
      <div className={cn('tab-bar-content-container', contentContainerClassName)}>
        {
          //@ts-ignore
          React.Children.map(children, (child: any) => React.cloneElement(child, {activeTabLabel}))
        }
      </div>
    </div>
  )
}

TabBar.defaultProps = {
  className: 'bx--row',
  navContainerClassName: 'bx--col-lg-3 bx--col-md-3 bx--col-sm-4 bx--no-gutter',
  contentContainerClassName: 'bx--col-lg-8 bx--offset-lg-1 bx--offset-md-1 bx--col-md-4 bx--col-sm-4',
  isRenderInnerTabs: true,
  vertical: false,
  renderTabs: ({labels, activeTabLabel, setActiveTabLabel}: RenderTabsProps): ReactNodeArray => {
    return labels.map((navLabel) => (
      <TabBarNavItem key={navLabel} navLabel={navLabel} className={cn({active: activeTabLabel === navLabel})} onChangeActiveTab={setActiveTabLabel} />
    ))
  },
}

export default TabBar
