import _get from 'lodash.get'
import React from 'react'
import {getPlatformName, PlatformsCompliance} from '../../helpers'
import {PanelComponent} from './types'

const Panel: PanelComponent = ({platforms, prismic}): JSX.Element => {
  const getSupportedPlatformsList = (platforms: any) => Object.keys(platforms).filter((platformType) => platforms[platformType])
  return (
    <div className="projects-list__panel-wrapper">
      <div className="bx--grid bx--grid--condensed panel__grid">
        <div className="bx--row">
          <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
            <div className="panel__image-container">
              <div className="bx--aspect-ratio bx--aspect-ratio--16x9">
                <div className="bx--aspect-ratio--object">
                  {<img alt={_get(prismic, 'short_summary')} className="panel__image" src={_get(prismic, 'medium_summary_header_image.small.url')} />}
                </div>
              </div>
            </div>
          </div>
          <div className="bx--col-lg-8 bx--col-md-3 bx--col-sm-4">
            <div className="panel__description">
              <div className="panel__description-section panel__description-text">
                <h6 className="panel__title">Description</h6>
                {<p className="panel__text">{_get(prismic, 'short_summary')}</p>}
              </div>
              <div className="panel__description-section">
                <h6 className="panel__title">System Requirements</h6>
                <p className="panel__text">
                  Operating system:{' '}
                  {getSupportedPlatformsList(platforms)
                    .map((platformType: string) => getPlatformName(platformType as keyof typeof PlatformsCompliance))
                    .join(', ')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Panel.defaultProps = {
  platforms: {
    supportsAndroid: false,
    supportsLinux: false,
    supportsMac: false,
    supportsRaspberryPi: false,
    supportsWindows: false,
  },
  prismic: {
    medium_summary_header_image: {small: {url: ''}},
    my_contribution_statement: 'default',
    name: 'default',
    short_name: 'default',
    short_summary: 'default',
    tiny_summary: 'default',
  },
}

export default Panel
