import {useHistory} from 'react-router'
import {Button} from 'carbon-components-react'
import {ArrowRight20} from '@carbon/icons-react'
import React, {FunctionComponent} from 'react'
import content from '../../../content'

import {internal, colors} from '../../../../../constants'

interface YourSparePowerSectionProps {
  backgroundColor: string
  imageSrc: string
}

const YourSparePowerSection: FunctionComponent<YourSparePowerSectionProps> = ({backgroundColor = colors.lightBlue, imageSrc}) => {
  const section = content.yourSparePowerSection
  const history = useHistory()
  const handleClick = () => {
    history.push(internal.Join.url)
  }

  return (
    <div className="main-content-wrapper__full-width-container" style={{backgroundColor}}>
      <div className="you-spare-power-section bx--grid">
        <div className="you-spare-power-section__image-container">
          <div className="bx--aspect-ratio bx--aspect-ratio--1x1">
            <div className="bx--aspect-ratio--object ">
              <img src={imageSrc} className="image" alt="" />
            </div>
          </div>
        </div>
        <div className="you-spare-power-section__content bx--grid">
          <div className="bx--row">
            <h4 className="you-spare-power-section__title bx--col-lg-5 bx--col-md-7 bx--col-sm-3">{section.title}</h4>
            <div className="bx--col-lg-11 bx--col-md-1" />
          </div>
          <div className="bx--row">
            <p className="you-spare-power-section__text bx--col-lg-5 bx--col-md-4 bx--col-sm-3">{section.text}</p>
            <div className="bx--col-lg-11 bx--col-md-0" />
            <div className="you-spare-power-section__button-container bx--offset-lg-12 bx--col-lg-4 bx--col-md-4 bx--no-gutter">
              <Button className="button" renderIcon={ArrowRight20} onClick={handleClick}>
                <span className="button__text">Join</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default YourSparePowerSection
