import React, {FunctionComponent, ReactNode} from 'react'
import cn from 'classnames'
import {MainContentHeader} from '../../../../../components'
import {MainContentHeaderProps} from '../../../../../components/layout/MainContentHeader/types'

interface JoinMainHeader extends MainContentHeaderProps {
  title: string | ReactNode
  className?: string
}

const JoinMainHeader: FunctionComponent<JoinMainHeader> = ({className, ...rest}) => {
  return <MainContentHeader className={cn('main-content-header__join-process', className)} {...rest} />
}

export default JoinMainHeader
