//import Prismic from 'prismic-javascript'
import {AxiosResponse} from 'axios'
import ApiSearchResponse from 'prismic-javascript/types/ApiSearchResponse.d'
import AxiosService from './AxiosService'
import {
  Analytics,
  Token,
  UserData,
  LoginStatus,
  LoginData,
  Projects,
  SingleProjectResponse,
  ProjectHistoryResponse,
  HistoryToday,
  HistoryYesterday,
  ProjectPreferences,
  Client,
  Profile,
  Global,
  HomePageContent,
  Category,
  MemberStatsData,
  GetDevicesResponseData,
  GetBadgesResponseData,
  GetProjectHistoryResponseData,
  DailyHistoryResponseData,
  GetResultsResponseData,
  ResultWithLog,
  WorkunitData,
} from './types'
import {apiv2Axios, apiv3Axios} from './apiConfig'
import {MemberStats} from './types/member-api'
import {StreakCurrentData, StreakData, StreakLatestData} from './types/streak-stats-api'
import {formatPrismicProjectsByShortnames} from './content/prismic-projects-by-shortname'
import {prismicHomePageContent} from './content/prismic-home-page-content'

const apiv2Service = new AxiosService(apiv2Axios, {withCredentials: true})
const apiv3Service = new AxiosService(apiv3Axios, {withCredentials: true})

//const prismicService = Prismic.client(prismicEndpoint, {accessToken: prismicAccessToken})

class ApiService {
  getAnalytics = async (): Promise<AxiosResponse<Analytics>> => {
    const response: AxiosResponse<Analytics> = await apiv3Service.templateMethod({
      url: '/analytics',
    })
    return response
  }

  putAnalytics = async (data: string): Promise<AxiosResponse<any>> => {
    const response: AxiosResponse<any> = await apiv3Service.templateMethod({
      url: '/analytics',
      method: 'POST',
      data,
    })
    return response
  }

  getToken = async (): Promise<AxiosResponse<Token>> => {
    const response: AxiosResponse<Token> = await apiv2Service.templateMethod({
      url: '/token',
    })
    return response
  }

  postUsers = async (userData: UserData): Promise<AxiosResponse<any>> => {
    let response: AxiosResponse<any> = await this.getToken()
    // @ts-ignore
    if (response === undefined || (response.response && response.response.status >= 500)) {
      return response
    }

    const {
      data: {token},
    } = response

    const reqData = {...userData, token}
    const queryString = Object.keys(reqData)
      .map((key) => `${key}=${encodeURIComponent(reqData[key])}`)
      .join('&')

    response = await apiv2Service.templateMethod({
      url: '/users',
      method: 'POST',
      data: queryString,
    })

    return response
  }

  getLoginStatus = async (): Promise<AxiosResponse<LoginStatus>> => {
    const response: AxiosResponse<LoginStatus> = await apiv2Service.templateMethod({
      url: '/login-status',
    })
    return response
  }

  postLogin = async (loginData: LoginData): Promise<AxiosResponse<any>> => {
    const queryString = Object.keys(loginData)
      .filter((key) => loginData[key] !== undefined)
      .map((key) => `${key}=${encodeURIComponent(loginData[key])}`)
      .join('&')

    const response: AxiosResponse<any> = await apiv3Service.templateMethod({
      url: '/login',
      method: 'POST',
      data: queryString,
    })

    return response
  }

  getProjects = async (): Promise<AxiosResponse<Projects>> => {
    const response: AxiosResponse<Projects> = await apiv3Service.templateMethod({
      url: '/project',
    })
    return response
  }

  getProject = async (projectShortName: string): Promise<AxiosResponse<SingleProjectResponse>> => {
    const response: AxiosResponse<SingleProjectResponse> = await apiv3Service.templateMethod({
      url: `/project/${projectShortName}`,
    })
    return response
  }

  getProjectHistory = async (projectShortName: string): Promise<AxiosResponse<ProjectHistoryResponse>> => {
    const response: AxiosResponse<ProjectHistoryResponse> = await apiv2Service.templateMethod({
      url: `/projects/${projectShortName}/history`,
    })
    return response
  }

  getProjectHistoryToday = async (projectShortName: string): Promise<AxiosResponse<HistoryToday>> => {
    const response: AxiosResponse<HistoryToday> = await apiv2Service.templateMethod({
      url: `/projects/${projectShortName}/history/today`,
    })
    return response
  }

  getProjectHistoryYesterday = async (projectShortName: string): Promise<AxiosResponse<HistoryYesterday>> => {
    const response: AxiosResponse<HistoryYesterday> = await apiv2Service.templateMethod({
      url: `/projects/${projectShortName}/history/yesterday`,
    })
    return response
  }

  getProjectPreferences = async (profileName: string): Promise<ProjectPreferences> => {
    const response: AxiosResponse<ProjectPreferences> = await apiv2Service.templateMethod({
      url: `/ms/project-preferences/${profileName}`,
    })
    return response && response.data
  }

  putProjectPreferences = async (profileName: string, projectsShortNames: Array<string>): Promise<ProjectPreferences> => {
    const {
      data: {token},
    } = await this.getToken()

    const preferredProjectList = projectsShortNames && projectsShortNames.join(',')
    const queryParams = {preferredProjectList, token}
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&')

    const response: AxiosResponse<ProjectPreferences> = await apiv2Service.templateMethod({
      url: `/ms/project-preferences/${profileName}?${queryString}`,
      method: 'PUT',
    })
    return response && response.data
  }

  getPrismicProjectsByShortNames = async (shortNames: Array<string> = []): Promise<ApiSearchResponse> => {
    // const response: ApiSearchResponse = await prismicService.query(Prismic.Predicates.any('my.project.short_name', []))
    const response = await formatPrismicProjectsByShortnames([...shortNames])
    return response
  }

  getPrismicHomePageContent = async (): Promise<HomePageContent> => {
    // const response: ApiSearchResponse = await prismicService.query(
    //   Prismic.Predicates.any('document.type', ['home_page_hero', 'home_page_updates']),
    // )
    const response = await prismicHomePageContent
    const homePageContent: Record<string, unknown> = {}
    response.results.forEach((item) => {
      if (item.type === 'home_page_hero') {
        homePageContent.hero = item.data
      } else {
        homePageContent.updates = item.data
      }
    })
    return homePageContent
  }

  getClients = async (): Promise<AxiosResponse<Client>> => {
    const response: AxiosResponse<Client> = await apiv2Service.templateMethod({
      url: '/clients',
    })
    return response
  }

  getProfile = async (): Promise<AxiosResponse<Profile>> => {
    const response: AxiosResponse<Profile> = await apiv2Service.templateMethod({
      url: '/ms/profile',
    })
    return response
  }

  getGlobal = async (): Promise<AxiosResponse<Global>> => {
    const response: AxiosResponse<Global> = await apiv3Service.templateMethod({
      url: '/global',
    })
    return response
  }

  getHelpCategories = async (): Promise<AxiosResponse<Category[]>> => {
    const response: AxiosResponse<Category[]> = await apiv3Service.templateMethod({
      url: '/help/categories?depth=2',
    })
    return response
  }

  getStats = async (type = ''): Promise<AxiosResponse<MemberStatsData>> => {
    let url = '/ms/stats'
    if (type && type.length > 0) {
      url = `${url}?type=${type}`
    }
    const response: AxiosResponse<MemberStatsData> = await apiv3Service.templateMethod({
      url,
    })
    return response
  }

  getStats30Day = async (): Promise<AxiosResponse<MemberStats>> => {
    const response: AxiosResponse<MemberStats> = await apiv3Service.templateMethod({
      url: '/ms/stats/30day',
    })
    return response
  }

  getStatsYesterday = async (): Promise<AxiosResponse<MemberStats>> => {
    const response: AxiosResponse<MemberStats> = await apiv3Service.templateMethod({
      url: '/ms/stats/yesterday',
    })
    return response
  }

  getMilestones = async (max = 4): Promise<AxiosResponse<any>> => {
    const response: AxiosResponse<any> = await apiv3Service.templateMethod({
      url: `/ms/milestones?limit=${max}`,
    })
    return response
  }

  getDevices = async (
    installedSince: number | undefined = undefined,
    lastResult: number | undefined = undefined,
    pageNumber: number | undefined = undefined,
    limit: number | undefined = undefined,
  ): Promise<AxiosResponse<GetDevicesResponseData>> => {
    let url = '/ms/devices'
    if (limit && pageNumber) {
      url = `${url}?pageNumber=${pageNumber}&limit=${limit}`
      if (installedSince) {
        url = `${url}&installedSince=${installedSince}`
      }
      if (lastResult) {
        url = `${url}&lastResult=${lastResult}`
      }
    }
    const response: AxiosResponse<GetDevicesResponseData> = await apiv3Service.templateMethod({
      url,
    })
    return response
  }
  getDevice = async (id: string | null, type: string | null): Promise<AxiosResponse<any>> => {
    const url = `/ms/devices/${id}?type=${type}`
    const response: AxiosResponse<any> = await apiv3Service.templateMethod({
      url,
    })
    return response
  }
  getDevicesForDownload = async (): Promise<AxiosResponse<GetDevicesResponseData>> => {
    const response: AxiosResponse<any> = await apiv3Service.downloadMethod('devices.csv', {
      url: '/ms/devices?limit=0',
    })
    return response
  }

  getTeamsForDownload = async (): Promise<AxiosResponse<GetDevicesResponseData>> => {
    const response: AxiosResponse<GetDevicesResponseData> = await apiv3Service.downloadMethod('teams.csv', {
      url: '/ms/stats/teams',
    })
    return response
  }

  getBadges = async (): Promise<AxiosResponse<GetBadgesResponseData>> => {
    const response: AxiosResponse<GetDevicesResponseData> = await apiv3Service.templateMethod({
      url: 'ms/stats?type=badge',
    })
    return response
  }

  getMyProjectHistory = async (since: string | undefined = undefined): Promise<AxiosResponse<GetProjectHistoryResponseData>> => {
    let url = '/ms/stats/project/history?limit=0'
    if (since) {
      url = `${url}&since=${since}`
    }
    const response: AxiosResponse<GetProjectHistoryResponseData> = await apiv3Service.templateMethod({
      url,
    })
    return response
  }

  getMyProjectHistoryForDownload = async (): Promise<AxiosResponse<DailyHistoryResponseData>> => {
    const response: AxiosResponse<DailyHistoryResponseData> = await apiv3Service.downloadMethod('history.csv', {
      url: '/ms/stats/project/history?limit=0',
    })
    return response
  }

  getStatsHistory = async (pageNumber = 1, limit = 14): Promise<AxiosResponse<DailyHistoryResponseData>> => {
    const response: AxiosResponse<DailyHistoryResponseData> = await apiv3Service.templateMethod({
      url: `/ms/stats/history?limit=${limit}&pageNumber=${pageNumber}`,
    })
    return response
  }

  getStatsHistoryForDownload = async (): Promise<AxiosResponse<DailyHistoryResponseData>> => {
    const response: AxiosResponse<DailyHistoryResponseData> = await apiv3Service.downloadMethod('history.csv', {
      url: '/ms/stats//history?limit=0',
    })
    return response
  }

  getStreak = async (): Promise<AxiosResponse<StreakData>> => {
    const response: AxiosResponse<MemberStatsData> = await apiv3Service.templateMethod({
      url: '/ms/streaks',
    })

    return response
  }

  getStreakCurrent = async (): Promise<AxiosResponse<StreakCurrentData>> => {
    const response: AxiosResponse<MemberStatsData> = await apiv3Service.templateMethod({
      url: '/ms/streaks/current',
    })

    return response
  }

  getStreakLongest = async (): Promise<AxiosResponse<StreakLatestData>> => {
    const response: AxiosResponse<MemberStatsData> = await apiv3Service.templateMethod({
      url: '/ms/streaks/longest',
    })

    return response
  }

  getDevicesHistory = async (
    id: string | null,
    type: string | null,
    pageNumber: number | undefined = undefined,
    limit: number | undefined = undefined,
  ): Promise<AxiosResponse<DailyHistoryResponseData>> => {
    let url = `/ms/devices/history?id=${id}&type=${type}`
    if (limit && pageNumber) {
      url = `${url}&pageNumber=${pageNumber}&limit=${limit}`
    }
    const response: AxiosResponse<DailyHistoryResponseData> = await apiv3Service.templateMethod({
      url,
    })
    return response
  }

  getDevicesHistoryForDownload = async (id: string | null, type: string | null): Promise<AxiosResponse<DailyHistoryResponseData>> => {
    const response: AxiosResponse<DailyHistoryResponseData> = await apiv3Service.downloadMethod('history.csv', {
      url: `/ms/devices/history?id=${id}&type=${type}&limit=0`,
    })
    return response
  }

  // eslint-disable-next-line complexity
  getParamsForResults = (
    deviceId: string | null,
    projectId: string | null,
    serverStatusIds: string[] | null,
    outcomeIds: string[] | null,
    validationStatusIds: string[] | null,
    sortField: string | null,
    pageNumber: number | null = null,
    limit: number | null = null,
    sortDirection: string | null = null,
    // eslint-disable-next-line max-params
  ) => {
    let param = ``
    if (deviceId && deviceId != '0') {
      param = `${param}deviceId=${deviceId}&`
    }
    if (projectId && projectId != '-1') {
      param = `${param}projectId=${projectId}&`
    }
    if (serverStatusIds) {
      serverStatusIds.forEach((elem) => (param = `${param}serverStatusIds=${elem}&`))
    }
    if (outcomeIds) {
      outcomeIds.forEach((elem) => (param = `${param}outcomeIds=${elem}&`))
    }
    if (validationStatusIds) {
      validationStatusIds.forEach((elem) => (param = `${param}validationStatusIds=${elem}&`))
    }
    if (sortField) {
      param = `${param}sortBy=${sortField}&`
    }
    if (pageNumber && pageNumber != 1) {
      param = `${param}pageNumber=${pageNumber}&`
    }
    if (limit && limit != 25) {
      param = `${param}limit=${limit}&`
    }
    if (sortDirection && sortDirection != 'ASC') {
      param = `${param}sortDirection=${sortDirection}&`
    }
    if (param.length > 0 && param[param.length - 1] === '&') {
      return param.slice(0, -1)
    }
    return param
  }

  getResults = async (
    deviceId: string | null,
    projectId: string | null,
    serverStatusIds: string[] | null,
    outcomeIds: string[] | null,
    validationStatusIds: string[] | null,
    sortField = 'sentTime',
    pageNumber: number | undefined = undefined,
    limit: number | undefined = undefined,
    // eslint-disable-next-line max-params
  ): Promise<AxiosResponse<GetResultsResponseData>> => {
    let url = `/ms/results`
    let param = this.getParamsForResults(deviceId, projectId, serverStatusIds, outcomeIds, validationStatusIds, sortField)
    if (limit && pageNumber) {
      param = `${param}pageNumber=${pageNumber}&limit=${limit}`
    }

    if (param.length > 0) {
      url = `${url}?${param}`
    }
    const response: AxiosResponse<GetProjectHistoryResponseData> = await apiv3Service.templateMethod({
      url,
    })
    return response
  }

  getResultsForDownload = async (
    deviceId: string | null,
    projectId: string | null,
    serverStatusIds: string[] | null,
    outcomeIds: string[] | null,
    validationStatusIds: string[] | null,
    sortField = 'sentTime',
  ): Promise<AxiosResponse<GetDevicesResponseData>> => {
    let url = `/ms/results`
    const param = this.getParamsForResults(deviceId, projectId, serverStatusIds, outcomeIds, validationStatusIds, sortField)

    if (param.length > 0) {
      url = `${url}?${param}`
    }
    const response: AxiosResponse<GetDevicesResponseData> = await apiv3Service.downloadMethod('history.csv', {
      url,
    })
    return response
  }

  getResult = async (resultId: number): Promise<AxiosResponse<ResultWithLog>> => {
    const url = `/ms/results/${resultId}`
    const response: AxiosResponse<ResultWithLog> = await apiv3Service.templateMethod({url})
    return response
  }

  getWorkunit = async (workunitId: number): Promise<AxiosResponse<WorkunitData>> => {
    const url = `/ms/workunit/${workunitId}`
    const response: AxiosResponse<WorkunitData> = await apiv3Service.templateMethod({url})
    return response
  }

  getDeviceDomain = async (): Promise<AxiosResponse<GetBadgesResponseData>> => {
    const response: AxiosResponse<GetDevicesResponseData> = await apiv3Service.templateMethod({
      url: '/ms/results/devices',
    })
    return response
  }

  getProjectDomain = async (): Promise<AxiosResponse<GetBadgesResponseData>> => {
    const response: AxiosResponse<GetDevicesResponseData> = await apiv3Service.templateMethod({
      url: '/ms/results/projects',
    })
    return response
  }
}

const api = new ApiService()
export default api
