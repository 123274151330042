import React, {FunctionComponent} from 'react'
import cn from 'classnames'
import {ErrorMessageProps} from './types'

interface WithClassNameProps {
  className?: string
}

const FormMainContentWrapper: FunctionComponent<WithClassNameProps> = ({children, className, ...rest}) => (
  <div className={cn('form__main-content', className)} {...rest}>
    {children}
  </div>
)

const FormButtonContentWrapper: FunctionComponent<WithClassNameProps> = ({children, className, ...rest}) => (
  <div className={cn('form__button-group', className)} {...rest}>
    {children}
  </div>
)

const FormHeaderText: FunctionComponent<WithClassNameProps> = ({children, className, ...rest}) => (
  <h4 className={cn('form__header-text', className)} {...rest}>
    {children}
  </h4>
)

const FormHeaderShortText: FunctionComponent<WithClassNameProps> = ({children, className, ...rest}) => (
  <p className={cn('form__short-text', className)} {...rest}>
    {children}
  </p>
)

const FormGroup: FunctionComponent<WithClassNameProps> = ({children, className, ...rest}) => (
  <div className={cn('bx--fieldset form__form-group', className)} {...rest}>
    {children}
  </div>
)

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({text, show}) => (
  <div className={cn('bx--form-requirement', show && 'form__error-message')}>{text}</div>
)

export {FormMainContentWrapper, FormButtonContentWrapper, FormHeaderText, FormHeaderShortText, FormGroup, ErrorMessage}
