import React, {Fragment, FunctionComponent} from 'react'
import {useHistory} from 'react-router'
import cn from 'classnames'
import {Button} from 'carbon-components-react'
import {ArrowRight20} from '@carbon/icons-react'
import {JoinActionButton} from '../../../../../components/JoinActionButton'
import {goByUrl} from '../../../../../utils'
import {
  AccordanceMaps,
  AccordanceTypes,
  HomeSectionProps,
  OverlayNameAccordance,
  OverlayNameAccordanceTypes,
  TextNameAccordance,
  TextNameAccordanceTypes,
} from './types'

const defaultProps = {
  textColor: 'White' as TextNameAccordanceTypes,
  imageOverlayStyle: 'None' as OverlayNameAccordanceTypes,
}

const HeroSection: FunctionComponent<HomeSectionProps> = ({
  heroImageUrl,
  titleParagraphs,
  text,
  heroShortName,
  learnLink,
  loggedIn,
  signedUpProject,
  imageOverlayStyle,
  textColor,
}) => {
  const history = useHistory()
  const getClassname = (type: AccordanceTypes, accordanceMap: AccordanceMaps): string => type && accordanceMap[type]

  const overlayClassname = getClassname(imageOverlayStyle, OverlayNameAccordance)
  const textClassname = getClassname(textColor, TextNameAccordance)

  const click = () => {
    learnLink && goByUrl(learnLink, history)
  }

  return (
    <div className="hero-space-section">
      <div className="hero-image-container">
        <img alt="" className="hero-image" src={heroImageUrl} />
      </div>
      <div className={cn('hero-overlay', overlayClassname)} />
      <div className={cn('hero-content-container', textClassname)}>
        <div className=" bx--grid">
          <div className="bx--row">
            <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
              <div className="hero-content">
                <p className="hero-title">
                  {titleParagraphs &&
                    titleParagraphs.map((paragraph, index) => {
                      if (typeof paragraph === 'undefined' || typeof paragraph.text === 'undefined' || paragraph.text.trim().length === 0) {
                        return <Fragment key={index} />
                      }
                      return (
                        <Fragment key={index}>
                          {paragraph.text}
                          <br />
                        </Fragment>
                      )
                    })}
                </p>
                <p className="hero-description">{text}</p>
                {learnLink && (
                  <Button renderIcon={ArrowRight20} onClick={click}>
                    Learn more
                  </Button>
                )}
                {heroShortName && <JoinActionButton loggedIn={loggedIn} signedUpProject={signedUpProject} currentProjectShortName={heroShortName} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HeroSection.defaultProps = defaultProps

export default HeroSection
