import React, {FunctionComponent, useEffect, useState} from 'react'
import {BreadcrumbItem, Breadcrumb} from 'carbon-components-react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'

import {Grid, Row, GridElement} from '../MyContribution/components/layout'
import {PageHeader} from '../MyContribution/components/layout/headers/PageHeader'
import {WithContextMenuWrapper, BaseLayout} from '../../../layouts'
import {PageMetadata} from '../../../components/PageMetadata'
import {metadata} from '../../../constants/metadata'
import {setNavigation} from '../../../store/navigation/actions'
import {api} from '../../../services'
import {HistoryTable, Box} from './components'

const DeviceHistory: FunctionComponent = () => {
  const [device, setDevice] = useState<string>('')
  const [statusError, setStatusError] = useState<boolean>(false)
  const dispatch = useDispatch()
  const deviceId = new URLSearchParams(useLocation().search).get('id')
  const deviceType = new URLSearchParams(useLocation().search).get('type')
  useEffect(() => {
    dispatch(setNavigation({section: 'My contribution', type: 'main', highlight: 'Devices'}))

    const getData = async () => {
      const resp = await api.getDevice(deviceId, deviceType)
      if (!resp) {
        setDevice('Device not found')
        setStatusError(true)
      } else if (resp.data && resp.data.name) {
        setDevice(resp.data.name)
      }
    }
    getData()
  }, [deviceId, deviceType])

  return (
    <BaseLayout>
      <PageMetadata {...metadata.contribution.device} />
      <WithContextMenuWrapper>
        <div className="section-breadcrumb">
          <Breadcrumb>
            <BreadcrumbItem href="/contribution/devices">Devices</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <PageHeader>{device}</PageHeader>
        {statusError ? (
          <Grid>
            <Row>
              <GridElement noMinHeight lg={3} md={2} sm={2}>
                <p>The device you attempted to view could not be found.</p>
              </GridElement>
            </Row>
          </Grid>
        ) : (
          <div>
            <Grid>
              <Row>
                <GridElement noMinHeight lg={3} md={2} sm={2}>
                  <HistoryTable id={deviceId} type={deviceType} />
                </GridElement>
              </Row>
            </Grid>
            <Box />
          </div>
        )}
      </WithContextMenuWrapper>
    </BaseLayout>
  )
}
export default DeviceHistory
