import React, {FunctionComponent} from 'react'
import {ProgressIndicator, ProgressStep} from 'carbon-components-react'
import {isDownloadStep} from '../../helpers'

interface JoinProccessIndicatorBarProps {
  step: number
}

const JoinProccessIndicatorBar: FunctionComponent<JoinProccessIndicatorBarProps> = ({step}) => {
  return isDownloadStep ? (
    <ProgressIndicator className="join-progress-indicator" vertical={false} currentIndex={step}>
      <ProgressStep label="Create account" />
      <ProgressStep label="Select projects" />
      <ProgressStep label="Select platform" />
      <ProgressStep label="Install software" />
    </ProgressIndicator>
  ) : (
    <ProgressIndicator className="join-progress-indicator" vertical={false} currentIndex={step}>
      <ProgressStep label="Create account" />
      <ProgressStep label="Select projects" />
      <ProgressStep label="Install software" />
    </ProgressIndicator>
  )
}

export default JoinProccessIndicatorBar
