import React, {FunctionComponent} from 'react'
import {Result} from '../../../../../services/api/types'
import {Status} from '../Status'
import {formatISODateTimeFromTime2, formatResultPoints, formatResultHours} from '../../../../../utils'
import {Link} from '../../../../../components'
import {internal} from '../../../../../constants'
import {ResultCellProps, resultsRow} from './types'

export const resultsToDataRow = (item: Result) => {
  const row: resultsRow = {
    id: `${item.resultId}`,
    os: item.os || '',
    osVersion: item.osVersion || '',
    resultName: item.resultName,
    deviceName: item.deviceName,
    status: item.statusName,
    sentTime: item.sentTime,
    dueTime: item.dueTime,
    returnedTime: item.returnedTime || '',
    cpuHours: item.cpuHours,
    claimedCredit: item.claimedCredit,
  }
  return row
}

const formatTime = (time: string | undefined) => {
  if (time) {
    const strTime = formatISODateTimeFromTime2(time)
    return <span>{strTime}</span>
  } else {
    return ''
  }
}

const formatResultsName = (item: Result | undefined, isWorkunitTable: boolean) => {
  if (item) {
    if (isWorkunitTable) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{item.resultName}</>
    } else {
      return (
        <Link className="wcg-no-underline wcg-result-table-break" href={`${internal.Workunit.url}/${item.workunitId}`}>
          {item.resultName}
        </Link>
      )
    }
  }
  return 'No results'
}

const formatRuntime = (item: Result | undefined) => {
  if (item) {
    if (item.cpuHours === 0 && item.elapsedTime === 0) {
      return ''
    }
    const cpuHours = formatResultHours(item.cpuHours)
    const elapsedTime = formatResultHours(item.elapsedTime)
    return (
      <span>
        {cpuHours} / {elapsedTime}
      </span>
    )
  }
  return ''
}

const formatCredit = (item: Result | undefined) => {
  if (item) {
    if (item.claimedCredit === 0 && item.grantedCredit === 0) {
      return ''
    }
    const claimedCredit = formatResultPoints(item.claimedCredit)
    const grantedCredit = formatResultPoints(item.grantedCredit)
    return (
      <span>
        {claimedCredit} / {grantedCredit}
      </span>
    )
  }
  return 'No results'
}

const ResultCell: FunctionComponent<ResultCellProps> = ({rowId, cell, results, isWorkunitTable}) => {
  const intId = parseInt(rowId, 10)
  const result = results.find((result) => result.resultId === intId)
  const column = cell.info.header
  if (result === undefined) {
    return null
  }

  if (column === 'resultName') {
    return formatResultsName(result, isWorkunitTable)
  } else if (column === 'sentTime') {
    return formatTime(result.sentTime)
  } else if (column === 'dueTime') {
    if (isWorkunitTable && result.returnedTime != undefined) {
      return formatTime(result.returnedTime)
    }
    return formatTime(result.dueTime)
  } else if (column === 'returnedTime') {
    return formatTime(result.returnedTime)
  } else if (column === 'cpuHours') {
    return formatRuntime(result)
  } else if (column === 'claimedCredit') {
    return formatCredit(result)
  } else if (column === 'status') {
    return <Status result={result} />
  }
  return cell.value
}

export default ResultCell
