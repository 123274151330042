import React, {FunctionComponent, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {RootState} from '../../store'
import {appStateSelector} from '../../store/selectors'
import {AppErrorState} from '../../store/appState/types'
import {clearError} from '../../store/appState/actions'
import {Error} from './Error'

// @ts-ignore
const SystemError: FunctionComponent = ({children}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const {hasError} = useSelector<RootState, AppErrorState>(appStateSelector)

  useEffect(() => {
    dispatch(clearError())
  }, [location])

  if (hasError) {
    return <Error />
  } else {
    return children
  }
}

export default SystemError
