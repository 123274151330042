import React, {useEffect} from 'react'
import _get from 'lodash.get'
import {Link} from 'carbon-components-react'
import {useFetchApi} from '../../../../../utils/hooks/useFetchApi'
import {api} from '../../../../../services'
import {convertNumberToSIFormat} from '../../../../../utils'
import content from '../../../content'
import {StatisticCard, StatisticCardGroup} from '../../index'
import {MainContentWrapper} from '../../../../../components'

const ByTheNumberSection = () => {
  const [state, fetchGlobal] = useFetchApi(api.getGlobal)

  useEffect(() => {
    fetchGlobal()
  }, [])

  const section = content.byTheNumbersSection
  const cards = section.cards
  const users = convertNumberToSIFormat(_get(state, 'data.members'))
  const devices = convertNumberToSIFormat(_get(state, 'data.devices'))
  const time = convertNumberToSIFormat(_get(state, 'data.runtimeInSeconds') / (365 * 24 * 3600))
  return (
    <MainContentWrapper
      noGutter
      expand
      noBottomPadding
      fullWidthContainerClassName="by-the-number-section-container"
      header={
        <>
          <h5 className="main-content-wrapper__header main-content-wrapper__header_margin">{section.title}</h5>
          <Link href={section.link.src}>{section.link.text}</Link>
        </>
      }
    >
      <StatisticCardGroup>
        <StatisticCard icon={cards.users.icon} title={users || cards.users.title} text={cards.users.text} />
        <StatisticCard icon={cards.devices.icon} title={devices || cards.devices.title} text={cards.devices.text} />
        <StatisticCard icon={cards.time.icon} title={time || cards.time.title} text={cards.time.text} />
      </StatisticCardGroup>
    </MainContentWrapper>
  )
}

export default ByTheNumberSection
