import React, {FunctionComponent, useEffect} from 'react'
import {LogoTwitter32, LogoFacebook32, Devices32, Group32} from '@carbon/icons-react'
import _get from 'lodash.get'
import {useParams} from 'react-router-dom'
import {Link, MainContentWrapper, ResourceCard, ResourceCardGroup} from '../../../../../components'
import {external, internal} from '../../../../../constants'
import {useFetchApi} from '../../../../../utils/hooks/useFetchApi'
import {api} from '../../../../../services'
import {ContentText} from '../typography'
import {JoinMainHeader} from '../JoinMainHeader'
import {Debian, Fedora, Windows, Macos, Android, Raspberry} from './components'

const contents = {
  fedora: Fedora,
  windows: Windows,
  macos: Macos,
  debian: Debian,
  raspberry: Raspberry,
  android: Android,
}

const Install: FunctionComponent = () => {
  const {os} = useParams()
  const [state, fetchProfile] = useFetchApi(api.getProfile)
  useEffect(() => {
    fetchProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const teamId = _get(state, 'data.teamId')
  const OsContent = contents[os]
  return (
    <>
      <JoinMainHeader title="Install software" withDivider />
      <MainContentWrapper withDivider bottomPadding="layout-05" header="Instructions" className="os-specific-content-wrapper">
        <OsContent />
      </MainContentWrapper>
      <MainContentWrapper withDivider bottomPadding="layout-05" header="Assistance">
        <ContentText>
          If you need further assistance, please consider getting help from other members in{' '}
          <Link underlined initialSize href={internal.Forums.url}>
            our forums
          </Link>
          . Or you may{' '}
          <Link underlined initialSize href={internal.Contact.url}>
            contact us.
          </Link>
        </ContentText>
      </MainContentWrapper>
      <MainContentWrapper withDivider noGutter secondary header="Extend your contribution">
        <ResourceCardGroup>
          <ResourceCard
            leftIcon={<Group32 />}
            url={teamId ? internal['Get to know your team'].url(teamId) : internal['Find a team'].url}
            title={teamId ? 'Get to know your team' : 'Join a team'}
          />
          <ResourceCard leftIcon={<Devices32 />} url={internal.Download.url} title="Install on more devices" />
        </ResourceCardGroup>
      </MainContentWrapper>
      <MainContentWrapper withDivider noGutter secondary header="Get updates">
        <ResourceCardGroup>
          <ResourceCard leftIcon={<LogoTwitter32 />} url={external.Twitter.url} external title="Follow us on Twitter" />
          <ResourceCard leftIcon={<LogoFacebook32 />} url={external.Facebook.url} external title="Follow us on Facebook" />
        </ResourceCardGroup>
      </MainContentWrapper>
    </>
  )
}

export default Install
