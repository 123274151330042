import React, {FunctionComponent, PropsWithChildren, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Breadcrumb, BreadcrumbItem, Column, Grid, Row, SkeletonText} from 'carbon-components-react'
import {BaseLayout, WithContextMenuWrapper} from '../../../layouts'
import {Link, PageMetadata} from '../../../components'
import {metadata} from '../../../constants/metadata'
import {GridElement, PageHeader} from '../MyContribution/components'
import {setNavigation} from '../../../store/navigation/actions'
import {api} from '../../../services'
import {WorkunitData} from '../../../services/api/types'
import {formatDatetimeFromString} from '../../../utils'
import {internal} from '../../../constants/urls'
import {WorkunitTable} from './WorkunitTable'

interface WorkunitProps extends PropsWithChildren<any> {
  id: number
}

const Workunit: FunctionComponent<WorkunitProps> = (props) => {
  const [workunit, setWorkunit] = useState<WorkunitData | undefined>(undefined)
  const [notFound, setNotFound] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavigation({section: 'My contribution', type: 'main', highlight: 'Results'}))
  }, [])

  useEffect(() => {
    const getData = async () => {
      const respAll = await api.getWorkunit(props.match.params.id)
      if (respAll && respAll.data && respAll.data) {
        setWorkunit(respAll.data)
      } else {
        setNotFound(true)
      }
    }
    getData()
  }, [props.match.params.id])

  return (
    <BaseLayout>
      <PageMetadata {...metadata.contribution.workunit} />
      <WithContextMenuWrapper>
        <div className="section-breadcrumb">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link href={internal.Results.url}>Results</Link>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <PageHeader>{workunit ? workunit.name : notFound ? 'Workunit' : <SkeletonText />}</PageHeader>
        {workunit && (
          <Grid className="bx--no-gutter">
            <Row>
              <Column>
                <table className="workunit-header">
                  <tbody>
                    <tr>
                      <td>Project name:&nbsp;</td>
                      <td>{workunit.appName}</td>
                    </tr>
                    <tr>
                      <td>Created:&nbsp;</td>
                      <td>{formatDatetimeFromString(workunit.createTime)}</td>
                    </tr>
                    <tr>
                      <td>Name:&nbsp;</td>
                      <td className="workunit-allow-wrap">{workunit.name}</td>
                    </tr>
                    <tr>
                      <td>Minimum Quorum:&nbsp;</td>
                      <td>{workunit.quorum}</td>
                    </tr>
                    <tr>
                      <td>Replication:&nbsp;</td>
                      <td>{workunit.replication}</td>
                    </tr>
                  </tbody>
                </table>
              </Column>
            </Row>
            <Row>
              <GridElement noMinHeight lg={3} md={2} sm={2}>
                <WorkunitTable results={workunit.results} />
              </GridElement>
            </Row>
          </Grid>
        )}
        {notFound && (
          <Grid className="bx--no-gutter">
            <Row>
              <Column>Workunit not found</Column>
            </Row>
          </Grid>
        )}
      </WithContextMenuWrapper>
    </BaseLayout>
  )
}

export default Workunit
