import React, {FunctionComponent, useState} from 'react'
import _find from 'lodash.find'
import _get from 'lodash.get'
import {CodeSnippet} from '../../../../../../components'
import content from '../../../../../Home/content'
import {internal} from '../../../../../../constants'
import {MemberStatsData} from '../../../../../../services/api/types'
import {TeamStat} from '../../../../../../services/api/types/member-api'

interface Props {
  codeUrl?: string
  imageUrl?: string
  teamId?: string
  userId?: string
  stats: any
}

function isCurrentTeam(teamStats: TeamStat) {
  return teamStats.retireDate === undefined
}

const getDisplay = (stats: undefined | MemberStatsData) => {
  const currentTeam = stats && stats.teamStats ? _find(stats.teamStats, isCurrentTeam) : undefined
  return stats && stats.member
    ? {
        currentTeamId: _get(currentTeam, 'id', undefined),
        userId: _get(stats, 'member.id', undefined),
      }
    : undefined
}

const getRandomItemFromArray = (array: any[]) => array[Math.floor(Math.random() * array.length)]

const FeatureCard: FunctionComponent<Props> = ({stats}) => {
  const [randomImageUrl] = useState(getRandomItemFromArray(content.yourSparePowerSection.images))
  const display = getDisplay(stats)
  return (
    <div className="bx--feature-card  bx--card bx--card--inverse bx--feature-card__card">
      <div className="bx--image bx--aspect-ratio bx--aspect-ratio--1x1">
        <div className="bx--aspect-ratio--object ">
          <img className="bx--image__img bx--card__img" src={randomImageUrl} alt=" alt text" />
        </div>
      </div>
      <div className="bx--card__wrapper">
        <div className="bx--card__content">
          <h3 className="bx--card__heading">Invite your friends to World Community Grid</h3>
          <p className="bx--card__paragraph">Earn badges by inviting friends with this unique referral link</p>
          <div className="bx--card__footer">
            <CodeSnippet className="code-snippet">{display && display.userId && internal['Share team'].url(display.userId, display.currentTeamId)}</CodeSnippet>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureCard
