const imageUrls = {
  boincLogo: '/images/logos/boinc_logo.png',
  krembilLogo: '/images/logos/krembil_blue.svg',
  jurisicaLogo: '/images/logos/jurisica_lab.svg',
  wcgSmallLogo: '/images/logos/IBM_World_Community_Grid_logo_small_pos_RGB.png',
  wcgIcon: '/images/logos/wcg_logo_circles.svg',
  wcgLogo: '/images/logos/wcg.svg',
  wcgLaptop: '/images/logos/laptop.svg',
  wcgDevices: '/images/logos/devices.svg',
}

export {imageUrls}
