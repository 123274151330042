import React, {FunctionComponent} from 'react'
import {useParams} from 'react-router-dom'

import {MainContentWrapper} from '../../../../components/layout/MainContentWrapper'

import {Fedora} from '../../Join/components/Install/components/Fedora'
import {Windows} from '../../Join/components/Install/components/Windows'
import {Macos} from '../../Join/components/Install/components/Macos'
import {Debian} from '../../Join/components/Install/components/Debian'
import {Raspberry} from '../../Join/components/Install/components/Raspberry'
import {Android} from '../../Join/components/Install/components/Android'
import {MainContentHeader} from '../../../../components/layout/MainContentHeader'

const contents = {
  fedora: Fedora,
  windows: Windows,
  macos: Macos,
  debian: Debian,
  raspberry: Raspberry,
  android: Android,
}

const Install: FunctionComponent = () => {
  const {os} = useParams()
  const OsContent = contents[os]
  return (
    <>
      <MainContentHeader title="Install software" withDivider />
      <MainContentWrapper header="Instructions">
        <OsContent />
      </MainContentWrapper>
    </>
  )
}

export default Install
