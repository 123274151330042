import React, {FunctionComponent} from 'react'

const ContentHeader: FunctionComponent = ({children}) => <h4 className="install__header">{children}</h4>

const ContentSubHeader: FunctionComponent = ({children}) => <h5 className="install__sub-header">{children}</h5>

const ContentText: FunctionComponent = ({children}) => <p className="install__text">{children}</p>

const Paragraph: FunctionComponent = ({children}) => <p className="install__paragraph">{children}</p>

export {ContentHeader, ContentSubHeader, ContentText, Paragraph}
