import React, {FunctionComponent} from 'react'

import {Download16} from '@carbon/icons-react'

export interface DownloadProps {
  download: () => void
  label: string
}

const Download: FunctionComponent<DownloadProps> = ({download, label}) => {
  return (
    <button className="wcg-download" onClick={download} aria-label={label}>
      <Download16 />
    </button>
  )
}

export default Download
