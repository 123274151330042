import {FunctionComponent, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setNavigation} from '../../store/navigation/actions'
import {NavigationState} from '../../store/navigation/types'

const getNavigationData = (): NavigationState | undefined => {
  const navTag = document.getElementById('header')
  const type = (navTag && navTag.getAttribute('data-context-type')) || ''
  const section = (navTag && navTag.getAttribute('data-context-section')) || ''
  const highlight = (navTag && navTag.getAttribute('data-context-highlight')) || ''
  return (navTag && {type, section, highlight}) || undefined
}

const NavigationChecker: FunctionComponent = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const navData = getNavigationData()
    navData && dispatch(setNavigation(navData))
  }, [])
  return null
}

export default NavigationChecker
