import _map from 'lodash.map'
import {CalendarItem, CalendarItems} from '../../types'

export function shiftDate(date: any, numDays: any) {
  const now = new Date()
  const shiftedDate = new Date(Date.now() + numDays * 24 * 3600 * 1000)
  const offset = shiftedDate.getTimezoneOffset() - now.getTimezoneOffset()
  return new Date(Date.now() + numDays * 24 * 3600 * 1000 + offset * 60 * 1000)
}

export function getRange(count: number): number[] {
  return Array.from({length: count}, (_, i) => i)
}

export const findItemByDate = (items: CalendarItems, day: Date): CalendarItem | undefined => {
  day.setHours(0, 0, 0, 0)
  return items.find((item: any) => {
    const itemDate = item.date
    return itemDate.getTime() === day.getTime() && item
  })
}

export const withSetTimeout = (callback: any, delay: number) => {
  let resizeTimeout: any
  return (e: any) => {
    e.stopPropagation()
    if (resizeTimeout) {
      clearTimeout(resizeTimeout)
    }
    resizeTimeout = setTimeout(() => {
      callback()
    }, delay)
  }
}

export const getDisplayedCalendarItems = (numOfDaysFromToday: number, today: Date, data: CalendarItems) => {
  return getRange(numOfDaysFromToday).map((index) => {
    const day = shiftDate(today, -index)
    const item: CalendarItem | undefined = findItemByDate(data, day)
    return item
      ? item
      : {
          date: day,
          count: undefined,
        }
  })
}

const getHitRangesArray = (low: number, hi: number) => {
  const rangeRec = (low: number, hi: number, vals: number[]): any => {
    if (low > hi) {
      return vals
    }
    vals.push(low)
    return rangeRec(low + 1, hi, vals)
  }
  return rangeRec(low, hi, [])
}

const LOW_RANGE_BORDER = 1
const HI_RANGE_BORDER = 8
export const heatRanges = getHitRangesArray(LOW_RANGE_BORDER, HI_RANGE_BORDER)

export const getRangeEquivalentForCount = (current: number, low?: number, hi?: number): number | undefined => {
  if (low && hi) {
    const amountDiff = hi - low
    const rangeAmountDiff = HI_RANGE_BORDER - LOW_RANGE_BORDER + 1
    const stepAmount = amountDiff / rangeAmountDiff
    return Math.floor((current - low) / stepAmount) + 1
  }
}
export const getMinCount = (data: CalendarItems): number | undefined => {
  const counts: number[] = _map<any>(data, 'count')
  return counts && Math.min(...counts)
}

export const getMaxCount = (data: CalendarItems): number | undefined => {
  const counts: number[] = _map<any>(data, 'count')
  return counts && Math.max(...counts)
}
