import React, {FunctionComponent} from 'react'
import {ListItem, UnorderedList} from 'carbon-components-react'
import cn from 'classnames'
import {Link} from '../../../../components'
export interface Link {
  title: string
  url: string
}

interface FooterLinksGroupProps {
  title?: string
  links?: Array<Link>
  target?: string
  withDivider?: boolean
  children?: any
}

const defaultProps = {}

const FooterLinksGroup: FunctionComponent<FooterLinksGroupProps> = ({children, title, links, target, withDivider}) => {
  return (
    <div className={cn('footer--links-group', withDivider && 'with-divider')}>
      {children ? (
        children
      ) : (
        <>
          {title && <h6 className="bx--type-productive-heading-01">{title}</h6>}
          {links && (
            <UnorderedList>
              {links.map(({url, title}: Link, index: number) => (
                <ListItem key={index} className="bx--type-body-long-01">
                  {target ? (
                    <Link href={url} target={target}>
                      {title}
                    </Link>
                  ) : (
                    <Link href={url}>{title}</Link>
                  )}
                </ListItem>
              ))}
            </UnorderedList>
          )}
        </>
      )}
    </div>
  )
}

FooterLinksGroup.defaultProps = defaultProps

export default FooterLinksGroup
