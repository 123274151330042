import React, {FunctionComponent, useEffect, useState} from 'react'
import cn from 'classnames'
import {Add20, Laptop20, Mobile20} from '@carbon/icons-react'
import {Card} from '../Card'
import {Link} from '../../../../../../components/Link'
import {internal} from '../../../../../../constants'
import {api} from '../../../../../../services'
import {DeviceObject} from '../../../../../../services/api/types'
import {InteractiveTooltip} from '../../../../../../components/Tooltip'

export interface ComponentProps {
  className?: string
}

const MAX_DEVICE_LIST_LENGTH = 3

const DevicesCard: FunctionComponent<ComponentProps> = ({className, ...rest}) => {
  const [devices, setDevices] = useState<DeviceObject[] | undefined>(undefined)

  useEffect(() => {
    const getData = async () => {
      let didCancel = false
      const response = await api.getDevices()
      const {items: devices} = response.data
      if (devices && devices.length > MAX_DEVICE_LIST_LENGTH) devices.length = MAX_DEVICE_LIST_LENGTH
      if (!didCancel) setDevices(devices)
      return () => {
        didCancel = true
      }
    }
    getData()
  }, [])

  return (
    <Card className={cn('milestones-card', className)} {...rest}>
      <Card.InnerWrapper>
        <Card.Header className="milestones-card-horizontal-wrapper">
          <Card.Title className="milestones-card-title">
            Devices{' '}
            <InteractiveTooltip>
              <p>These are all the devices you have connected to World Community Grid, both now and in the past.</p>
            </InteractiveTooltip>
          </Card.Title>
          <Link href={internal['Device statistics'].url}>
            <Card.Text>View all</Card.Text>
          </Link>
        </Card.Header>
        <Card.List>
          {devices && devices ? (
            devices.map((device: DeviceObject, index: number) => {
              return <Card.ListRow key={index} text={device.name} icon={device.os.toLowerCase().includes('device') ? Mobile20 : Laptop20} />
            })
          ) : (
            <Card.ListSkeleton />
          )}
          <Card.ListRow
            className="devices-list-row__add-project"
            text={
              <Link href={internal.Download.url} className="devices-link">
                <Add20 color="blue" className="icon" />
                Add a device
              </Link>
            }
          />
        </Card.List>
      </Card.InnerWrapper>
    </Card>
  )
}

export default DevicesCard
