import React, {FunctionComponent, useCallback, useEffect, useState} from 'react'
import cn from 'classnames'
import {LineChart} from '@carbon/charts-react'
import {Dropdown, OnChangeData} from 'carbon-components-react'
import {Card} from '../Card'
import {api} from '../../../../../../services'
import {Download} from '../../../../../../components/Download'
import {metrics, timeFrames, runtimeScales} from './content'
import {ActivityCardProps, HistoryItem, Metrics, TimeFrames, RuntimeScale, PreparedHistoryItem} from './types'
import {chooseItemsByTimeFrame, computeRuntimeScale, download, formatChartOptions, formatHistoryDataToChartData, prepareHistoryData} from './helpers'

const GRAPH_HEIGHT = 300
const CHART_HEIGHT = 264

const ActivityCard: FunctionComponent<ActivityCardProps> = ({className, ...rest}) => {
  const [historyItems, setHistoryItems] = useState<PreparedHistoryItem[] | undefined>()
  const [displayItems, setDisplayItems] = useState<PreparedHistoryItem[] | undefined>()
  const [timeFrame, setTimeFrame] = useState<TimeFrames>(timeFrames[0])
  const [metric, setMetric] = useState<Metrics>(metrics[0])
  const [runtimeScale, setRuntimeScale] = useState<RuntimeScale>(runtimeScales[0])
  const [projects, setProjects] = useState<any>([])
  const [chartContainerHeight, setChartContainerHeight] = useState<number>(GRAPH_HEIGHT)
  const [chartHeight, setChartHeight] = useState<number>(GRAPH_HEIGHT)

  const chartContainerStyle = {
    minHeight: chartContainerHeight,
  }

  useEffect(() => {
    let didCanceled = false
    const getData = async () => {
      const resp = await api.getMyProjectHistory()
      const {items}: {items: HistoryItem[]} = resp && resp.data
      if (!didCanceled) {
        setHistoryItems(prepareHistoryData(items))
      }
    }
    getData()
    return () => {
      didCanceled = true
    }
  }, [])

  useEffect(() => {
    const getData = async () => {
      const response = await api.getProjects()
      setProjects(response.data)
    }
    getData()
  }, [])

  useEffect(() => {
    if (historyItems) {
      setDisplayItems(chooseItemsByTimeFrame(historyItems, timeFrame.id))
    }
  }, [historyItems, timeFrame])

  useEffect(() => {
    if (historyItems && metric.id === 'runtime') {
      setRuntimeScale(computeRuntimeScale(chooseItemsByTimeFrame(historyItems, timeFrame.id)))
    }
  }, [metric, timeFrame, historyItems])

  //Set the height of the activity graph container because the carbon
  //graph doesn't properly handle legends that spill to a second line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (displayItems && displayItems.length > 0) {
      setTimeout(() => {
        const legend = document.querySelector('#wcg-activity-graph [data-name=legend-items]')
        const background = document.querySelector('#wcg-activity-graph svg.graph-frame')
        if (background && legend) {
          //15 is the one-line height of the legend and is handled correctly
          const extraHeightLegend = legend.getBoundingClientRect().height - 15
          const extraHeightChart = Math.max(CHART_HEIGHT - background.getBoundingClientRect().height, 0)
          setChartHeight(GRAPH_HEIGHT + extraHeightChart)
          setChartContainerHeight(GRAPH_HEIGHT + extraHeightLegend)
        }
      }, 300)
    }
  }, [displayItems])

  const handleMetricsSelect = useCallback(
    ({selectedItem}: OnChangeData<Metrics>) => {
      selectedItem && setMetric(selectedItem)
    },
    [setMetric],
  )
  const handleTimeFrameSelect = useCallback(
    ({selectedItem}: OnChangeData<TimeFrames>) => {
      selectedItem && setTimeFrame(selectedItem)
    },
    [setTimeFrame],
  )

  return (
    <Card className={cn('activity-card', className)} {...rest}>
      <Card.HorizontalWrapper>
        <div className="dropdowns">
          <Dropdown<{text: string; id: string}>
            light
            type="inline"
            ariaLabel="Metrics Dropdown"
            initialSelectedItem={metrics[0]}
            label=""
            titleText=""
            id="metrics-dropdown"
            helperText=""
            items={metrics}
            itemToString={(item) => (item ? item.text : '')}
            onChange={handleMetricsSelect}
          />
          <Dropdown<{text: string; id: string}>
            light
            type="inline"
            ariaLabel="Time Frames Dropdown"
            initialSelectedItem={timeFrames[0]}
            label=""
            titleText=""
            id="time-frames-dropdown"
            helperText=""
            items={timeFrames}
            itemToString={(item) => (item ? item.text : '')}
            onChange={handleTimeFrameSelect}
          />
        </div>
        <Download download={download} label="Download teams" />
      </Card.HorizontalWrapper>
      <Card.Divider />
      <Card.InnerWrapper>
        <div id="wcg-activity-graph" className="card-content" style={chartContainerStyle}>
          {displayItems && displayItems.length > 0 && (
            <LineChart
              data={formatHistoryDataToChartData(displayItems, projects, runtimeScale)}
              options={formatChartOptions(timeFrame, metric, runtimeScale, `${chartHeight}px`)}
            />
          )}
        </div>
      </Card.InnerWrapper>
    </Card>
  )
}

export default ActivityCard
