import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'

const prismicEndpoint = 'https://world-community-grid.prismic.io/api/v2'
const prismicAccessToken = ''

const apiv2Config: AxiosRequestConfig = {
  baseURL: '/api/v2',
}

const apiv3Config: AxiosRequestConfig = {
  baseURL: '/api/v3',
}

const apiv2Axios: AxiosInstance = axios.create(apiv2Config)
const apiv3Axios: AxiosInstance = axios.create(apiv3Config)

const requestHandler = (request: any) => {
  try {
    return request
  } catch (e) {
    return request
  }
}

const responseErrorHandling = (error: any): AxiosResponse | unknown => {
  if (typeof error && error.response && error.response.data === 'string') {
    return
  }
  if (error.response && error.response.status === 500) {
    return Promise.reject(error)
  }
  if (error.response && error.response.status === 400) {
    return Promise.reject(error.response)
  }
  if (error.response && error.response.status === 401) {
    return Promise.reject(error.response)
  }
}

apiv2Axios.interceptors.request.use((request) => requestHandler(request))
apiv2Axios.interceptors.response.use(
  (response) => response,
  (error) => responseErrorHandling(error),
)
apiv3Axios.interceptors.request.use((request) => requestHandler(request))
apiv3Axios.interceptors.response.use(
  (response) => response,
  (error) => responseErrorHandling(error),
)

export {apiv2Axios, apiv3Axios, prismicEndpoint, prismicAccessToken}
