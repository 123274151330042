import {useReducer} from 'react'

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      }
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      }
    default:
      throw new Error()
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFetchApi = (initialRequest) => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: undefined,
    error: undefined,
  })

  const didCancel = false

  const fetchData = async (requestData) => {
    dispatch({type: 'FETCH_INIT'})

    try {
      const result = await initialRequest(requestData)

      if (!didCancel) {
        dispatch({type: 'FETCH_SUCCESS', payload: result.data})
      }
    } catch (error) {
      if (!didCancel) {
        dispatch({type: 'FETCH_FAILURE', payload: error.response})
      }
    }
  }

  return [state, fetchData]
}
