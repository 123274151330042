import {useEffect, useState} from 'react'
import {Status} from './types'

export const useStatus = (loggedIn: boolean, signedUpProject?: string) => {
  const [status, setStatus] = useState<Status>(Status.notLoggedIn)
  useEffect(() => {
    setStatus(loggedIn ? (signedUpProject ? Status.loggedInAndSignedUp : Status.loggedIn) : Status.notLoggedIn)
  }, [setStatus, loggedIn, signedUpProject])
  return [status, setStatus] as const
}
