import React, {FunctionComponent} from 'react'

import {SideNavContent} from '../../components'

const WithContextMenuWrapper: FunctionComponent = ({children}) => {
  return (
    <div className="with-context-menu-wrapper">
      <div className="wcg-context-nav bx--side-nav--expanded">
        <SideNavContent />
      </div>
      <div className="with-context-menu-wrapper-main-content bx--grid">
        <div className="bx--row">
          <div className="bx--offset-xlg-4 bx--col-xlg-12 bx--offset-md-0  bx--col-md-8 bx--offset-sm-0 bx--col-sm-4">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default WithContextMenuWrapper
