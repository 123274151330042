import React, {FunctionComponent, ReactNode} from 'react'
import {ClickableTile} from 'carbon-components-react'
import {ArrowRight20, Launch20} from '@carbon/icons-react'

interface ResourceCardProps {
  leftIcon?: ReactNode | string
  light?: boolean
  url?: string
  title: string | ReactNode
  external?: boolean
}

const defaultProps = {
  light: true,
  external: false,
}

const ResourceCard: FunctionComponent<ResourceCardProps> = ({leftIcon, light, url, title, external}) => {
  return (
    <ClickableTile href={url} target={external ? '_blank' : ''} light={light}>
      <div className="bx--aspect-ratio bx--aspect-ratio--2x1">
        <div className="bx--aspect-ratio--object">
          <div className="tile-content">
            <p className="tile-content__title">{title}</p>
            <div className="tile-content__icons">
              <div className="tile-content__type-icon">{leftIcon}</div>
              <div className="tile-content__redirection-icon">{external ? <Launch20 /> : <ArrowRight20 />}</div>
            </div>
          </div>
        </div>
      </div>
    </ClickableTile>
  )
}

ResourceCard.defaultProps = defaultProps

const ResourceCardGroup: FunctionComponent = ({children}) => {
  return <div className="resource-card-group">{children}</div>
}

export {ResourceCard, ResourceCardGroup}
