import {Checkbox} from 'carbon-components-react'
import React, {SyntheticEvent} from 'react'
import {HeaderComponent} from './types'

const stopPropagationOnClick = (e: SyntheticEvent) => {
  e.stopPropagation()
}

const Header: HeaderComponent = ({title, id, checked, onChange}): JSX.Element => {
  const handleChange = (checked: boolean, id: string): void => {
    onChange(checked, id)
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div role="button" tabIndex={0} className="projects-list__header-checkbox-wrapper" onClick={stopPropagationOnClick}>
      <Checkbox checked={checked} labelText={<span>{title}</span>} id={id} onChange={handleChange} />
    </div>
  )
}

Header.defaultProps = {
  title: 'default',
  id: 'default',
  checked: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: () => {},
}

export default Header
