import React, {FunctionComponent} from 'react'
import cn from 'classnames'
import {TabBarContentItemProps} from './types'

const TabBarContentItem: FunctionComponent<TabBarContentItemProps> = ({children, label, activeTabLabel, ...rest}) => {
  const classes = cn('tab-bar-content-item', {active: activeTabLabel === label})

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  )
}

TabBarContentItem.defaultProps = {
  activeTabLabel: '',
}

export default TabBarContentItem
