import React from 'react'
import content from '../../../content'
import {LogoCard, LogoCardGroup} from '../../index'
import {MainContentWrapper} from '../../../../../components'

const LooksWhoTalkingSection = () => {
  const section = content.looksWhoTalkingSection
  const cards = section.cards
  return (
    <MainContentWrapper expand noGutter header={section.title} className="looks-who-talking-section">
      <LogoCardGroup>
        {cards.map(({src, url}, index) => {
          return (
            <a key={index} href={url} target="_blank" rel="noopener noreferrer">
              <LogoCard src={src} alt="whostalkingcard" />
            </a>
          )
        })}
      </LogoCardGroup>
    </MainContentWrapper>
  )
}

export default LooksWhoTalkingSection
