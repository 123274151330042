export const OS_NAMES = {
  WINDOWS: {text: 'Windows', name: 'Windows', download: 'Windows', short: 'windows'},
  MACOS: {text: '(Apple) Mac', name: 'Mac OS', download: 'Mac OS', short: 'macos'},
  DEBIAN: {
    text: 'Linux Ubuntu/Mint/Debian and similar distributions',
    download: '',
    name: 'Debian',
    short: 'debian',
  },
  FEDORA: {
    text: 'Linux CentOS/Red Hat/Fedora and similar distributions',
    download: '',
    name: 'Fedora',
    short: 'fedora',
  },
  ANDROID: {text: 'Android', name: 'Android', download: 'Android', short: 'android'},
  RASPBERRY: {
    text: 'Raspberry Pi',
    download: '',
    name: 'Raspberry',
    short: 'raspberry',
  },
}
