import {AxiosInstance, AxiosRequestConfig} from 'axios'

interface Options {
  withCredentials: boolean
}

interface TemplateMethodProps extends Partial<Pick<AxiosRequestConfig, 'url' | 'method' | 'data' | 'headers' | 'responseType'>> {
  config?: AxiosRequestConfig
}

class AxiosService {
  readonly withCredentials: boolean
  readonly axiosInstance: AxiosInstance

  constructor(axiosInstance: AxiosInstance, options: Options) {
    this.withCredentials = options.withCredentials
    this.axiosInstance = axiosInstance
  }
  readonly templateMethod = async ({url, method, data, config}: TemplateMethodProps) => {
    try {
      const response = await this.axiosInstance({
        ...config,
        withCredentials: this.withCredentials,
        method,
        url,
        data,
      })
      return response
    } catch (error) {
      return error
    }
  }
  readonly downloadMethod = async (filename: string, {url, method, data, config}: TemplateMethodProps) => {
    try {
      const response = await this.axiosInstance({
        ...config,
        withCredentials: this.withCredentials,
        method,
        url,
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
        data,
      }).then(({data}) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data], {type: 'text/csv'}))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      return response
    } catch (error) {
      return error
    }
  }
}

export default AxiosService
