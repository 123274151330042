import {Reducer} from 'redux'
import {NavigationState, NavigationActions, ACTION_TYPE} from './types'

const initialState = {
  type: undefined,
  section: undefined,
  highlight: undefined,
}

export const navagationReducer: Reducer<NavigationState, NavigationActions> = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SET_NAVIGATION:
      return {...action.payload}
    default:
      return state
  }
}
