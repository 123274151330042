import React, {FunctionComponent} from 'react'
import {Milestone} from '../../../../../services/api/types/milestones-api'
import {convertBadgeTimeToDaysOrYearString, convertRecruitingBadgeLevel} from '../../../../../utils'

export interface BadgeMilestoneContentProps {
  milestone: Milestone
}

const headline = (milestone: Milestone) => {
  if (milestone.badgeType === 'R') {
    return `${convertRecruitingBadgeLevel(milestone.badgeLevel || 0)} badge - Recruiting`
  } else {
    return `${convertBadgeTimeToDaysOrYearString(milestone.badgeRuntime || 0)} badge - ${milestone.projectName}`
  }
}

const message = (milestone: Milestone) => {
  if (milestone.badgeType === 'R') {
    return `You've earned a ${convertRecruitingBadgeLevel(milestone.badgeLevel || 0)} badge for Recruiting`
  } else {
    return `You've earned a ${convertBadgeTimeToDaysOrYearString(milestone.badgeRuntime || 0)} badge for ${milestone.projectName}`
  }
}

const imageSrc = (milestone: Milestone) => {
  return `/images/pb/${milestone.projectShortName}_${milestone.badgeLevel}_72.png`
}

const BadgeMilestoneContent: FunctionComponent<BadgeMilestoneContentProps> = ({milestone}) => {
  return (
    <>
      <h4>{headline(milestone)}</h4>
      <div className="wcg-milestone-badge-container">
        <div className="wcg-milestone-badge-image-container">
          <img src={imageSrc(milestone)} alt="" />
        </div>
        <div className="wcg-milestone-badge-content-container">
          <h5>Congratulations!</h5>
          <p>{message(milestone)}</p>
        </div>
      </div>
    </>
  )
}

export default BadgeMilestoneContent
