import React, {FunctionComponent, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import BaseLayout from '../../../layouts/BaseLayout/BaseLayout'
import {MainContentHeader} from '../../../components/layout/MainContentHeader'
import {MainContentWrapper} from '../../../components/layout/MainContentWrapper'
import {setNavigation} from '../../../store/navigation/actions'
import {PageMetadata} from '../../../components/PageMetadata'
import {metadata} from '../../../constants/metadata'
import {DonationActionButton} from '../../../components/DonationActionButton'
import {RootState} from '../../../store'
import {userSelector} from '../../../store/selectors'
import {UserState} from '../../../store/user/types'

const NotFound: FunctionComponent = () => {
  const dispatch = useDispatch()
  const {loggedIn} = useSelector<RootState, UserState>(userSelector)
  useEffect(() => {
    dispatch(setNavigation({section: 'Home', type: 'main', highlight: ''}))
  }, [])
  return (
    <BaseLayout>
      <PageMetadata {...metadata.donations} />
      <MainContentHeader title="Donations are vital for supporting WCG work and are greatly appreciated." />
      <MainContentWrapper>
        <p>
          In the fall of 2004 IBM launched the World Community Grid (WCG). The WCG was funded and run by IBM as a philanthropic project until February 2022.
          Over the past year, the WCG has been transferred to the Krembil Research Institute, University Health Network (UHN), located in Toronto, Canada. The
          WCG is now managed by Dr. Igor Jurisica and his team at UHN.
        </p>
        <br />
        <p>
          We are exceptionally grateful to IBM for their many years of financial and operational support. WCG continues to support open-source and open-data
          research and helps reduce computational time to allow scientists to address the world’s most pressing questions at no cost to the researchers. As an
          academic group, we face a significant financial challenge in providing the same level of support to the global research community.
        </p>
        <br />
        <p>
          Across 18 years of life, over 800,000 volunteers like you have provided their unused computing resources to support the work of the WCG researchers
          who in turn have conducted ground-breaking medical and environment-related research that is changing the world. We thank everyone who has contributed
          the spare compute cycles of their devices at home, and hope that you will continue to support WCG as we expand the number of projects and grow the
          size of the grid.
        </p>
        <br />
        <p>
          Together, there is much more we can do. We need your additional help. UHN is supported by philanthropic funding. While the WCG is exceedingly
          efficient in terms of operational cost, we still need funding for software development and maintaining the infrastructure of the grid. If you, your
          friends and family, or your organization can help with a donation, this will go a long way to enabling WCG to continue and grow as the world’s largest
          volunteer-driven supercomputer, enabling seemingly impossible scientific research to come to life.
        </p>
        <br />
        <p>Thank you for your support,</p>
        <br />
        <p>Dr. Igor Jurisica</p>
        <br />
      </MainContentWrapper>
      <div className="donation-action-button">
        <DonationActionButton loggedIn={loggedIn} />
      </div>
    </BaseLayout>
  )
}

export default NotFound
