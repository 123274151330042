import {ThunkDispatch} from 'redux-thunk'
import {api} from '../../services'
import {AppThunkAction} from '../index'
import {ACTION_TYPE, LoginRedirectActions, LoginRedirectState} from './types'

export const setLoginRedirect = (loginRedirect: LoginRedirectState): LoginRedirectActions => ({
  type: ACTION_TYPE.SET_LOGIN_REDIRECT,
  payload: loginRedirect,
})

export const delLoginRedirect = (): LoginRedirectActions => ({
  type: ACTION_TYPE.DEL_LOGIN_REDIRECT,
})
