import React, {FunctionComponent} from 'react'
import {SwitcherDivider} from 'carbon-components-react'
import {NavItem} from '../../../../constants/navigation/types'
import {Items} from '../Items'
import {SwitcherSectionTitle} from '../../../Switcher'
import {Types} from '../../types'

interface MenuProps {
  items?: NavItem[]
  type: Types
  highlightedName: string
  sectionName?: string
}

export const ContextMenu: FunctionComponent<MenuProps> = (props) => {
  return <Items {...props} isShowActiveItem />
}
export const MainMenu: FunctionComponent<MenuProps> = (props) => {
  return <Items {...props} isShowActiveItem />
}

interface AdditionalMenuProps extends MenuProps {
  title: string
}

export const AdditionalMenu: FunctionComponent<AdditionalMenuProps> = ({sectionName, items, highlightedName, title}) => {
  return (
    <div className="bx--side-nav__additional-menu">
      <SwitcherSectionTitle>{title}</SwitcherSectionTitle>
      <SwitcherDivider />
      <Items items={items} highlightedName={highlightedName} sectionName={sectionName} isShowActiveItem />
    </div>
  )
}
