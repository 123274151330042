export interface AppErrorState {
  hasError: boolean
  errorSource: string
}

export enum ACTION_TYPE {
  SET_ERROR = 'SET_ERROR',
  CLEAR_ERROR = 'CLEAR_ERROR',
}

export interface SetErrorAction {
  type: typeof ACTION_TYPE.SET_ERROR
  payload: AppErrorState
}

export interface ClearErrorAction {
  type: typeof ACTION_TYPE.CLEAR_ERROR
}

export type AppStateActions = SetErrorAction | ClearErrorAction
