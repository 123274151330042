import {useEffect, useState} from 'react'
import _find from 'lodash.find'
import {internal} from '../../constants'
import {NavItem} from '../../constants/navigation/types'
import {nav, navStandalone} from '../../constants/navigation'
import {api} from '../../services'
import {ProjectShortNames, ShortNames} from '../../constants/navigation/projects'
import {Types} from './types'

const addUrlDeep = (array: NavItem[]) =>
  array.forEach((item: NavItem) => {
    if (!item.url) item.url = internal['Help view topic'].url(item.shortName)
    item.items && addUrlDeep(item.items)
  })

export const useContextMenuItems = ({isMain, isHelp, isStandalone}: Types, section?: string) => {
  const getItemsFromFile = () => (isHelp ? undefined : _find(isStandalone ? navStandalone : nav, ['title', section])?.items)

  const [contextMenuItems, setContextMenuItems] = useState<NavItem[] | undefined>(getItemsFromFile())

  useEffect(() => {
    const getHelpMenuItems = async () => {
      const helpMenu = await api.getHelpCategories()
      const helpMenuItems = helpMenu.data
      addUrlDeep(helpMenuItems)
      setContextMenuItems(helpMenuItems)
    }

    isHelp && getHelpMenuItems()
  }, [isHelp])

  useEffect(() => {
    setContextMenuItems(() => getItemsFromFile())
  }, [isStandalone, isMain, section])

  return contextMenuItems
}

const flattenArrayWithInnerArrayFieldDeep = (array: Record<string, any>[], fieldName: string): Record<string, any>[] => {
  const arrayOfInnerItems = array.reduce((accArray: Record<string, any>[], arrayItem: Record<string, any>) => {
    const itemInnerArray: Record<string, any>[] = Array.isArray(arrayItem[fieldName]) && arrayItem[fieldName]
    return itemInnerArray ? [...accArray, ...flattenArrayWithInnerArrayFieldDeep(itemInnerArray, fieldName)] : accArray
  }, [])
  return array.concat(arrayOfInnerItems)
}

export const findTitleByShortName = (array?: NavItem[], value?: ShortNames): string => {
  const flattenArray = array && flattenArrayWithInnerArrayFieldDeep(array, 'items')
  const currentItem = _find(flattenArray, ['shortName', value])
  return (currentItem && currentItem.title) || ''
}

const getMaxNumberOfArray = (array: number[]) => Math.max.apply(null, array)

export const getDepthLevelNumber = (array: Record<string, any>[], fieldName: string, currentDepth = 0): number => {
  const arrayOfDepthNumbers: any = array.reduce((accArray: number[], arrayItem: Record<string, any>, index) => {
    const itemInnerArray: Record<string, any>[] = Array.isArray(arrayItem[fieldName]) && arrayItem[fieldName].length !== 0 && arrayItem[fieldName]
    return itemInnerArray ? [...accArray, getDepthLevelNumber(itemInnerArray, fieldName, currentDepth + 1)] : [...accArray, currentDepth]
  }, [])
  return getMaxNumberOfArray(arrayOfDepthNumbers)
}

export const findProjectNameByShortNameIfExists = (projects?: any[], value?: ShortNames): string => {
  const currentItem = _find(projects, ['shortName', value])
  return (currentItem && currentItem.name) || ''
}

export const isProjectShortName = (checkingString: string): boolean => {
  return Object.keys(ProjectShortNames).includes(checkingString)
}
