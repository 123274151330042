import React, {FunctionComponent} from 'react'

export interface HeatmapTooltipSquareProps {
  color: string
}

const HeatmapTooltipSquare: FunctionComponent<HeatmapTooltipSquareProps> = ({color}) => {
  return <div className="heatmap-tooltip-square" style={{backgroundColor: `#${color}`}} />
}

const HeatmapTooltip: FunctionComponent = () => {
  return (
    <div className="heatmap-tooltip">
      <span className="heatmap-tooltip-text">Less</span>
      {['d0e2ff', 'd0e2ff', 'a6c8ff', '78a9ff', '4589ff', '0f62fe', '0043ce', '002d9c'].map((color, index) => {
        return <HeatmapTooltipSquare key={index} color={color} />
      })}
      <span className="heatmap-tooltip-text">More</span>
    </div>
  )
}

export default HeatmapTooltip
