import React from 'react'
import {Link as ReactRouterLink, useLocation} from 'react-router-dom'
import {Link as CarbonLink} from 'carbon-components-react'
import cn from 'classnames'
import {checkIfUsualLink} from '../../utils'
import {CustomLink} from './types'

const Link: CustomLink = React.forwardRef(({children, href, className, underlined, initialSize, inheritFontSize, ...rest}, ref) => {
  const location = useLocation()
  const _to = href || ''

  const isUsualLink = checkIfUsualLink(_to, location)

  const classnames = [
    'bx--link wcg-link',
    underlined && 'bx--link_default-underlined',
    initialSize && 'bx--link_initial-font-size',
    inheritFontSize && 'bx--link_inherit-font-size',
    className,
  ]

  return isUsualLink ? (
    <CarbonLink className={cn(classnames)} href={_to} {...rest}>
      {children}
    </CarbonLink>
  ) : (
    <ReactRouterLink className={cn(classnames)} to={_to} {...rest}>
      {children}
    </ReactRouterLink>
  )
})

export default Link
