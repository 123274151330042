import React, {FunctionComponent, ReactElement} from 'react'
import {ClickableTile} from 'carbon-components-react'
import cn from 'classnames'

interface NewsCardProps {
  light?: boolean
  src: string
  url: string
  title?: string
  date?: string
  text?: string
  externalLinking?: boolean
}

const NewsCardDefaultProps = {
  light: true,
  url: '#',
  title: 'Title',
  date: 'Date',
  text: 'Lorem ipsum ',
  externalLinking: false,
}

const NewsCard: FunctionComponent<NewsCardProps> = ({light, url, title, date, text, externalLinking, src}) => {
  return (
    <ClickableTile className="card_news wcg-outline" href={url} target={externalLinking ? '_blank' : undefined} light={light}>
      <div className="bx--aspect-ratio bx--aspect-ratio--16x9">
        <div className="bx--aspect-ratio--object">
          <img src={src} className="card-image" alt="" />
        </div>
      </div>
      <div className="card-description">
        <p className="card-description__date">{date}</p>
        <p className="card-description__title">{title}</p>
        <p className="card-description__text">{text}</p>
      </div>
    </ClickableTile>
  )
}

NewsCard.defaultProps = NewsCardDefaultProps

interface NewsCardGroup {
  className?: string
  children?: ReactElement[] | null
}

const NewsCardGroup: FunctionComponent<NewsCardGroup> = ({children, className}) => {
  return (
    <div className={cn('card-group_news', className)}>
      {React.Children.map(children, (child) => (
        //@ts-ignore
        <div className="card-group__card-wrapper">{child && React.cloneElement(child)}</div>
      ))}
    </div>
  )
}

export {NewsCard, NewsCardGroup}
