import React, {FunctionComponent, useState} from 'react'
import {useHistory} from 'react-router'
import {Button, Checkbox, Form, Loading, TextInput} from 'carbon-components-react'
import {useFormik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import _get from 'lodash.get'
import {v4 as uuid} from 'uuid'
import {AxiosResponse} from 'axios'
import {checkIfUsualLink, validator} from '../../../../../utils'
import {
  NotificationsList,
  Divider,
  Link,
  FormHeaderText,
  FormButtonContentWrapper,
  FormMainContentWrapper,
  FormGroup,
  FormHeaderShortText,
} from '../../../../../components'
import {internal} from '../../../../../constants'
import {LoginError} from '../../../../../services/api/types'
import {RootState} from '../../../../../store'
import {locationSelector} from '../../../../../store/selectors'
import {api} from '../../../../../services'
import {fetchLoginStatus} from '../../../../../store/user/actions'

// eslint-disable-next-line max-lines-per-function
const LoginForm: FunctionComponent = () => {
  const [isFetching, setIsFetching] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const loginRedirectLocation = useSelector<RootState, string>(locationSelector)

  const formik = useFormik({
    initialValues: {
      // eslint-disable-next-line babel/camelcase
      j_username: '',
      // eslint-disable-next-line babel/camelcase
      j_password: '',
      remember: false,
    },
    // eslint-disable-next-line complexity
    onSubmit: async (values, form) => {
      try {
        setIsFetching(true)
        // eslint-disable-next-line babel/camelcase
        const {j_password, j_username, remember} = values

        // eslint-disable-next-line babel/camelcase
        const resp = await api.postLogin({j_password, j_username, settoken: remember ? 'on' : undefined})

        if (resp && resp.data && resp.data.errors && resp.status === 401) {
          const responseErrors: LoginError[] = resp.data.errors
          responseErrors &&
            form.setStatus({
              notifications: responseErrors.map((error: LoginError) => ({
                text: <div dangerouslySetInnerHTML={{__html: error.error}} />,
                id: uuid(),
              })),
            })
        } else if (resp && resp.status === 200) {
          await dispatch(fetchLoginStatus())

          const loginRedirect = resp.data && resp.data.path ? resp.data.path : loginRedirectLocation
          if (checkIfUsualLink(loginRedirect)) {
            window.location.assign(loginRedirect)
          } else {
            history.push(loginRedirect)
          }
        } else {
          form.setStatus({
            notifications: [
              {
                text: 'An error occurred while attempting to login.  Please try again later',
                id: uuid(),
              },
            ],
          })
        }
      } catch (e) {
        const responseErrors: LoginError[] = e && e.errors
        responseErrors &&
          form.setStatus({
            notifications: responseErrors.map((error: LoginError) => ({
              text: <div dangerouslySetInnerHTML={{__html: error.error}} />,
              id: uuid(),
            })),
          })
      } finally {
        setIsFetching(false)
      }
    },
    validate: (values) => {
      return validator.rules({
        emailAddress: validator.validate(values.j_username).required('Email address is required').email(),
        password: validator
          .validate(values.j_password)
          .required('Password is required')
          .minLength(6, 'Password must be between 6 and 100 characters')
          .maxLength(100, 'Password must be between 6 and 100 characters'),
      })
    },
    validateOnMount: true,
  })

  return (
    <Form className="login-form" onSubmit={formik.handleSubmit}>
      <Loading active={isFetching} withOverlay={true} />
      <FormMainContentWrapper>
        <FormGroup>
          <FormHeaderText>Log in</FormHeaderText>
          <FormHeaderShortText>
            Don&apos;t have an account?{' '}
            <Link underlined href={internal.Join.url}>
              Join
            </Link>
          </FormHeaderShortText>
        </FormGroup>
        <div className="form__form-group">
          {_get(formik, 'status.notifications') ? <NotificationsList data={_get(formik, 'status.notifications')} /> : <Divider />}
        </div>
        <FormGroup>
          <TextInput
            id="j_username"
            name="j_username"
            labelText="Email address"
            placeholder="example@email.com"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.j_username}
            invalid={formik.touched.j_username && !!formik.errors.j_username}
            invalidText={formik.errors.j_username}
          />
        </FormGroup>
        <FormGroup>
          <TextInput.PasswordInput
            id="j_password"
            name="j_password"
            tooltipPosition="bottom"
            tooltipAlignment="center"
            hidePasswordLabel="Hide password"
            showPasswordLabel="Show password"
            labelText={
              <div className="input-label">
                Password
                <Link className="input-label__link" href={internal['Forgot password'].url}>
                  Forgot password?
                </Link>
              </div>
            }
            placeholder="enter password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.j_password}
            invalid={formik.touched.j_password && !!formik.errors.j_password}
            invalidText={formik.errors.j_password}
          />
        </FormGroup>
        <FormGroup>
          <Checkbox
            labelText={<>Remember me</>}
            id="remember"
            checked={formik.values.remember}
            onBlur={formik.handleBlur}
            onChange={(_, __, event) => {
              formik.handleChange(event)
            }}
            name="remember"
          />
        </FormGroup>
      </FormMainContentWrapper>
      <FormButtonContentWrapper>
        <Button kind="primary" type="submit" disabled={!formik.isValid || formik.isValidating || isFetching}>
          {isFetching ? <>Logging</> : <>Login</>}
        </Button>
      </FormButtonContentWrapper>
    </Form>
  )
}

export default LoginForm
