export enum PlatformsCompliance {
  supportsAndroid = 'Android',
  supportsLinux = 'Linux',
  supportsMac = 'Mac',
  supportsRaspberryPi = 'RaspberryPi',
  supportsWindows = 'Windows',
}

export const getPlatformName = (type: keyof typeof PlatformsCompliance): PlatformsCompliance | '' => {
  return PlatformsCompliance[type] || ''
}
