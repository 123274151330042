import React, {FunctionComponent, useState, useEffect} from 'react'
import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Pagination,
} from 'carbon-components-react'

import {useLocation, useHistory} from 'react-router-dom'
// @ts-ignore
import _isEqual from 'lodash.isequal'
import queryString from 'query-string'
import {api} from '../../../../../services'
import {Download} from '../../../../../components/Download'
import {Result as Item} from '../../../../../services/api/types'
import {FilterPanel} from '../FilterPanel'
import {Filters} from '../Filters'
import {FilterProps} from '../Filters/types'
import ResultCell, {resultsToDataRow} from '../ResultCell/ResultCell'
import {DEFAULT_DEVICE_ID, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, DEFAULT_PROJECT_ID} from '../Filters/content'
import {headerType, TableProps} from './types'

const PAGE_SIZES: [10, 25, 50, 100, 250, 500, 1000] = [10, 25, 50, 100, 250, 500, 1000]

const dataHeaders: headerType[] = [
  {
    key: 'resultName',
    header: 'Result name',
  },
  {
    key: 'deviceName',
    header: 'Device name',
  },
  {
    key: 'status',
    header: 'Status',
  },
  {
    key: 'sentTime',
    header: 'Sent time',
  },
  {
    key: 'dueTime',
    header: 'Time due',
  },
  {
    key: 'returnedTime',
    header: 'Return time',
  },
  {
    key: 'cpuHours',
    header: 'Cpu time/ Elapsed time',
  },
  {
    key: 'claimedCredit',
    header: 'Claimed credit/ Granted credit',
  },
]

const toArray = (param: any): string[] => {
  if (param === undefined) {
    return []
  }
  if (Array.isArray(param)) {
    return param
  }
  if (typeof param === 'string') {
    return [param]
  }
  return []
}

const toString = (param: any, defaultValue: string): string => {
  if (typeof param === 'string') {
    return param
  }
  return defaultValue
}

const toNumber = (param: any, defaultValue: number): number => {
  if (!isNaN(param)) {
    return parseInt(param, 10)
  }
  return defaultValue
}

// eslint-disable-next-line max-lines-per-function
const ResultsTable: FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const queryParameters = queryString.parse(location.search)
  const [serverStatusIds, setServerStatusIds] = useState<string[]>(toArray(queryParameters.serverStatusIds))
  const [outcomeIds, setOutcomeIds] = useState<string[]>(toArray(queryParameters.outcomeIds))
  const [validationStatusIds, setValidationStatusIds] = useState<string[]>(toArray(queryParameters.validationStatusIds))
  const [deviceId, setDeviceId] = useState<string>(toString(queryParameters.deviceId, DEFAULT_DEVICE_ID))
  const [projectId, setProjectId] = useState<string>(toString(queryParameters.projectId, DEFAULT_PROJECT_ID))
  const [sortField, setSortField] = useState<string>(toString(queryParameters.sortBy, ''))
  const [sortDirection, setSortDirection] = useState<string>(toString(queryParameters.sortDirection, ''))
  const [searchValue, setSearchValue] = useState<string>()

  const [allResults, setAllResults] = useState<Item[] | undefined>(undefined)
  const [displayResults, setDisplayResults] = useState<Item[]>([])
  const [currentPageResults, setCurrentPageResults] = useState<Item[]>([])

  const [maxRecordsAvailable, setMaxRecordsAvailable] = useState<number>(0)
  const [currentPageSize, setCurrentPageSize] = useState<number>(toNumber(queryParameters.limit, DEFAULT_PAGE_SIZE))
  const [currentPage, setCurrentPage] = useState<number>(toNumber(queryParameters.pageNumber, DEFAULT_PAGE_NUMBER))

  const [dataRows, setDataRows] = useState<any>([])

  const updateTable = (item: FilterProps) => {
    setDeviceId(item.deviceId)
    setProjectId(item.projectId)
    setServerStatusIds(item.serverStatusIds)
    setValidationStatusIds(item.validationStatusIds)
    setOutcomeIds(item.outcomeIds)
    setCurrentPage(DEFAULT_PAGE_NUMBER)
    setCurrentPageSize(DEFAULT_PAGE_SIZE)
  }

  const download = () => {
    api.getResultsForDownload(deviceId, projectId, serverStatusIds, outcomeIds, validationStatusIds)
  }

  const handlePageChange = (data: {page: number; pageSize: number}) => {
    setCurrentPage(data.page)
    setCurrentPageSize(data.pageSize)
  }

  const customSearch = (inputValue: any) => {
    if (inputValue && inputValue.currentTarget && inputValue.currentTarget.value) {
      setSearchValue(inputValue.currentTarget.value)
    } else {
      setSearchValue('')
    }
  }

  const handleHeaderClick = (e: any) => {
    const headerText = e.target.innerText
    const header = dataHeaders.find((header) => header.header === headerText)
    if (header === undefined) {
      return
    }

    setCurrentPage(DEFAULT_PAGE_NUMBER)
    setCurrentPageSize(DEFAULT_PAGE_SIZE)
    if (sortField === header.key) {
      if (sortDirection === '') {
        setSortDirection('ASC')
      } else if (sortDirection === 'ASC') {
        setSortDirection('DESC')
      } else {
        setSortField('')
        setSortDirection('')
      }
    } else {
      setSortField(header.key)
      setSortDirection('ASC')
    }
  }

  useEffect(() => {
    const queryParameters = queryString.parse(location.search)

    const tmpDeviceId = toString(queryParameters.deviceId, DEFAULT_DEVICE_ID)
    const tmpProjectId = toString(queryParameters.projectId, DEFAULT_PROJECT_ID)
    const tmpServerStatusIds = toArray(queryParameters.serverStatusIds)
    const tmpValidationStatusIds = toArray(queryParameters.validationStatusIds)
    const tmpOutcomeIds = toArray(queryParameters.outcomeIds)
    const tmpCurrentPage = toNumber(queryParameters.pageNumber, DEFAULT_PAGE_NUMBER)
    const tmpCurrentPageSize = toNumber(queryParameters.limit, DEFAULT_PAGE_SIZE)

    if (tmpDeviceId !== deviceId) {
      setDeviceId(tmpDeviceId)
    }
    if (tmpProjectId !== projectId) {
      setProjectId(tmpProjectId)
    }
    if (!_isEqual(tmpServerStatusIds, serverStatusIds)) {
      setServerStatusIds(tmpServerStatusIds)
    }
    if (!_isEqual(tmpValidationStatusIds, validationStatusIds)) {
      setValidationStatusIds(tmpValidationStatusIds)
    }
    if (!_isEqual(tmpOutcomeIds, outcomeIds)) {
      setOutcomeIds(tmpOutcomeIds)
    }
    if (tmpCurrentPage !== currentPage) {
      setCurrentPage(tmpCurrentPage)
    }
    if (tmpCurrentPageSize !== currentPageSize) {
      setCurrentPageSize(tmpCurrentPageSize)
    }
  }, [location])

  useEffect(() => {
    const getData = async () => {
      setAllResults(undefined)
      const respAll = await api.getResults(deviceId, projectId, serverStatusIds, outcomeIds, validationStatusIds)
      if (respAll && respAll.data && respAll.data.items) {
        setAllResults(respAll.data.items)
      }
    }
    getData()
  }, [deviceId, projectId, serverStatusIds, outcomeIds, validationStatusIds])

  useEffect(() => {
    const queryString = api.getParamsForResults(
      deviceId,
      projectId,
      serverStatusIds,
      outcomeIds,
      validationStatusIds,
      sortField,
      currentPage,
      currentPageSize,
      sortDirection,
    )
    let tmpSearch = location.search
    if (tmpSearch.length > 0 && tmpSearch[0] === '?') {
      tmpSearch = tmpSearch.substr(1)
    }
    if (queryString !== tmpSearch) {
      history.push({pathname: location.pathname, search: queryString})
    }
  }, [deviceId, projectId, serverStatusIds, outcomeIds, validationStatusIds, sortField, currentPage, currentPageSize, sortDirection])

  useEffect(() => {
    if (allResults) {
      let tmpResults
      if (searchValue && searchValue !== '') {
        tmpResults = allResults.filter((item: Item) => {
          return item.resultName.toLowerCase().includes(searchValue.toLowerCase())
        })
      } else {
        tmpResults = [...allResults]
      }
      tmpResults.sort((a, b) => {
        const direction = sortDirection === 'DESC' ? -1 : 1
        if (a[sortField] === b[sortField]) {
          return 0
        }
        if (a[sortField] === undefined) {
          return -1 * direction
        } else if (b[sortField] === undefined) {
          return 1 * direction
        } else if (a[sortField] > b[sortField]) {
          return 1 * direction
        }
        return -1 * direction
      })
      setDisplayResults(tmpResults)
      setMaxRecordsAvailable(tmpResults.length)
    }
  }, [allResults, searchValue, sortField, sortDirection])

  useEffect(() => {
    const tmpResults = displayResults.slice((currentPage - 1) * currentPageSize, currentPageSize * currentPage)
    setCurrentPageResults(tmpResults)
    setDataRows(tmpResults.map(resultsToDataRow))
  }, [displayResults, currentPage, currentPageSize])

  if (allResults === undefined) {
    return <DataTableSkeleton />
  }

  return (
    <>
      <DataTable rows={dataRows} headers={dataHeaders} isSortable>
        {/* @ts-ignore */}
        {({rows, headers, getHeaderProps, getRowProps, getTableProps, getToolbarProps, getTableContainerProps}: TableProps) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
              <TableToolbarContent>
                <TableToolbarSearch onChange={customSearch} value={searchValue} placeholder="Search result name" />
                <FilterPanel>
                  <Filters
                    updateTable={updateTable}
                    deviceId={deviceId}
                    projectId={projectId}
                    serverStatusIds={serverStatusIds}
                    outcomeIds={outcomeIds}
                    validationStatusIds={validationStatusIds}
                  />
                </FilterPanel>
                <Download download={download} label="Download results" />
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow onClick={(e) => handleHeaderClick(e)}>
                  {headers.map((header: headerType) => (
                    <TableHeader key={header.key} {...getHeaderProps({header})}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: {id: string; cells: any[]}) => (
                  <TableRow key={row.id} {...getRowProps({row})}>
                    {row.cells.map((cell) => (
                      <TableCell className="workunit-allow-wrap" key={cell.id}>
                        <ResultCell rowId={row.id} cell={cell} results={currentPageResults} isWorkunitTable={false} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <div className="pagination-table">
        <Pagination pageSizes={PAGE_SIZES} pageSize={currentPageSize} page={currentPage} totalItems={maxRecordsAvailable || 0} onChange={handlePageChange} />
      </div>
    </>
  )
}

export default ResultsTable
