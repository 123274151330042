import React, {FunctionComponent, ReactNode} from 'react'
import {SideNavMenu, SideNavMenuItem} from 'carbon-components-react'

import {NavItem} from '../../../../constants/navigation/types'
import {Link} from '../../../Link'
interface ItemsProps {
  items?: NavItem[]
  highlightedName: string
  sectionName?: string
  isShowActiveItem?: boolean
}

const Items: FunctionComponent<ItemsProps> = ({items, highlightedName, sectionName, isShowActiveItem}) => {
  return (
    <div>
      {items &&
        items.map<ReactNode>((item: NavItem, index: number) => {
          return item.items ? (
            <SideNavMenu
              key={index}
              title={item.title}
              defaultExpanded={isShowActiveItem && item.title === sectionName && item.items.some((item) => item.title === highlightedName)}
            >
              {item.items.map<ReactNode>((childItem: NavItem, index: number) => {
                return (
                  <SideNavMenuItem<any>
                    element={Link}
                    className="wcg-side-nav-item"
                    key={index}
                    href={childItem.url}
                    isActive={isShowActiveItem && item.title === sectionName && childItem.title === highlightedName}
                  >
                    {childItem.title}
                  </SideNavMenuItem>
                )
              })}
            </SideNavMenu>
          ) : (
            <SideNavMenuItem
              key={index}
              href={item.url}
              element={Link}
              className="wcg-side-nav-item"
              isActive={isShowActiveItem && sectionName === '' && item.title === highlightedName}
            >
              {item.title}
            </SideNavMenuItem>
          )
        })}
    </div>
  )
}

export default Items
