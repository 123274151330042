import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'

import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {store, persistor} from './store'
import {Header, Footer, SideNavContent, NavigationChecker} from './components'
import App from './App'

/*
    Note:  I do not like the approach of wrapping the renders in try/catch blocks
    and adding one for each potential entry point in the legacy application as this will grow
    somewhat extensively.  Some work is needed to be able to move these onto the specific page
    where they are rendered and still use the same redux store.
*/
try {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    document.getElementById('root'),
  )
} catch (err) {}

try {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Route
            render={() => {
              return (
                <>
                  <NavigationChecker />
                  <Header />
                </>
              )
            }}
          />
        </Router>
      </PersistGate>
    </Provider>,
    document.getElementById('header'),
  )
} catch (err) {}

try {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Route component={Footer} />
        </Router>
      </PersistGate>
    </Provider>,
    document.getElementById('footer'),
  )
} catch (err) {}

try {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Route
            render={() => {
              return (
                <div className="bx--side-nav--expanded">
                  <SideNavContent />
                </div>
              )
            }}
          />
        </Router>
      </PersistGate>
    </Provider>,
    document.getElementById('wcg--left-nav'),
  )
} catch (err) {}
