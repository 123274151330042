import React, {FunctionComponent, useEffect} from 'react'
import {SideNav as CarbonSideNav, SideNavItems, AccordionSkeleton} from 'carbon-components-react'
import {useSelector} from 'react-redux'
import {nav as mainMenu} from '../../constants/navigation'
import {RootState} from '../../store'
import {NavigationState} from '../../store/navigation/types'
import {ShortNames} from '../../constants/navigation/projects'
import {ProjectsState} from '../../store/projects/types'
import {NavItem} from '../../constants/navigation/types'
import {navigationSelector, projectsSelector} from '../../store/selectors'
import {useContextMenuItems, findTitleByShortName, getDepthLevelNumber, findProjectNameByShortNameIfExists, isProjectShortName} from './helpers'
import {MainMenu, AdditionalMenu, ContextMenu} from './components'
import {SideNavProps, Types} from './types'

enum depth {
  firstLevel = 0,
  secondLevel = 1,
}

const getType = (navigation: NavigationState): Types => {
  return {
    isStandalone: typeof navigation.type === 'string' && navigation.type === 'standalone',
    isMain: typeof navigation.type === 'string' && navigation.type === 'main',
    isHelp: typeof navigation.type === 'string' && navigation.type === 'help',
  }
}

const getSectionName = (type: Types, contextMenuItems: NavItem[] | undefined, navigation: NavigationState): string => {
  return type.isHelp ? findTitleByShortName(contextMenuItems, navigation.section as ShortNames) : navigation.section || ''
}

const getHighlightedName = (type: Types, contextMenuItems: NavItem[] | undefined, navigation: NavigationState): string => {
  return type.isHelp ? findTitleByShortName(contextMenuItems, navigation.highlight as ShortNames) : navigation.highlight || ''
}

const getAdditionalMenuTitle = (type: Types, navigation: NavigationState, projects: any[]): string => {
  if (type.isHelp) {
    return 'Help'
  } else if (navigation.section && isProjectShortName(navigation.section)) {
    return findProjectNameByShortNameIfExists(projects, navigation.section as ShortNames)
  } else {
    return navigation.section || ''
  }
}

const getDepth = (type: Types, contextMenuItems: NavItem[] | undefined): boolean => {
  return (!type.isStandalone && contextMenuItems && getDepthLevelNumber(contextMenuItems, 'items', depth.firstLevel) > 0) || false
}

const SideNavContent: FunctionComponent = () => {
  const navigation = useSelector<RootState, NavigationState>(navigationSelector)
  const {projects} = useSelector<RootState, ProjectsState>(projectsSelector)
  const type = getType(navigation)
  const contextMenuItems = useContextMenuItems(type, navigation.section)
  const sectionName = getSectionName(type, contextMenuItems, navigation)
  const highlightedName = getHighlightedName(type, contextMenuItems, navigation)
  const additionalMenuTitle = getAdditionalMenuTitle(type, navigation, projects)
  const isDepthInContextItems = getDepth(type, contextMenuItems)

  return (
    <SideNavItems>
      <div className="bx--side-nav__display-on-small">
        {mainMenu ? <MainMenu items={mainMenu} type={type} sectionName={sectionName} highlightedName={highlightedName} /> : <AccordionSkeleton />}
        {(type.isStandalone || type.isHelp) &&
          (contextMenuItems ? (
            <AdditionalMenu
              items={contextMenuItems}
              type={type}
              sectionName={isDepthInContextItems ? sectionName : ''}
              highlightedName={highlightedName}
              title={additionalMenuTitle}
            />
          ) : (
            <AccordionSkeleton />
          ))}
      </div>
      <div className="bx--side-nav__display-on-wide">
        {contextMenuItems ? (
          <ContextMenu items={contextMenuItems} type={type} highlightedName={highlightedName} sectionName={isDepthInContextItems ? sectionName : ''} />
        ) : (
          <AccordionSkeleton />
        )}
      </div>
    </SideNavItems>
  )
}

const SideNav: FunctionComponent<SideNavProps> = ({isSideNavExpanded, isChildOfHeader, onClickSideNavExpand}) => {
  useEffect(() => {
    const listener = () => {
      isSideNavExpanded && onClickSideNavExpand()
    }
    const sideNavOverlay = document.querySelectorAll('.bx--side-nav__overlay, .bx--header__global')

    sideNavOverlay &&
      sideNavOverlay.forEach((item) => {
        item.addEventListener('click', listener)
      })
    return () => {
      sideNavOverlay &&
        sideNavOverlay.forEach((item) => {
          item.removeEventListener('click', listener)
        })
    }
  })

  return (
    <CarbonSideNav expanded={isSideNavExpanded} isChildOfHeader={isChildOfHeader} aria-label="Side navigation" addFocusListeners addMouseListeners isRail>
      <SideNavContent />
    </CarbonSideNav>
  )
}

SideNav.defaultProps = {
  isSideNavExpanded: false,
  isChildOfHeader: true,
}

export {SideNav, SideNavContent}
