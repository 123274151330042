import React, {ReactNode, FunctionComponent, ReactElement} from 'react'
import {ClickableTile} from 'carbon-components-react'
import cn from 'classnames'
import {ArrowRight20, Launch20} from '@carbon/icons-react'

interface DiscoverCardProps {
  url: string
  src: string
  text: string
  externalLinking?: boolean
  light?: boolean
  title?: string
  date?: string
  footerText?: string
  footerIcon?: ReactNode
  className?: string
}

const defaultProps = {
  light: false,
  url: '#',
  text: '',
  externalLinking: false,
}

const DiscoverCard: FunctionComponent<DiscoverCardProps> = ({light, url, title, date, text, externalLinking, src, footerText, footerIcon, className}) => {
  return (
    <ClickableTile href={url} target={externalLinking ? '_blank' : undefined} light={light} className={cn('card_discover', className)}>
      <div className="card-image-container bx--aspect-ratio bx--aspect-ratio--1x1">
        <div className="bx--aspect-ratio--object">
          <img src={src} className="card-image" alt="" />
        </div>
      </div>
      <div className="card-description">
        {date && <p className="card-description__date">{date}</p>}
        {title && <p className="card-description__title">{title}</p>}
        <p className="card-description__text">{text}</p>
      </div>
      <div className="card-footer">
        <p className="card-footer__text">{footerText}</p>
        <div className="card-footer__icon">{footerIcon ? footerIcon : externalLinking ? <Launch20 /> : <ArrowRight20 />}</div>
      </div>
    </ClickableTile>
  )
}

DiscoverCard.defaultProps = defaultProps

interface DiscoverCardGroupProps {
  className?: string
  children: ReactElement[]
}

const DiscoverCardGroup: FunctionComponent<DiscoverCardGroupProps> = ({children, className}) => {
  return (
    <div className={cn('card-group_discover', className)}>
      {React.Children.map(children, (child) => {
        //@ts-ignore
        return <div className="card-group__card-wrapper">{React.cloneElement(child)}</div>
      })}
    </div>
  )
}

export {DiscoverCard, DiscoverCardGroup}
