import React, {FunctionComponent, useEffect} from 'react'
import {MainContentHeader} from '../../../components/layout/MainContentHeader'
import {MainContentWrapper} from '../../../components/layout/MainContentWrapper'

const Error: FunctionComponent = () => {
  return (
    <>
      <MainContentHeader title="System error" />
      <MainContentWrapper>
        World Community Grid is currently experiencing an unexpected error. Please check{' '}
        <a href="https://www.facebook.com/worldcommunitygrid.org/" target="_blank" rel="noreferrer">
          Facebook
        </a>{' '}
        or{' '}
        <a href="https://twitter.com/WCGrid" target="_blank" rel="noreferrer ">
          Twitter
        </a>{' '}
        for more information.
      </MainContentWrapper>
    </>
  )
}

export default Error
