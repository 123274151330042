import React, {FunctionComponent} from 'react'
import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from 'carbon-components-react'
import {api} from '../../../../../../services'
import {TeamStat} from '../../../../../../services/api/types/member-api'
import {formatISODateFromTime, formatPoints, formatResults, formatRuntime} from '../../../../../../utils'
import {Download} from '../../../../../../components/Download'
import {LastUpdated} from '../../../../../../components/LastUpdated'

interface headerType {
  header: string
  key: string
}
const dataHeaders: headerType[] = [
  {
    header: 'Team name',
    key: 'name',
  },
  {
    header: 'Dates',
    key: 'joinDate',
  },
  {
    header: 'Runtime',
    key: 'runtime',
  },
  {
    header: 'Results returned',
    key: 'results',
  },
  {
    header: 'Points earned',
    key: 'points',
  },
]

interface teamRow {
  id: string
  name: string
  joinDate: number
  runtime: number
  results: number
  points: number
}

const teamToDataRow = (team: TeamStat) => {
  const row: teamRow = {
    id: `${team.joinDate}`,
    name: team.name,
    joinDate: team.joinDate,
    runtime: team.runtimeInSeconds,
    results: team.results,
    points: team.points,
  }
  return row
}

const formatDates = (team: TeamStat | undefined) => {
  if (team) {
    const start = formatISODateFromTime(team.joinDate)
    const end = team.retireDate ? formatISODateFromTime(team.retireDate) : 'Present'
    return (
      <span>
        <span className="wcg-team-table-nobr">{start}</span> - <span className="wcg-team-table-nobr">{end}</span>
      </span>
    )
  }
  return 'Error'
}

const formatTeamName = (team: TeamStat | undefined) => {
  if (team) {
    return (
      <a className="wcg-no-underline" href={`/team/viewTeamInfo.do?teamId=${team.id}`}>
        {team.name}
      </a>
    )
  }
  return 'Error'
}

const renderCell = (id: string | number | null | undefined, cell: any, teams: TeamStat[]) => {
  // eslint-disable-next-line
  const team = teams.find((team) => team.joinDate == id)
  const column = cell.info.header
  if (column === 'results') {
    return formatResults(cell.value)
  } else if (column === 'points') {
    return formatPoints(cell.value)
  } else if (column === 'runtime') {
    return formatRuntime(cell.value)
  } else if (column === 'joinDate') {
    return formatDates(team)
  } else if (column === 'name') {
    return formatTeamName(team)
  }
  return cell.value
}

const download = () => {
  api.getTeamsForDownload()
}

export interface MyTeamSectionProps {
  stats: any
}

const MyTeamSection: FunctionComponent<MyTeamSectionProps> = ({stats}) => {
  const teams = stats.teamStats
  const lastUpdated = stats.teamStatsUpdated

  if (teams === undefined) {
    return <DataTableSkeleton />
  }

  const dataRows = teams.map(teamToDataRow)
  return (
    <div className="wcg-my-team-history">
      <DataTable rows={dataRows} headers={dataHeaders} isSortable>
        {/* @ts-ignore */}
        {({rows, headers, getHeaderProps, getRowProps, getTableProps, getToolbarProps, getTableContainerProps}) => (
          <TableContainer {...getTableContainerProps()}>
            <TableToolbar {...getToolbarProps()}>
              <TableToolbarContent>
                <Download download={download} label="Download teams" />
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header: headerType) => (
                    <TableHeader key={header.key} {...getHeaderProps({header})}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: {id: string | number | null | undefined; cells: any[]}) => (
                  <TableRow key={row.id} {...getRowProps({row})}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{renderCell(row.id, cell, teams)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>

      <LastUpdated time={lastUpdated} />
    </div>
  )
}

export default MyTeamSection
