import React, {FunctionComponent} from 'react'

const FirstResultMilestoneContent: FunctionComponent = () => {
  return (
    <>
      <h4>Returned your first result</h4>
      <p>Thanks for contributing to World Community Grid.</p>
    </>
  )
}

export default FirstResultMilestoneContent
