import React, {FunctionComponent, ReactNode} from 'react'
import {Switcher as CarbonSwitcher, SwitcherDivider, SwitcherItem} from 'carbon-components-react'
import {SwitcherSectionProps} from './types'

const SwitcherSectionTitle: FunctionComponent = ({children}) => <h6 className="switcher__section-title">{children}</h6>

const SwitcherSectionItemsList: FunctionComponent = ({children}) => <ul className="switcher__section-items-list">{children}</ul>

const SwitcherLogo: FunctionComponent = ({children}) => <div className="switcher__logo-container"> {children} </div>

const SwitcherSection: FunctionComponent<SwitcherSectionProps> = ({title, itemsData}) => {
  return (
    <div className="switcher__section-container">
      <SwitcherSectionTitle>{title}</SwitcherSectionTitle>
      <SwitcherDivider />
      <SwitcherSectionItemsList>
        {itemsData.map(({title, url}, index) => {
          return (
            <SwitcherItem key={index} href={url} target="_blank" rel="noopener noreferrer" aria-label="">
              {title}
            </SwitcherItem>
          )
        })}
      </SwitcherSectionItemsList>
    </div>
  )
}

interface SwitcherProps {
  logo: string | ReactNode
}

const Switcher: FunctionComponent<SwitcherProps> = ({logo, children}) => {
  return (
    <CarbonSwitcher aria-label="Switcher Container" className="switcher">
      {logo && <SwitcherLogo>{logo}</SwitcherLogo>}
      {children}
    </CarbonSwitcher>
  )
}

export {Switcher, SwitcherSection, SwitcherSectionTitle, SwitcherDivider}
