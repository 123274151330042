import React, {FunctionComponent} from 'react'
import {Link} from '../../../../../../../components/Link'
import {ContentHeader, ContentSubHeader, ContentText, RatioImage} from '../../../index'
import {internal} from '../../../../../../../constants'

const Macos: FunctionComponent = () => {
  return (
    <>
      <ContentHeader>Download for macOS </ContentHeader>
      <ContentText>In order to contribute to World Community Grid, install the BOINC software application.</ContentText>
      <ContentSubHeader>1. Locate BOINC</ContentSubHeader>
      <ContentText>Locate the download file and open the folder.</ContentText>
      <RatioImage url="/images/install/mac_install_1.jpg" />
      <ContentSubHeader>2. Install</ContentSubHeader>
      <ContentText>
        Double-click the BOINC Installer to start the installation, and then follow the prompts that appear. Your installer will have a slightly different name
        than the one shown in the picture.
      </ContentText>
      <RatioImage url="/images/install/mac_install_2.jpg" />
      <ContentSubHeader>3. Start contributing</ContentSubHeader>
      <ContentText>
        Congratulations, you are now connected to World Community Grid and ready to start contributing! Just continue using your device as you normally would,
        and World Community Grid will harness your spare power for scientific research.
      </ContentText>
      <RatioImage url="/images/install/mac_install_3.jpg" />
      <ContentText>
        You have downloaded the Mac software.{' '}
        <Link underlined initialSize href={internal.Download.url}>
          Download for other platforms.
        </Link>
      </ContentText>
    </>
  )
}

export default Macos
