import React, {FunctionComponent, useEffect, useState} from 'react'
import cn from 'classnames'
import {SelectSkeleton} from 'carbon-components-react'
import {Card} from '../Card'
import {api} from '../../../../../../services'
import {Download} from '../../../../../../components/Download'
import {Calendar} from './components/Calendar'
import {getCalendarItemsFromHistoryItems, download} from './helpers'
import {CalendarItems} from './types'
import {HeatmapTooltip} from './components'

const CalendarCard: FunctionComponent<any> = ({className, ...rest}) => {
  const [calendarData, setCalendarData] = useState<CalendarItems | undefined>()

  useEffect(() => {
    const getData = async () => {
      const {data: historyStats} = await api.getStatsHistory(1, 180)
      historyStats && setCalendarData(getCalendarItemsFromHistoryItems(historyStats.items))
    }
    getData()
  }, [])

  return (
    <Card className={cn('calendar-card', className)} {...rest}>
      <Card.InnerWrapper>
        <Card.HorizontalWrapper>
          <Card.Title className="milestones-card-title">Activity</Card.Title>
          <Download download={download} label="Download teams" />
        </Card.HorizontalWrapper>
      </Card.InnerWrapper>
      <Card.Content>
        <Card.InnerWrapper className="card-content">
          {calendarData ? <Calendar data={calendarData} /> : <SelectSkeleton className="calendar-skeleton" />}
        </Card.InnerWrapper>
      </Card.Content>
      <Card.Divider />
      <Card.InnerWrapper className="card-footer horizontal-wrapper">
        <p className="footer-text" />
        <div className="footer-rating">
          <HeatmapTooltip />
        </div>
      </Card.InnerWrapper>
    </Card>
  )
}

export default CalendarCard
