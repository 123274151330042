import React, {FunctionComponent} from 'react'
import cn from 'classnames'
import _find from 'lodash.find'
import _get from 'lodash.get'
import {SkeletonText} from 'carbon-components-react'
import {Card} from '../Card'
import {Link} from '../../../../../../components/Link'
import {internal} from '../../../../../../constants/urls'
import {formatLongDateFromString, truncate} from '../../../../../../utils'
import {MemberStatsData} from '../../../../../../services/api/types'
import {TeamStat} from '../../../../../../services/api/types/member-api'

export interface OverviewCardProps {
  className?: string
  stats: MemberStatsData | undefined
}

function isCurrentTeam(teamStats: TeamStat) {
  return teamStats.retireDate === undefined
}

function numBadges(stats: MemberStatsData) {
  if (stats.badges === undefined) {
    return 0
  }
  let numBadges = 0
  stats.badges.forEach((badge) => {
    numBadges = numBadges + badge.badgeLevel + 1
  })
  return numBadges
}

const getDisplay = (stats: undefined | MemberStatsData) => {
  const currentTeam = stats && stats.teamStats ? _find(stats.teamStats, isCurrentTeam) : undefined
  return stats && stats.member
    ? {
        userName: _get(stats, 'member.userName'),
        lastResult: _get(stats, 'member.lastResult') ? formatLongDateFromString(_get(stats, 'member.lastResult')) : 'No results',
        teamName: _get(currentTeam, 'name', 'Join a team'),
        badgesAmount: numBadges(stats),
        sinceDate: _get(stats, 'member.joinDate') || '--',
        joinDate: formatLongDateFromString(_get(stats, 'member.joinDate')),
        currentTeamId: _get(currentTeam, 'id', undefined),
      }
    : undefined
}

const OverviewCard: FunctionComponent<OverviewCardProps> = ({children, className, stats, ...rest}) => {
  const display = getDisplay(stats)

  return (
    <Card className={cn('summary-card', className)} {...rest}>
      <div className="summary-card-content">
        <div className="summary-card-content-part">
          <Card.InnerWrapper className="vertical-wrapper">
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <Card.SemiText gray>Username</Card.SemiText>
                  </td>
                  <td>{display ? <Card.SemiText className="text">{display.userName}</Card.SemiText> : <SkeletonText style={{height: '8px'}} />}</td>
                </tr>
                <tr>
                  <td>
                    <Card.SemiText gray>Last result returned</Card.SemiText>
                  </td>
                  <td>{display ? <Card.SemiText>{display.lastResult}</Card.SemiText> : <SkeletonText style={{height: '8px'}} />}</td>
                </tr>
                <tr>
                  <td>
                    <Card.SemiText gray>Team</Card.SemiText>
                  </td>
                  <td>
                    {display ? (
                      display.currentTeamId ? (
                        <Link href={internal['Get to know your team'].url(display.currentTeamId)}>
                          <Card.SemiText className="text">{display.teamName}</Card.SemiText>
                        </Link>
                      ) : (
                        <Link href={internal['Find a team'].url}>
                          <Card.SemiText>Find a team</Card.SemiText>
                        </Link>
                      )
                    ) : (
                      <SkeletonText style={{height: '8px'}} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Card.SemiText gray>Badges earned</Card.SemiText>
                  </td>
                  <td>{display ? <Card.SemiText>{display.badgesAmount}</Card.SemiText> : <SkeletonText style={{height: '8px'}} />}</td>
                </tr>
              </tbody>
            </table>
            <Link underlined href={internal['Profile and settings'].url}>
              <Card.SemiText>Edit profile and settings</Card.SemiText>
            </Link>
          </Card.InnerWrapper>
        </div>
        <div className="summary-card-content-part">
          <Card.Divider />
          <Card.InnerWrapper className="vertical-wrapper">
            <div className="member-year">
              <Card.SemiText gray>Member since</Card.SemiText>
              {display ? <p className="member-year-text">{truncate(display.sinceDate, 4)}</p> : <SkeletonText />}
            </div>
            <Card.SemiText gray>Joined: {display ? display.joinDate : ''}</Card.SemiText>
          </Card.InnerWrapper>
        </div>
      </div>
    </Card>
  )
}

export default OverviewCard
