import React, {FunctionComponent} from 'react'
import {Helmet} from 'react-helmet'

import {metadata} from '../../constants/metadata'
import {getHost} from '../../utils'
import {PageMetadataProps} from './types'

const PageMetadata: FunctionComponent<PageMetadataProps> = (props: PageMetadataProps) => {
  const pageMetadata: PageMetadataProps = {...metadata.default, ...props}
  const server = ['https://', getHost()].join('')
  const url = [server, window.location.pathname].join('')
  const title = pageMetadata.title?.split('|')[0].trim()
  const image = [server, pageMetadata.image].join('')
  return (
    <Helmet>
      <title>{pageMetadata.title}</title>
      <meta name="description" property="description" content={pageMetadata.description} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={pageMetadata.description} />
      <meta property="og:image" content={image} />
      <meta property="twitter:site" content={pageMetadata.site} />
      <meta property="twitter:card" content="summary" />
    </Helmet>
  )
}

export default PageMetadata
