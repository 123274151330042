import React, {FunctionComponent} from 'react'
import cn from 'classnames'

export interface PageHeaderProps {
  className?: string
}

const PageHeader: FunctionComponent<PageHeaderProps> = ({children, className, ...rest}) => {
  return (
    <div className={cn('page-header', className)} {...rest}>
      {children}
    </div>
  )
}

export default PageHeader
