import React, {useEffect, FunctionComponent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import BaseLayout from '../../../layouts/BaseLayout/BaseLayout'
import {setNavigation} from '../../../store/navigation/actions'
import {PageMetadata} from '../../../components'
import {metadata} from '../../../constants/metadata'
import {LoginForm} from './components'

const Login: FunctionComponent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavigation({section: 'Login', type: 'main', highlight: 'Login'}))
  }, [])

  return (
    <BaseLayout>
      <PageMetadata {...metadata.login} />
      <div className="login-page-content">
        <LoginForm />
      </div>
    </BaseLayout>
  )
}

export default Login
