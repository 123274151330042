import React, {FunctionComponent, useEffect} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {internal} from '../../../../../constants'
import {RootState} from '../../../../../store'
import {UserState} from '../../../../../store/user/types'
import {MainContentWrapper} from '../../../../../components/layout/MainContentWrapper'
import {JoinMainHeader} from '../JoinMainHeader'
import {Link} from '../../../../../components/Link'
import {userSelector} from '../../../../../store/selectors'
import {SignUpForm} from './components'

const SignUp: FunctionComponent<RouteComponentProps> = () => {
  const history = useHistory()
  const user = useSelector<RootState, UserState>(userSelector, shallowEqual)
  const location = useLocation()
  const newLocation = {...location, pathName: internal['Join Projects'].url}
  useEffect(() => {
    user.loggedIn && history.push(newLocation)
  }, [history, user.loggedIn, newLocation])

  return (
    <>
      <JoinMainHeader title="Create Account" noBottomPadding />
      <MainContentWrapper noVerticalPaddings>
        <p className="form__short-text">
          Already have an account?{' '}
          <Link underlined href={internal['Log in'].url}>
            Log in
          </Link>
        </p>
      </MainContentWrapper>
      <MainContentWrapper noVerticalPaddings>
        <SignUpForm className="sign-up-form__join" noHeader />
      </MainContentWrapper>
    </>
  )
}

export default SignUp
