import React, {FunctionComponent, ReactElement} from 'react'
import cn from 'classnames'
import {CarbonIconType} from '@carbon/icons-react'

interface StatisticCardProps {
  icon: CarbonIconType
  title: string
  text: string
  className?: string
}

const defaultProps = {
  title: 'Title',
  text: '',
}

const StatisticCard: FunctionComponent<StatisticCardProps> = ({icon, title, text, className, ...rest}) => {
  const IconComponent = icon
  return (
    <div className={cn('card_statistic', className)} {...rest}>
      <div className="card-image-container">
        <IconComponent className="card-image pictogram" />
      </div>
      <div className="card-description">
        <p className="card-description__title">{title}</p>
        <p className="card-description__text">{text}</p>
      </div>
    </div>
  )
}

StatisticCard.defaultProps = defaultProps

interface StatisticCardGroupProps {
  className?: string
  children: ReactElement[]
}

const StatisticCardGroup: FunctionComponent<StatisticCardGroupProps> = ({children, className}) => {
  return (
    <div className={cn('card-group_statistic', className)}>
      {React.Children.map(children, (child) => (
        //@ts-ignore
        <div className="card-group__card-wrapper">{React.cloneElement(child)}</div>
      ))}
    </div>
  )
}

export {StatisticCard, StatisticCardGroup}
