import React, {useEffect} from 'react'
import {Header as CarbonHeader, HeaderName, HeaderGlobalBar} from 'carbon-components-react/lib/components/UIShell'
import {HeaderContainer, HeaderMenuButton, LinkProps} from 'carbon-components-react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from '../../components'
import {internal} from '../../constants'
import {RootState} from '../../store'
import {UserState} from '../../store/user/types'
import {imageUrls} from '../../constants/imageUrls'
import {fetchProjects} from '../../store/projects/actions'
import {userSelector} from '../../store/selectors'
import {TopNav, SideNav, AccountAction, LoginStatus} from './components'
import {header} from './content'

const {topNavMenu} = header

const Header = () => {
  const user = useSelector<RootState, UserState>(userSelector)
  const {loggedIn, roles} = user

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchProjects())
  }, [])

  return (
    <>
      <LoginStatus />
      <HeaderContainer
        render={({isSideNavExpanded, onClickSideNavExpand}) => {
          return (
            <div className="header__container">
              <CarbonHeader aria-label="World Community Grid">
                <HeaderMenuButton aria-label="Open menu" isCollapsible onClick={onClickSideNavExpand} isActive={isSideNavExpanded} />

                <HeaderName<LinkProps> href={header.wcgLogo.url} prefix="" element={Link} className="wcg-logo-link">
                  <span className="wcg-logo-link-inner-logo">
                    <div className="wcg-logo-link-inner-logo__img">
                      <img src={imageUrls.wcgIcon} alt="World Community Grid icon" />
                    </div>
                  </span>
                  <h5 className="wcg-logo-link-inner-text">World Community Grid</h5>
                </HeaderName>
                <TopNav itemsData={topNavMenu.items} />
                <HeaderGlobalBar>
                  {!loggedIn && (
                    <Link className="bx--header__action--join bx--header__action" href={internal.Join.url}>
                      <p className="bx--header__action--join-text">Join</p>
                    </Link>
                  )}
                  <AccountAction loggedIn={loggedIn} roles={roles} />
                </HeaderGlobalBar>
                <SideNav isSideNavExpanded={isSideNavExpanded} onClickSideNavExpand={onClickSideNavExpand} />
              </CarbonHeader>
            </div>
          )
        }}
      />
    </>
  )
}

export default Header
