import React, {FunctionComponent, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

import {Button, Grid, Loading, Row, Column} from 'carbon-components-react'

import {setNavigation} from '../../../store/navigation/actions'
import {api} from '../../../services'
import {Milestone} from '../../../services/api/types/milestones-api'

import BaseLayout from '../../../layouts/BaseLayout/BaseLayout'
import {WithContextMenuWrapper} from '../../../layouts/WithContextMenuWrapper'
import {PageHeader} from '../MyContribution/components/layout/headers/PageHeader'
import {PageMetadata} from '../../../components/PageMetadata'
import {metadata} from '../../../constants/metadata'
import {MilestoneItem} from './components/MilestoneItem'

const initialMilestones: Milestone[] = [
  {
    date: '',
    type: 'S',
  },
]

const Milestones: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [milestones, setMilestones] = useState<Milestone[]>(initialMilestones)
  const [maxMilestones, setMaxMilestones] = useState<number>(1)
  const [numMilestonesToShow, setNumMilestonesToShow] = useState<number>(10)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchMore = () => {
    setLoading(true)
    setNumMilestonesToShow(maxMilestones)
  }

  useEffect(() => {
    dispatch(setNavigation({section: 'My contribution', type: 'main', highlight: 'Milestones'}))
  }, [])

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const resp = await api.getMilestones(numMilestonesToShow)
      const milestones = resp.data
      if (milestones && milestones.items) {
        setMilestones(milestones.items)
      }
      if (milestones && milestones.metadata && milestones.metadata.recordsAvailable) {
        setMaxMilestones(milestones.metadata.recordsAvailable)
      }
      setLoading(false)
    }
    getData()
  }, [numMilestonesToShow])

  let showMore
  if (loading) {
    if (maxMilestones > 1) {
      showMore = (
        <Button size="sm" onClick={fetchMore} disabled={true}>
          <Loading withOverlay={false} small description="Loading more milestones" />
          Loading
        </Button>
      )
    }
  } else if (maxMilestones > numMilestonesToShow) {
    showMore = (
      <Button size="field" onClick={fetchMore}>
        Show all&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Button>
    )
  }

  return (
    <BaseLayout>
      <PageMetadata {...metadata.contribution.milestones} />
      <WithContextMenuWrapper>
        <PageHeader>Milestones</PageHeader>
        <Grid className="bx--no-gutter">
          {milestones.map((milestone, index) => (
            <MilestoneItem key={index} last={index === numMilestonesToShow - 1 || index === maxMilestones - 1} milestone={milestone} />
          ))}
          <Row>
            <Column sm={{span: 4, offset: 0}} md={{span: 3, offset: 2}}>
              {showMore}
            </Column>
          </Row>
        </Grid>
        <div style={{height: '64px'}}>&nbsp;</div>
      </WithContextMenuWrapper>
    </BaseLayout>
  )
}

export default Milestones
