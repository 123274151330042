export const installationDates = [
  {
    id: 'all-devices',
    text: 'anytime (all devices)',
  },
  {
    id: 'last-3-days',
    text: 'in the last 3 days',
  },
  {
    id: 'last-7-days',
    text: 'in the last 7 days',
  },
  {
    id: 'last-14-days',
    text: 'in the last 14 days',
  },
  {
    id: 'last-30-days',
    text: 'in the last 30 days',
  },
]

export const lastResults = [
  {
    id: 'all-devices',
    text: 'anytime (all devices)',
  },
  {
    id: 'last-3-days',
    text: 'in the last 3 days',
  },
  {
    id: 'last-7-days',
    text: 'in the last 7 days',
  },
  {
    id: 'last-14-days',
    text: 'in the last 14 days',
  },
  {
    id: 'last-30-days',
    text: 'in the last 30 days',
  },
  {
    id: 'last-60-days',
    text: 'in the last 60 days',
  },
  {
    id: 'last-90-days',
    text: 'in the last 90 days',
  },
]
