import React, {FunctionComponent} from 'react'
import {Link} from '../../../../../../../components/Link'
import {ContentHeader, ContentSubHeader, ContentText, RatioImage} from '../../../index'
import {internal} from '../../../../../../../constants'

const Windows: FunctionComponent = () => {
  return (
    <>
      <ContentHeader>Download for Windows</ContentHeader>
      <ContentText>In order to contribute to World Community Grid, install the BOINC software application.</ContentText>
      <ContentSubHeader>1. Locate BOINC</ContentSubHeader>
      <ContentText>
        Locate the downloaded BOINC Installer. The location will vary depending on your browser and the name of your installer will include different characters
        towards the end of the filename.
      </ContentText>
      <RatioImage url="/images/install/win_install_1.jpg" />
      <ContentSubHeader>2. Install</ContentSubHeader>
      <ContentText>Double-click to run the installer, and then follow the prompts that appear.</ContentText>
      <RatioImage url="/images/install/win_install_2.jpg" />
      <ContentSubHeader>3. Start contributing</ContentSubHeader>
      <ContentText>
        Congratulations, you are now connected to World Community Grid and ready to start contributing! Just continue using your device as you normally would,
        and World Community Grid will harness your spare power for scientific research.
      </ContentText>
      <RatioImage url="/images/install/win_install_3.jpg" />
      <ContentText>
        You have downloaded the Windows software.{' '}
        <Link underlined initialSize href={internal.Download.url}>
          Download for other platforms.
        </Link>
      </ContentText>
    </>
  )
}

export default Windows
