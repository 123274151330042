import {Link} from 'carbon-components-react'
import _get from 'lodash.get'
import React, {FunctionComponent} from 'react'
import {internal} from '../../../../../constants'
import {NavTab} from '../../index'
import {JoinActionButton, TabBar, TabBarContentItem} from '../../../../../components'
import {HelpResearchersSectionProps} from './types'

const HelpResearchersSection: FunctionComponent<HelpResearchersSectionProps> = ({projects, loggedIn, signedUpProjects}) => {
  return (
    <div className="main-content-wrapper__full-width-container secondary">
      <div className="bx--grid main-content-wrapper__grid bottom-padding-layout-03 help-researchers-section">
        <TabBar
          renderTabs={({labels, activeTabLabel, setActiveTabLabel}) => {
            return (
              <>
                <h5 className="main-content-wrapper__header main-content-wrapper__header_research bx--hang--left bx--hang--right">You can help researchers</h5>
                <div className="help-researchers-section__nav">
                  <ul>
                    {labels.map((label, index) => (
                      <NavTab key={index} isActive={label === activeTabLabel} onChangeActiveTab={setActiveTabLabel} label={label} />
                    ))}
                  </ul>
                </div>
                <div className="bx--hang--left">
                  <Link href={internal['Active research'].url}>See all projects &#8594;</Link>
                </div>
              </>
            )
          }}
        >
          {projects &&
            projects.map(({prismic, shortName}: any, index: string) => {
              const label = _get(prismic, 'tiny_summary') || ''
              const imageUrl = _get(prismic, 'medium_summary_header_image.url')
              const title = _get(prismic, 'medium_summary_header')
              const text = _get(prismic, 'medium_summary')
              return (
                <TabBarContentItem key={index} label={label}>
                  <div className="project-card">
                    <div className="project-card__image-container bx--aspect-ratio bx--aspect-ratio--16x9">
                      <div className="bx--aspect-ratio--object">
                        <img src={imageUrl} className="project-card__image" alt="" />
                      </div>
                    </div>
                    <h3 className="project-card__title">{title}</h3>
                    <p className="project-card__summary">{text}</p>
                    <JoinActionButton
                      loggedIn={loggedIn}
                      currentProjectShortName={shortName}
                      signedUpProject={signedUpProjects ? signedUpProjects.find((name) => name === shortName) : undefined}
                    />
                  </div>
                </TabBarContentItem>
              )
            })}
        </TabBar>
      </div>
    </div>
  )
}

export default HelpResearchersSection
