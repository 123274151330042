import React, {FunctionComponent, useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {Grid, Row, GridElement} from '../MyContribution/components/layout'
import {PageHeader} from '../MyContribution/components/layout/headers/PageHeader'

import {WithContextMenuWrapper, BaseLayout} from '../../../layouts'
import {PageMetadata} from '../../../components/PageMetadata'
import {metadata} from '../../../constants/metadata'
import {setNavigation} from '../../../store/navigation/actions'

import {DevicesTable, Boxes} from './components'

const Devices: FunctionComponent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavigation({section: 'My contribution', type: 'main', highlight: 'Devices'}))
  }, [])

  return (
    <BaseLayout>
      <PageMetadata {...metadata.contribution.devices} />
      <WithContextMenuWrapper>
        <PageHeader>Devices</PageHeader>
        <Grid>
          <Row>
            <GridElement noMinHeight lg={3} md={2} sm={2}>
              <DevicesTable />
            </GridElement>
          </Row>
        </Grid>
        <Boxes />
      </WithContextMenuWrapper>
    </BaseLayout>
  )
}
export default Devices
