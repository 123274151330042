import React, {FunctionComponent} from 'react'
import _get from 'lodash.get'
import {Link} from 'carbon-components-react'
import content from '../../../content'
import {NewsCard, NewsCardGroup} from '../../index'
import {MainContentWrapper} from '../../../../../components'
import {HomePageUpdates, Primary} from '../../../../../services/api/types'

const UpdatesSection: FunctionComponent<HomePageUpdates> = (updates) => {
  const section = content.updatesSection
  return (
    <MainContentWrapper
      withDivider
      noGutter
      className="updates-section"
      header={
        <>
          <h5 className="main-content-wrapper__header main-content-wrapper__header_margin">{section.title}</h5>
          <p className="main-content-wrapper__header-description">{section.description}</p>
          <Link href={section.link.src}>{section.link.text}</Link>
        </>
      }
      expand
    >
      <NewsCardGroup>
        {updates &&
          updates.body &&
          updates.body.map((update, index) => {
            const {link, subject, summary, date, image}: Primary = _get(update, 'primary')
            return <NewsCard key={index} url={link} title={subject} text={summary} date={date} src={image.url} />
          })}
      </NewsCardGroup>
    </MainContentWrapper>
  )
}

export default UpdatesSection
