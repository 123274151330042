import React, {useCallback, useEffect, useState} from 'react'
import {HeaderGlobalAction} from 'carbon-components-react'
import cn from 'classnames'
import {FilterEdit16} from '@carbon/icons-react'
import {handleOutsideClick, makeId} from '../../../../../utils'

export interface CustomOverflowMnuProps {
  children: any
}
const FilterPanel = ({children}: CustomOverflowMnuProps) => {
  const [id] = useState(`filterPanel${makeId(4)}`)
  const [isShown, setIsShown] = useState(false)

  const toggle = useCallback(() => {
    setIsShown((prev) => !prev)
  }, [])

  const handlePanelOutsideClick = handleOutsideClick(`#${id}`, (e) => {
    setIsShown(false)
  })
  useEffect(() => {
    window.addEventListener('click', handlePanelOutsideClick)
    return () => {
      window.removeEventListener('click', handlePanelOutsideClick)
    }
  }, [])

  return (
    <div id={id} style={{width: 'auto', height: '100%'}} className="bx--overflow-menu bx--overflow-menu__filter-panel wcg-filter-panel">
      <HeaderGlobalAction
        className={cn('bx--header__action  bx--header__action--account wcg--action', isShown && 'active')}
        aria-label="Filter Panel"
        onClick={toggle}
      >
        <FilterEdit16 />
      </HeaderGlobalAction>
      <div className={cn('wcg--action__filter-panel bx--overflow-menu-options bx--overflow-menu-options--open wcg-filter-panel-container', isShown && 'show')}>
        {React.Children.map(children, (child, index) => {
          //@ts-ignore
          return React.cloneElement<any>(child, {
            setIsShown,
          })
        })}
      </div>
    </div>
  )
}

export default FilterPanel
