import {useEffect} from 'react'
import {useLocation} from 'react-router'

import {api} from '../../services'

const analyticsParams = ['rtid', 'partner', 'rs_campaign', 'client_id', 'recruiterId', 'recChannel', 'messageId', 'utm_campaign']

const searchForAnalyticsParameters = (search: string) => {
  let parameters = search
  if (parameters && parameters.startsWith('?')) {
    parameters = parameters.slice(1)
  }
  if (parameters === undefined || parameters.length === 0) {
    return
  }

  let params = ''
  parameters.split('&').forEach((item) => {
    const param = item.split('=')
    if (param.length === 2) {
      if (analyticsParams.includes(param[0])) {
        if (params.length === 0) {
          params = item
        } else {
          params = `${params}&${item}`
        }
      }
    }
  })
  if (params.length > 0) {
    api.putAnalytics(encodeURI(params))
  }
}

const WCGAnalytics = () => {
  const {search} = useLocation()
  useEffect(() => {
    searchForAnalyticsParameters(search)
  }, [search])
  return null
}

export default WCGAnalytics
