import React, {FunctionComponent, ReactNode} from 'react'
import cn from 'classnames'
import {Divider} from '../../../components'

export interface MainContentWrapperProps {
  withDivider?: boolean
  header?: string | ReactNode
  secondary?: boolean
  noGutter?: boolean
  bottomPadding?: string
  noVerticalPaddings?: boolean
  noBottomPadding?: boolean
  invertColors?: boolean
  fullWidthContainerClassName?: string
  className?: string
  headerClassName?: string
  expand?: boolean
  contentClassName?: string
}

const MainContentWrapper: FunctionComponent<MainContentWrapperProps> = ({
  children,
  withDivider,
  header,
  secondary,
  noGutter,
  bottomPadding,
  noVerticalPaddings,
  noBottomPadding,
  invertColors,
  fullWidthContainerClassName,
  className,
  headerClassName,
  contentClassName,
  expand,
}) => {
  return (
    <div
      className={cn(
        'main-content-wrapper',
        invertColors && 'main-content-wrapper_inverted_colors',
        'main-content-wrapper__full-width-container',
        secondary && 'secondary',
        fullWidthContainerClassName,
      )}
    >
      <div
        className={cn(
          'bx--grid',
          'main-content-wrapper__grid',
          bottomPadding && `bottom-padding-${bottomPadding}`,
          noVerticalPaddings && 'without-vertical-paddings',
          noBottomPadding && 'without-bottom-padding',
          className,
        )}
      >
        <div className={cn('bx--row')}>
          <div className={cn(header && 'main-content-wrapper__header-container', 'bx--col-lg-3 bx--col-md-8 bx--col-sm-4', headerClassName)}>
            {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
            {typeof header === 'string' ? <h5 className="main-content-wrapper__header">{header}</h5> : <>{header}</>}
          </div>
          <div
            className={cn(
              expand ? 'bx--col-lg' : 'bx--col-lg-8',
              'bx--offset-lg-1',
              noGutter && 'bx--no-gutter',
              contentClassName ? contentClassName : 'bx--col-md-8 bx--col-sm-4',
            )}
          >
            {children}
          </div>
        </div>
      </div>
      {withDivider && <Divider />}
    </div>
  )
}

export default MainContentWrapper
