import React, {useEffect, useMemo, useState} from 'react'
import _get from 'lodash.get'
import {useDispatch, useSelector} from 'react-redux'
import BaseLayout from '../../layouts/BaseLayout/BaseLayout'
import {api} from '../../services'
import {fetchProjects} from '../../store/projects/actions'
import {RootState} from '../../store'
import {UserState} from '../../store/user/types'
import {ProjectsState} from '../../store/projects/types'
import {HomePageHero, Project} from '../../services/api/types'
import {PageMetadata} from '../../components/PageMetadata'
import {setNavigation} from '../../store/navigation/actions'
import {delLoginRedirect} from '../../store/loginRedirect/actions'
import {filterActiveProjects} from '../../utils'
import {projectsSelector, userSelector} from '../../store/selectors'
import content from './content'
import {
  HeroSection,
  UpdatesSection,
  HowItWorksSection,
  HelpResearchersSection,
  WithYourHelpSection,
  FeaturesSection,
  LooksWhoTalkingSection,
  ResearchersSaySection,
  ByTheNumberSection,
  YourSparePowerSection,
} from './components'

const getComparedSignedUpProjectName = (heroProjectShortName: string, projectPreferencesShortNames: string[]) =>
  projectPreferencesShortNames && projectPreferencesShortNames.find((name) => name === heroProjectShortName)

const sortProjects = (projects: Project[] = [], compareFunc = (a: Project, b: Project) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)) =>
  projects.sort(compareFunc)

const getButtonData = (heroData: HomePageHero | undefined, type: string) => {
  return heroData !== undefined && heroData.body !== null && heroData.body !== undefined ? heroData.body.find((item) => item.slice_type === type) : undefined
}

const Home = () => {
  const dispatch = useDispatch()
  const {loggedIn} = useSelector<RootState, UserState>(userSelector)
  const {projects} = useSelector<RootState, ProjectsState>(projectsSelector)
  const [heroSectionPropsFromData, setHeroSectionPropsFromData] = useState({})
  const [projectPreferencesShortNames, setProjectPreferencesShortNames] = useState<string[]>([])
  const [pageMetadata, setPageMetadata] = useState({})
  const [updatesSectionPropsFromData, setUpdatesSectionPropsFromData] = useState({})

  useEffect(() => {
    if (!loggedIn) {
      //clear out setting if user not logged in
      dispatch(delLoginRedirect())
    }
  }, [loggedIn])

  useEffect(() => {
    dispatch(fetchProjects())
  }, [])

  useEffect(() => {
    const getAProjectPreferencesShortNames = async () => {
      const projectPreferences = await api.getProjectPreferences('default')
      return _get(projectPreferences, 'preferredProjectList')
    }
    const getAllData = async () => {
      const homePageContent = await api.getPrismicHomePageContent()
      const heroData = homePageContent.hero
      const learnMore = getButtonData(heroData, 'learn_more')
      const contribute = getButtonData(heroData, 'contribute_link')
      setHeroSectionPropsFromData((prevState) => ({
        ...prevState,
        heroImageUrl: _get(heroData, 'background_image.url'),
        heroShortName: _get(contribute, 'primary.project_short_name'),
        learnLink: _get(learnMore, 'primary.link'),
        titleParagraphs: _get(heroData, 'headline'),
        text: _get(heroData, 'summary'),
        imageOverlayStyle: _get(heroData, 'image_overlay_style'),
        textColor: _get(heroData, 'text_color'),
      }))
      setPageMetadata((prevState) => ({
        ...prevState,
        title: _get(heroData, 'page_title'),
        description: _get(heroData, 'page_description'),
      }))

      setUpdatesSectionPropsFromData(() => homePageContent.updates)

      const projectPrefShortNames = await getAProjectPreferencesShortNames()
      setProjectPreferencesShortNames(projectPrefShortNames)

      setHeroSectionPropsFromData((prevState) => ({
        ...prevState,
        signedUpProject: getComparedSignedUpProjectName(_get(contribute, 'primary.project_short_name'), projectPrefShortNames),
      }))
    }
    getAllData()
  }, [])

  useEffect(() => {
    dispatch(setNavigation({section: 'Home', type: 'main', highlight: ''}))
  }, [])

  const getRandomItemFromArray = (array: any[]) => array[Math.floor(Math.random() * array.length)]

  const sortedProjects = useMemo(() => sortProjects(filterActiveProjects(projects)), [projects])
  const [randomYourSparePowerSectionColor] = useState(getRandomItemFromArray(content.yourSparePowerSection.colors))
  const [randomYourSparePowerSectionImage] = useState(getRandomItemFromArray(content.yourSparePowerSection.images))

  return (
    <BaseLayout>
      <PageMetadata {...pageMetadata} />
      <HeroSection loggedIn={loggedIn} {...heroSectionPropsFromData} />
      <UpdatesSection {...updatesSectionPropsFromData} />
      <HowItWorksSection />
      <HelpResearchersSection projects={sortedProjects} loggedIn={loggedIn} signedUpProjects={projectPreferencesShortNames} />
      <WithYourHelpSection />
      <ByTheNumberSection />
      <FeaturesSection />
      <ResearchersSaySection />
      <LooksWhoTalkingSection />
      <YourSparePowerSection backgroundColor={randomYourSparePowerSectionColor} imageSrc={randomYourSparePowerSectionImage} />
    </BaseLayout>
  )
}

export default Home
