export enum ProjectShortNames {
  hst1 = 'hst1',
  fahb = 'fahb',
  oet1 = 'oet1',
  ugm1 = 'ugm1',
  cfsw = 'cfsw',
  sn2s = 'sn2s',
  gfam = 'gfam',
  dsfl = 'dsfl',
  c4cw = 'c4cw',
  cep2 = 'cep2',
  hcmd2 = 'hcmd2',
  hfcc = 'hfcc',
  cep1 = 'cep1',
  rice = 'rice',
  hcc1 = 'hcc1',
  acah = 'acah',
  hcmd = 'hcmd',
  fcg1 = 'fcg1',
  hdc = 'hdc',
  hpf2 = 'hpf2',
  faah = 'faah',
  proteome = 'proteome',
  opn1 = 'opn1',
  arp1 = 'arp1',
  mip1 = 'mip1',
  scc1 = 'scc1',
  mcm1 = 'mcm1',
  zika = 'zika',
  dddt2 = 'dddt2',
  flu1 = 'flu1',
  dddt = 'dddt',
}

export enum ProjectMenuItems {
  'Overview' = 'Overview',
  Details = 'Details',
  News = 'News',
  Researchers = 'Researchers',
  FAQs = 'FAQs',
  Statistics = 'Statistics',
  'Spread the word' = 'Spread the word',
  'Tell a friend' = 'Tell a friend',
}

export type ShortNames = keyof typeof ProjectShortNames
export type ProjectItems = keyof typeof ProjectMenuItems
export type AccordanceObject = {[key in ShortNames]: ProjectItems[]}

const createObjectsFrom = (keys: ShortNames[], value: ProjectItems[]): AccordanceObject =>
  keys.reduce<any>((projectsAccordance, shortName) => ({...projectsAccordance, [shortName]: value}), {})

export const projectsToMenuItemsDictionary: AccordanceObject = {
  ...createObjectsFrom(
    [
      'hst1',
      'fahb',
      'oet1',
      'ugm1',
      'cfsw',
      'sn2s',
      'gfam',
      'dsfl',
      'c4cw',
      'cep2',
      'hcmd2',
      'hfcc',
      'cep1',
      'rice',
      'hcc1',
      'acah',
      'hcmd',
      'fcg1',
      'hdc',
      'hpf2',
      'faah',
      'proteome',
    ],
    ['Overview', 'Details', 'News', 'Researchers', 'FAQs', 'Statistics'],
  ),
  ...createObjectsFrom(['opn1', 'dddt', 'dddt2', 'flu1'], ['Overview', 'News', 'Researchers', 'FAQs', 'Statistics']),
  ...createObjectsFrom(['mip1', 'zika'], ['Overview', 'Details', 'News', 'Researchers', 'FAQs', 'Statistics']),
  scc1: ['Overview', 'Details', 'News', 'Researchers', 'FAQs', 'Statistics', 'Tell a friend'],
  arp1: ['Overview', 'News', 'Researchers', 'Statistics'],
  mcm1: ['Overview', 'Details', 'News', 'Researchers', 'FAQs', 'Statistics', 'Spread the word'],
}
