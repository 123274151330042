import {ChartTabularData, LegendPositions, LineChartOptions, ScaleTypes, TickRotations} from '@carbon/charts/interfaces'
import {formatISODateFromDate, getDateDaysFromNow, getDateYearsFromNow} from '../../../../../../utils'
import {api} from '../../../../../../services'
import {ChartItem, HistoryItem, Metrics, PreparedHistoryItem, ProjectItem, RuntimeScale, TimeFrames, TimeFramesIds} from './types'
import {runtimeScales} from './content'

const getDateForTimeFrame = (timeFrame: TimeFramesIds) => {
  switch (timeFrame) {
    case 'last-90-days':
      return getDateDaysFromNow(90)
    case 'last-year':
      return getDateYearsFromNow(1)
    case 'last-5-years':
      return getDateYearsFromNow(5)
    default:
      return new Date('2004-11-01')
  }
}

export const fillMissingEntries = (items: PreparedHistoryItem[]) => {
  items.sort((a, b) => a.date.getTime() - b.date.getTime())
  let date = new Date(items[0].date)
  const additional: PreparedHistoryItem[] = []
  let add = true
  items.forEach((item) => {
    while (date < new Date(item.date)) {
      if (add) {
        additional.push({
          date,
          shortName: items[0].shortName,
          runtimeInSeconds: 0.000000000000001,
          points: 0.000000000000001,
          results: 0.000000000000001,
        })
        add = false
      }
      date = new Date(date.getTime() + 24 * 3600 * 1000)
    }
    if (!add) {
      if (additional[additional.length - 1].date.getTime() !== date.getTime() - 24 * 3600 * 1000) {
        additional.push({
          date: new Date(date.getTime() - 24 * 3600 * 1000),
          shortName: items[0].shortName,
          runtimeInSeconds: 0.000000000000001,
          points: 0.000000000000001,
          results: 0.000000000000001,
        })
      }
      add = true
    }
    date = new Date(date.getTime() + 24 * 3600 * 1000)
  })
  return additional.concat(items)
}

export const getProjectItems = (projects: ProjectItem[], project: string) => {
  let result: ProjectItem | undefined
  projects.forEach((item) => {
    if (item.shortName === project) {
      result = item
    }
  })
  if (result === undefined) {
    result = {
      shortName: project,
      items: [],
    }
    projects.push(result)
  }
  return result
}

export const prepareHistoryData = (items: HistoryItem[]) => {
  //fill gaps with 0 valued entries
  const projects: ProjectItem[] = []
  items &&
    items.forEach((item) => {
      getProjectItems(projects, item.shortName).items.push({
        date: new Date(item.date),
        points: item.points,
        results: item.results,
        runtimeInSeconds: item.runtimeInSeconds,
        shortName: item.shortName,
      })
    })

  let results: PreparedHistoryItem[] = []
  projects.forEach((item) => {
    results = results.concat(fillMissingEntries(item.items))
  })

  return results.sort((a, b) => a.date.getTime() - b.date.getTime())
}

export const getProjectName = (item: PreparedHistoryItem, projects: any) => {
  if (projects === undefined) {
    return item.shortName
  }
  const project = projects.filter((project: {shortName: string}) => project.shortName === item.shortName)
  if (project === undefined || project.length === 0) {
    if (item.shortName === 'beta') {
      return 'Beta Testing'
    }
    return item.shortName
  }
  return project[0].name
}

export const getChartItemFromHistoryItem = (item: PreparedHistoryItem, projects: any, runtimeScale: RuntimeScale): ChartItem => {
  return {
    group: getProjectName(item, projects),
    date: formatISODateFromDate(item.date),
    points: item.points,
    results: item.results,
    runtime: item.runtimeInSeconds / runtimeScale.scale,
  }
}

export const formatHistoryDataToChartData = (items: PreparedHistoryItem[], projects: any, runtimeScale: RuntimeScale): ChartTabularData =>
  items.map((historyItem) => getChartItemFromHistoryItem(historyItem, projects, runtimeScale))

export const chooseItemsByTimeFrame = (items: PreparedHistoryItem[], timeFrame: TimeFramesIds): PreparedHistoryItem[] => {
  if (items === undefined) {
    return []
  }

  const date = getDateForTimeFrame(timeFrame)
  const result = items.filter((item) => {
    return item.date.getTime() > date.getTime()
  })
  return result
}

export const formatChartOptions = (timeFrame: TimeFrames, metric: Metrics, formatScale: RuntimeScale, containerHeight: string): LineChartOptions => {
  let yTitle
  if (metric.id === 'runtime') {
    yTitle = `${metric.text} (${formatScale.text})`
  } else {
    yTitle = metric.text
  }

  return {
    height: containerHeight,
    tooltip: {
      groupLabel: '',
    },
    toolbar: {
      enabled: false,
    },
    legend: {
      truncation: {
        numCharacter: 46,
        threshold: 1,
        type: 'one',
      },
      position: LegendPositions.BOTTOM,
    },
    data: {
      loading: false,
    },
    resizable: true,
    timeScale: {
      addSpaceOnEdges: 0,
    },
    axes: {
      bottom: {
        mapsTo: 'date',
        title: 'Date',
        scaleType: ScaleTypes.TIME,
        ticks: {
          number: 4,
          rotation: TickRotations.AUTO,
        },
      },
      left: {
        mapsTo: metric.id,
        title: yTitle,
        scaleType: ScaleTypes.LINEAR,
        includeZero: true,
      },
    },
    curve: 'curveMonotoneX',
  }
}

export const computeRuntimeScale = (items: PreparedHistoryItem[]) => {
  let scale = runtimeScales[0]
  items.forEach((item) => {
    if (item.runtimeInSeconds > 240 * 3600) {
      scale = runtimeScales[1]
    }
  })
  return scale
}

export const download = () => {
  api.getMyProjectHistoryForDownload()
}
