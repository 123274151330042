import React, {Fragment} from 'react'
import {Link} from 'carbon-components-react'
import content from '../../../content'
import {MainContentWrapper} from '../../../../../components'

const HowItWorksSection = () => {
  const section = content.howItWorksSection
  const subsections = section.subsections
  return (
    <MainContentWrapper
      expand
      header={
        <>
          <h5 className="main-content-wrapper__header main-content-wrapper__header_margin">{section.title}</h5>
          <Link href={section.link.src}>{section.link.text}</Link>
        </>
      }
      className="how-it-works-section"
    >
      <div className="content-wrapper bx--grid bx--no-gutter">
        <div className="bx--row">
          <h2 className="section-title bx--col-lg-11">{section.subtitle}</h2>
        </div>
        {subsections.map(({iconSrc, title, text}, index) => {
          return (
            <Fragment key={index}>
              <div className="subsection bx--row">
                <div className="bx--col-lg-11">
                  <div className="bx--grid bx--no-gutter--left">
                    <div className="bx--row">
                      <div className="subsection-image  bx--col-md-2 bx--col-lg-4 bx--col-sm-4">
                        <img alt="" src={iconSrc} className="pictogram" />
                      </div>
                      <div className="subsection-description bx--col-md-6 bx--col-lg-12 bx--col-sm-4 bx--no-gutter--right">
                        <h5 className="subsection-description__title">{title}</h5>
                        <p className="subsection-description__text"> {text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )
        })}
      </div>
    </MainContentWrapper>
  )
}

export default HowItWorksSection
