import React, {FunctionComponent} from 'react'
import {OrderedList, ListItem} from 'carbon-components-react'

import {ContentHeader, ContentText, Paragraph} from '../../../index'
import {CodeSnippet, Link} from '../../../../../../../components'
import {internal} from '../../../../../../../constants'

const Raspberry: FunctionComponent = () => {
  return (
    <>
      <ContentHeader>Download for Raspberry Pi</ContentHeader>
      <ContentText>We recommend installing the BOINC client directly from the Raspberry Pi repositories.</ContentText>
      <OrderedList className="ordered-list_code-snippet">
        <ListItem>
          <Paragraph>In a terminal window, run the following command:</Paragraph>
          <CodeSnippet className="code-snippet">sudo apt install boinc-client boinc-manager</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>Set the BOINC client to automatically start after you restart your computer:</Paragraph>
          <CodeSnippet className="code-snippet">sudo systemctl enable boinc-client</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>Start the BOINC client:</Paragraph>
          <CodeSnippet className="code-snippet">sudo systemctl start boinc-client</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>Allow group access to client access file:</Paragraph>
          <CodeSnippet className="code-snippet">sudo chmod g+r /var/lib/boinc-client/gui_rpc_auth.cfg</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>Add your Linux user to the BOINC group to allow the BOINC Manager to communicate with the BOINC client:</Paragraph>
          <CodeSnippet className="code-snippet">sudo usermod -a -G boinc $USER</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>Allow your terminal to pick up the privileges of the new group:</Paragraph>
          <CodeSnippet className="code-snippet">exec su $USER</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>In the same terminal window, start the BOINC Manager:</Paragraph>
          <CodeSnippet className="code-snippet">boincmgr -d /var/lib/boinc-client</CodeSnippet>
        </ListItem>
        <ListItem>
          <Paragraph>
            When BOINC Manager opens, select World Community Grid from the list of BOINC projects then enter your World Community Grid username and password.
          </Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph>
            When these steps are completed, you should see a screen to confirm that you&apos;ve been successfully signed up to World Community Grid.
          </Paragraph>
        </ListItem>
      </OrderedList>
      <p>
        You have downloaded the Ubuntu/Debian software.{' '}
        <Link underlined initialSize href={internal.Download.url}>
          Download for other platforms
        </Link>
        .
      </p>
    </>
  )
}

export default Raspberry
