import React, {FunctionComponent} from 'react'
import cn from 'classnames'
import {SkeletonText, TooltipIcon} from 'carbon-components-react'

interface PlaceholderProps {
  className?: string
}

interface BadgeProps {
  icon: any
  className?: string
  level: string | number
  color?: string
  tooltipText?: string
}

const Skeleton: FunctionComponent<PlaceholderProps> = ({className, ...rest}) => {
  return (
    <div className={cn('badge', className)} style={{backgroundColor: 'transparent'}} {...rest}>
      <div className="badge-content">
        <div className="badge-icon">
          <SkeletonText className="badge-icon-skeleton" />
        </div>
      </div>
    </div>
  )
}

const Badge: FunctionComponent<BadgeProps> & {
  Skeleton: typeof Skeleton
} = ({icon, className, level, color, tooltipText, ...rest}) => {
  return tooltipText ? (
    <TooltipIcon className="tooltip-width" tooltipText={tooltipText || ''}>
      <div className={cn('badge', className)} style={{backgroundColor: 'transparent'}} {...rest}>
        <div className="badge-content">
          <div className="badge-icon">{icon ? <img src={icon} alt="" /> : null}</div>
        </div>
      </div>
    </TooltipIcon>
  ) : (
    <div className={cn('badge', className)} style={{backgroundColor: 'transparent'}} {...rest}>
      <div className="badge-content">
        <div className="badge-icon">{icon ? <img src={icon} alt="" /> : null}</div>
      </div>
    </div>
  )
}

Badge.Skeleton = Skeleton

export default Badge
