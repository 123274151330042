import React, {FunctionComponent, useEffect, useState} from 'react'
import _get from 'lodash.get'
import {Section} from '../../layout/Section'
import {Grid} from '../../layout/Grid'
import {Row} from '../../layout/Row'
import {GridElement} from '../../layout/GridElement'
import {DevicesCard} from '../../cards/DevicesCard'
import {BadgesCard} from '../../cards/BadgesCard'
import {StatCard} from '../../cards/StatCard'
import {dateDiff} from '../../../../../../utils'
import {convertMillisecondsToNormalFormat, convertMillisecondsToShortFormat, convertToScore} from '../../../helpers'
import {api} from '../../../../../../services'
import {CalendarCard} from '../../cards'

export interface MyActivitySectionProps {
  stats: any
}

const getLongestStreak = (streaksInMilliseconds: number[]) =>
  streaksInMilliseconds.length === 0 ? undefined : streaksInMilliseconds.reduce((prev, next) => (prev > next ? prev : next), 0)

const getDisplayOfStreaks = (streaks: any): any => {
  const allStreaksList: any = _get(streaks, 'all.items', [])
  const streaksInMilliseconds = allStreaksList.map((streak: any) => dateDiff.inMillisecondsFromString(streak.firstDate, streak.lastDate))
  return streaks
    ? {
        currentStreakShort: convertMillisecondsToShortFormat(
          dateDiff.inMillisecondsFromString(_get(streaks, 'current.firstDate'), _get(streaks, 'current.lastDate')),
        ),
        streakPercent: convertToScore(_get(streaks, 'latest.rankPercent')),
        longestStreak: convertMillisecondsToNormalFormat(getLongestStreak(streaksInMilliseconds)),
      }
    : {}
}

const MyActivitySection: FunctionComponent<MyActivitySectionProps> = ({stats}) => {
  const [streaks, setStreaks] = useState<any>()
  useEffect(() => {
    const getData = async () => {
      const [{data: all}, {data: latest}, {data: current}] = await Promise.all([api.getStreak(), api.getStreakLongest(), api.getStreakCurrent()])
      setStreaks({
        all,
        latest,
        current,
      })
    }
    getData()
  }, [])

  const display = getDisplayOfStreaks(streaks)
  if (display === undefined) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>
  }
  return (
    <Section
      title={
        <Section.Header>
          <Section.Title>My activity</Section.Title>
        </Section.Header>
      }
    >
      <Grid>
        <Row>
          <GridElement lg={2} md={1} sm={2}>
            <CalendarCard stats={stats} />
          </GridElement>
          <GridElement lg={1} md={1} sm={2}>
            <StatCard
              title="Current streak"
              text={`${display.currentStreakShort}`}
              tooltipContent="This is the number of consecutive days you've contributed computing time to World Community Grid without a break."
              footerText={`Longest streak: ${display.longestStreak} `}
              rating={display.streakPercent && `${display.streakPercent}%`}
            />
          </GridElement>
        </Row>
        <Row>
          <GridElement lg={1} md={1} sm={2}>
            <DevicesCard />
          </GridElement>
          <GridElement lg={2} md={1} sm={2}>
            <BadgesCard stats={stats} />
          </GridElement>
        </Row>
      </Grid>
    </Section>
  )
}

export default MyActivitySection
