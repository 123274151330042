export const metrics = [
  {
    id: 'runtime',
    text: 'Runtime',
  },
  {
    id: 'results',
    text: 'Results',
  },
  {
    id: 'points',
    text: 'Points',
  },
]

export const timeFrames = [
  {
    id: 'last-90-days',
    text: 'Last 90 days',
  },
  {
    id: 'last-year',
    text: 'Last year',
  },
  {
    id: 'last-5-years',
    text: 'Last 5 years',
  },
  {
    id: 'all-time',
    text: 'All-time',
  },
]

export const runtimeScales = [
  {
    id: 'hour',
    text: 'hours',
    scale: 3600,
  },
  {
    id: 'day',
    text: 'days',
    scale: 24 * 3600,
  },
]
