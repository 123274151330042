import React, {FunctionComponent} from 'react'

import {convertNumberToSIFormat, formatPoints, formatResults, formatRuntime} from '../../../../../../utils'
import {convertSecondsToShortFormat, convertToScore} from '../../../helpers'
import {MemberStatsYesterday} from '../../../../../../services/api/types/member-api'

import {GridElement} from '../../layout/GridElement'
import {StatCard} from '../../cards/StatCard'

export interface ComponentProps {
  selectedStat?: MemberStatsYesterday
}

const getDisplayOfMyContributionRow = (selectedStat: MemberStatsYesterday | undefined) => {
  return selectedStat
    ? {
        results: selectedStat.results !== undefined && formatResults(selectedStat.results),
        resultsShort: (selectedStat.results !== undefined && convertNumberToSIFormat(+selectedStat.results)) || '0',
        resultsPercent: convertToScore(selectedStat.resultsRankPercent) || '',
        points: selectedStat.points !== undefined && formatPoints(selectedStat.points),
        pointsShort: (selectedStat.points !== undefined && convertNumberToSIFormat(+selectedStat.points)) || '0',
        pointsPercent: convertToScore(selectedStat.pointsRankPercent) || '',
        yyddhhammss: (selectedStat.runtimeInSeconds !== undefined && formatRuntime(selectedStat.runtimeInSeconds)) || '',
        yyddhhmmssShort: selectedStat.runtimeInSeconds !== undefined && convertSecondsToShortFormat(selectedStat.runtimeInSeconds),
        yyddhhmmssPercent: convertToScore(selectedStat.runtimeRankPercent) || '',
      }
    : {}
}

const MyContributionSection: FunctionComponent<ComponentProps> = ({selectedStat}) => {
  const display = getDisplayOfMyContributionRow(selectedStat)

  if (display === undefined) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>
  }
  return (
    <>
      <GridElement>
        <StatCard
          title="Runtime"
          text={`${display.yyddhhmmssShort}`}
          tooltipContent="This is the total amount of run time your devices have contributed to World Community Grid"
          footerText={`${display.yyddhhammss}`}
          rating={display.yyddhhmmssPercent && `${display.yyddhhmmssPercent}%`}
        />
      </GridElement>
      <GridElement>
        <StatCard
          title="Results returned"
          text={`${display.resultsShort}`}
          tooltipContent="This is the number of research results you have returned to World Community Grid"
          footerText={`${display.results} results returned`}
          rating={display.resultsPercent && `${display.resultsPercent}%`}
        />
      </GridElement>
      <GridElement>
        <StatCard
          title="Points earned"
          text={`${display.pointsShort}`}
          tooltipContent="This is the number of points your donated computing time has earned on World Community Grid."
          footerText={`${display.points} points earned`}
          rating={display.pointsPercent && `${display.pointsPercent}%`}
        />
      </GridElement>
    </>
  )
}

export default MyContributionSection
