import React, {FunctionComponent} from 'react'
import {DataTable, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow} from 'carbon-components-react'
import {OwnedResult} from '../../../../services/api/types'
import {headerType, TableProps} from '../../Results/components/ResultsTable/types'
import {ResultCell} from '../../Results/components'
import {resultsToDataRow} from '../../Results/components/ResultCell/ResultCell'

interface WorkunitTableProps {
  results: OwnedResult[]
}

const headers: headerType[] = [
  {
    key: 'resultName',
    header: 'Result name',
  },
  {
    key: 'os',
    header: 'OS type',
  },
  {
    key: 'osVersion',
    header: 'OS version',
  },
  {
    key: 'status',
    header: 'Status',
  },
  {
    key: 'sentTime',
    header: 'Sent time',
  },
  {
    key: 'dueTime',
    header: 'Time due/ Return time',
  },
  {
    key: 'cpuHours',
    header: 'Cpu time/ Elapsed time',
  },
  {
    key: 'claimedCredit',
    header: 'Claimed credit/ Granted credit',
  },
]

const ownedRow = (id: string, items: OwnedResult[]) => {
  const intId = parseInt(id, 10)
  const item = items.find((item) => item.resultId === intId)
  if (item && item.owned) {
    return 'workunit-table-owned-row'
  }
  return ''
}

const WorkunitTable: FunctionComponent<WorkunitTableProps> = ({results}) => {
  return (
    <DataTable headers={headers} rows={results.map((item) => resultsToDataRow(item))}>
      {({rows, headers, getHeaderProps, getRowProps, getTableContainerProps}: TableProps) => (
        <TableContainer {...getTableContainerProps()}>
          <Table size="compact">
            <TableHead>
              <TableRow>
                {headers.map((header: headerType) => (
                  <TableHeader key={header.key} {...getHeaderProps({header})}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: {id: string; cells: any[]}) => (
                <TableRow className={ownedRow(row.id, results)} key={row.id} {...getRowProps({row})}>
                  {row.cells.map((cell) => (
                    <TableCell className="workunit-allow-wrap" key={cell.id}>
                      <ResultCell rowId={row.id} cell={cell} results={results} isWorkunitTable={true} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  )
}

export default WorkunitTable
