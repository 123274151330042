import React, {FunctionComponent} from 'react'
import cn from 'classnames'

export interface RowProps {
  className?: string
}

const Row: FunctionComponent<RowProps> = ({children, className, ...rest}) => {
  return (
    <div className={cn('bx--row', className)} {...rest}>
      {children}
    </div>
  )
}

export default Row
