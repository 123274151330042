import {useEffect} from 'react'
import {useSelector} from 'react-redux'

import TagManager from 'react-gtm-module'
import Cookies from 'js-cookie'

import {RootState} from '../../store'
import {UserState} from '../../store/user/types'
import {Analytics} from '../../services/api/types'
import {api} from '../../services'
import {isServerSideRender} from '../../utils'
import {userSelector} from '../../store/selectors'
import {Datalayer} from './types'

const WCG_GTM_ID = 'GTM-P7SR87'
const OPT_OUT_COOKIE_NAME = 'opt-out'

const hasOptOutCookie = () => {
  const optOut = Cookies.get(OPT_OUT_COOKIE_NAME)
  return optOut && optOut === '1'
}

const isDoNotTrackBrowserSettingEnabled = () => {
  if (navigator.doNotTrack || window.doNotTrack) {
    return window.doNotTrack === '1' || navigator.doNotTrack === '1' || navigator.doNotTrack === 'yes'
  } else {
    return false
  }
}

const doNotTrack = () => {
  if (isServerSideRender()) {
    return true
  } else if (isDoNotTrackBrowserSettingEnabled()) {
    return true
  } else if (hasOptOutCookie()) {
    return true
  }
  return false
}

const initializeTagManager = () => {
  if (doNotTrack()) {
    return
  }
  //this person has not disabled tracking
  const tagManagerArgs = {
    gtmId: WCG_GTM_ID,
  }
  TagManager.initialize(tagManagerArgs)
}

const updateDataLayer = (analytics: Analytics) => {
  if (doNotTrack()) {
    return
  }
  const datalayer: Datalayer = {}
  if (typeof analytics !== 'undefined') {
    if (analytics.userStatus !== undefined) {
      // eslint-disable-next-line babel/camelcase
      datalayer.user_status = analytics.userStatus
    }
    if (analytics.id !== undefined) {
      // eslint-disable-next-line babel/camelcase
      datalayer.analytics_id = analytics.id
    }
  }
  TagManager.dataLayer({dataLayer: datalayer})
}

const updateAnalytics = () => {
  api
    .getAnalytics()
    .then((response) => response && response.data)
    .then((analytics: Analytics) => updateDataLayer(analytics))
}

const GoogleTagManager = () => {
  //setup tag manager
  useEffect(initializeTagManager, [])

  //watch for changes in login status
  const user = useSelector<RootState, UserState>(userSelector)
  useEffect(updateAnalytics, [user.loggedIn])

  return null
}

export default GoogleTagManager
