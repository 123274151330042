import {Reducer} from 'redux'
import {ProjectsState, ProjectActions, ACTION_TYPE} from './types'

const initialState = {
  projects: [],
  prismicProjects: [],
}

export const projectsReducer: Reducer<ProjectsState, ProjectActions> = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SET_PROJECTS:
      return {...state, projects: action.payload}
    case ACTION_TYPE.SET_PRISMIC_PROJECTS:
      return {
        ...state,
        projects:
          state.projects &&
          state.projects.map((project: any) => {
            const prismicProject = action.payload.find((prismic: any) => prismic.short_name === project.shortName)
            return prismicProject ? {...project, prismic: prismicProject} : project
          }),
      }
    default:
      return state
  }
}
