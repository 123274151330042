import React from 'react'
import {RatioImageComponent} from './types'

const RatioImage: RatioImageComponent = ({url}) => {
  return (
    <div className="image-container">
      <div className="bx--aspect-ratio bx--aspect-ratio--16x9">
        <div className="bx--aspect-ratio--object ratio-object">{<img className="ratio-image" src={url} alt="" />}</div>
      </div>
    </div>
  )
}

export default RatioImage
