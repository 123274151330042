import React, {FunctionComponent, useEffect} from 'react'
import {Redirect, Route, RouteProps, useLocation} from 'react-router-dom'
import {To} from 'history'
import {useDispatch, useSelector} from 'react-redux'
import {setLoginRedirect} from '../../store/loginRedirect/actions'
import {RootState} from '../../store'
import {loggedInSelector} from '../../store/selectors'

export interface PrivateRouteProps extends RouteProps {
  isPassing: boolean
  redirectTo: To
}

const defaultProps = {}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({isPassing, path, redirectTo, ...rest}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const loggedIn = useSelector<RootState, boolean>(loggedInSelector)
  useEffect(() => {
    if (!loggedIn && !isPassing && path === location.pathname) {
      //save intended location for redirect after login
      if (location.search && location.search !== '') {
        dispatch(setLoginRedirect({location: location.pathname + location.search}))
      } else {
        dispatch(setLoginRedirect({location: location.pathname}))
      }
    }
  }, [loggedIn, isPassing, path, location.pathname, location.search])
  return isPassing ? <Route path={path} {...rest} /> : <Redirect to={redirectTo ? redirectTo : '/'} />
}

PrivateRoute.defaultProps = defaultProps

export default PrivateRoute
