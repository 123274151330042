import React, {FunctionComponent} from 'react'
import classNames from 'classnames'

interface TabBarNavItemProps {
  navLabel: string
  className: string
  onChangeActiveTab?: (label: string) => void
}

const defaultProps = {
  navLabel: 'Tab',
  className: '',
}

const TabBarNavItem: FunctionComponent<TabBarNavItemProps> = ({navLabel, className, onChangeActiveTab}) => {
  const classes = classNames(className, 'tab-bar-nav-item')
  const handleClick = () => {
    onChangeActiveTab && onChangeActiveTab(navLabel)
  }
  return (
    <button type="button" className={classes} onClick={handleClick}>
      {navLabel}
    </button>
  )
}

TabBarNavItem.defaultProps = defaultProps

export default TabBarNavItem
