import _get from 'lodash.get'
import React, {forwardRef, FunctionComponent, RefObject, useEffect, useRef} from 'react'
import cn from 'classnames'

interface Props {
  className?: string
  children?: any
}

interface BadgesContainerProps {
  onResize: (childrenWidth: number, containerWidth: number, firstChildWidth: number, childrenCount: number) => void
}

const BadgesContainerElement: FunctionComponent<Props> = ({children, className}) => {
  return <div className={cn('badges-container-element', className)}>{children}</div>
}

const BadgesContainerColumn: FunctionComponent<Props> = forwardRef(({children, className}, ref: any) => {
  return (
    <div ref={ref} className={cn('badges-container-column', className)}>
      {children}
    </div>
  )
})

const withSetTimeout = (callback: any, delay: number) => {
  let resizeTimeout: any
  return (e: any) => {
    e.stopPropagation()
    if (resizeTimeout) {
      clearTimeout(resizeTimeout)
    }
    resizeTimeout = setTimeout(() => {
      callback()
    }, delay)
  }
}

const getSumWidth = (refsArray: any[]) => {
  return refsArray.reduce((sum: any, ref: any) => {
    return ref ? sum + ref.offsetWidth : sum
  }, 0)
}

const BadgesContainer: FunctionComponent<Props & BadgesContainerProps> & {
  Element: typeof BadgesContainerElement
  Column: typeof BadgesContainerColumn
} = ({className, children, onResize, ...rest}) => {
  const containerRef: RefObject<any> = useRef(null)
  const childrenRefs: RefObject<any> = useRef([])

  const resizeCallback = () => {
    const _childrenWidth = childrenRefs.current && getSumWidth(childrenRefs.current)
    const horizontalPaddings = 32
    const _containerWidth = containerRef.current.clientWidth - horizontalPaddings
    const _firstChildWidth = _get(childrenRefs, 'current[0].offsetWidth', 0)
    const _childrenCount = childrenRefs.current && childrenRefs.current.length

    onResize(_childrenWidth, _containerWidth, _firstChildWidth, _childrenCount)
  }

  useEffect(() => {
    const delayTime = 50
    const callbackWithSetTimeout = withSetTimeout(resizeCallback, delayTime)
    window.addEventListener('resize', callbackWithSetTimeout, false)
    return () => {
      window.removeEventListener('resize', callbackWithSetTimeout)
    }
  }, [])

  const removeNullFromRefs = (refs: any) => {
    refs = refs.filter((ref: any) => ref)
  }

  useEffect(() => {
    removeNullFromRefs(childrenRefs.current)
    resizeCallback()
  })

  return (
    <div ref={containerRef} className={cn('badges-container', className)} {...rest}>
      {React.Children.map(children, (child, index) => {
        //@ts-ignore
        return React.cloneElement<any>(child, {
          ref: (ref: any) => {
            childrenRefs.current[index] = ref
          },
        })
      })}
    </div>
  )
}

BadgesContainer.Element = BadgesContainerElement
BadgesContainer.Column = BadgesContainerColumn

export default BadgesContainer
