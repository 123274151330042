import React, {FunctionComponent, useEffect} from 'react'
import {PrivateRoute} from '../../../../../components/PrivateRoute'
import {PrivateRouteProps, ProgressPrivateRouteProps} from './types'

const ProgressPrivateRoute: FunctionComponent<ProgressPrivateRouteProps & PrivateRouteProps> = ({progressIndex, setStep, ...rest}) => {
  useEffect(() => {
    setStep(progressIndex)
  }, [setStep, progressIndex])

  return <PrivateRoute {...rest} />
}

export default ProgressPrivateRoute
