import React, {FunctionComponent} from 'react'
import {internal} from '../../../../../constants/urls'
import {Link} from '../../../../../components/Link'

const JoinedMilestoneContent: FunctionComponent = () => {
  return (
    <>
      <h4>Joined World Community Grid</h4>
      <p>
        Welcome! You can customize your account settings <Link href={internal['Profile and settings'].url}>here</Link>.
      </p>
    </>
  )
}

export default JoinedMilestoneContent
