import {DailyStatsItem} from '../../../../../../services/api/types'
import {api} from '../../../../../../services'
import {CalendarItem} from './types'

export const getCalendarItem = (item: DailyStatsItem): CalendarItem => {
  //In order to get the date to render correctly in the end user timezone, we need to append the time
  const localDate = `${item.date}T00:00:00.000`
  return {
    date: new Date(localDate),
    count: item.runtimeInSeconds,
  }
}

export const getCalendarItemsFromHistoryItems = (historyStats: DailyStatsItem[]): CalendarItem[] =>
  historyStats && historyStats.map((historyStat) => getCalendarItem(historyStat))

export const download = () => {
  api.getStatsHistoryForDownload()
}
