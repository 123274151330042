import React from 'react'
import _find from 'lodash.find'
import _get from 'lodash.get'
import {Button} from 'carbon-components-react'
import {ArrowRight20, Download20} from '@carbon/icons-react'
import {useHistory} from 'react-router-dom'
import {api} from '../../../../../services'
import {NextButtonComponentProps} from './types'

const getClientUrl = (clientsData: any, platform: any) => {
  const client = _find(clientsData, (client) => client.platform === platform)
  return _get(client, 'url')
}
const downloadClient = (url: any) => {
  window.open(url, '_self')
}
const tryToDownloadClient = async (platform: any) => {
  const {data: clients} = await api.getClients()
  const url = getClientUrl(clients, platform)
  url && downloadClient(url)
}

const NextButton: NextButtonComponentProps = ({platformNameToDownload, redirectTo, isDownloadableView, disabled, pathState, ...rest}) => {
  const history = useHistory()
  return (
    <Button
      {...rest}
      renderIcon={isDownloadableView ? Download20 : ArrowRight20}
      disabled={disabled}
      onClick={() => {
        platformNameToDownload && tryToDownloadClient(platformNameToDownload)
        history.push(redirectTo, pathState)
      }}
    >
      {isDownloadableView ? 'Download App' : 'Next'}
    </Button>
  )
}

export default NextButton
