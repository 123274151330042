import React, {FunctionComponent, ReactNode} from 'react'
import {InlineNotification} from 'carbon-components-react'
import {v4 as uuid} from 'uuid'
import cn from 'classnames'
interface Notification {
  text: ReactNode | HTMLElement
  id: string
}
interface NotificationsListProps {
  data: Array<Notification>
  className?: string
}

const defaultProps = {}

const NotificationsList: FunctionComponent<NotificationsListProps> = ({data, className}) => {
  return (
    <div className={cn('inline-notifications-list', className)}>
      {data &&
        data.map((item) => {
          return (
            <InlineNotification
              className="inline-notification__join"
              key={item.id || uuid()}
              kind="error"
              lowContrast={true}
              iconDescription="Dismiss notification"
              subtitle=""
              title={item.text || ''}
            />
          )
        })}
    </div>
  )
}

NotificationsList.defaultProps = defaultProps

export default NotificationsList
