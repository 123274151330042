import React, {FunctionComponent} from 'react'
import {Route, RouteProps} from 'react-router'
import {ProgressPrivateRouteProps} from './types'

const ProgressRoute: FunctionComponent<ProgressPrivateRouteProps & RouteProps> = ({progressIndex, setStep, ...rest}) => {
  setStep(progressIndex)
  return <Route {...rest} />
}

export default ProgressRoute
