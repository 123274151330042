import React, {FunctionComponent, useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {Grid, Row, GridElement} from '../MyContribution/components/layout'
import {PageHeader} from '../MyContribution/components/layout/headers/PageHeader'

import {WithContextMenuWrapper, BaseLayout} from '../../../layouts'
import {PageMetadata} from '../../../components/PageMetadata'
import {metadata} from '../../../constants/metadata'
import {setNavigation} from '../../../store/navigation/actions'

import {ResultsTable} from './components'

const Results: FunctionComponent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavigation({section: 'My contribution', type: 'main', highlight: 'Results'}))
  }, [])

  return (
    <BaseLayout>
      <PageMetadata {...metadata.contribution.results} />
      <WithContextMenuWrapper>
        <PageHeader>Results</PageHeader>
        <Grid>
          <Row>
            <GridElement noMinHeight lg={3} md={2} sm={2}>
              <ResultsTable />
            </GridElement>
          </Row>
        </Grid>
      </WithContextMenuWrapper>
    </BaseLayout>
  )
}
export default Results
