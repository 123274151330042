export interface LoginRedirectState {
  location: string
}
export enum ACTION_TYPE {
  SET_LOGIN_REDIRECT = 'SET_LOGIN_REDIRECT',
  DEL_LOGIN_REDIRECT = 'DEL_LOGIN_REDIRECT',
}

export interface SetLoginRedirectAction {
  type: typeof ACTION_TYPE.SET_LOGIN_REDIRECT
  payload: LoginRedirectState
}

export interface DelLoginRedirectAction {
  type: typeof ACTION_TYPE.DEL_LOGIN_REDIRECT
}

export type LoginRedirectActions = SetLoginRedirectAction | DelLoginRedirectAction
