import React, {FunctionComponent} from 'react'
import cn from 'classnames'

export interface GridElementProps {
  className?: string
  lg?: 1 | 2 | 3
  md?: 1 | 2
  sm?: 1 | 2
  withGutterLeftMdToLg?: boolean
  noGutterRightMdToLg?: boolean
  noGutterRightLg?: boolean
  noMinHeight?: boolean
}

const GridElement: FunctionComponent<GridElementProps> = ({
  children,
  lg,
  md,
  sm,
  className,
  withGutterLeftMdToLg,
  noGutterRightMdToLg,
  noGutterRightLg,
  noMinHeight,
  ...rest
}) => {
  return (
    <div
      className={cn(
        'grid-element',
        `grid-element-lg-${lg}`,
        `grid-element-md-${md}`,
        `grid-element-sm-${sm}`,
        withGutterLeftMdToLg && 'with-gutter-left-md-to-lg',
        noGutterRightMdToLg && 'no-gutter-right-md-to-lg',
        noGutterRightLg && 'no-gutter-right-lg',
        noMinHeight && 'no-min-height',
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

GridElement.defaultProps = {
  lg: 1,
  md: 1,
  sm: 2,
}

export default GridElement
