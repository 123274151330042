import _find from 'lodash.find'
import {Duration} from 'luxon'
import {History, Location} from 'history'
import {FULL_URL_PATTERN, internal} from '../constants'
import {Project} from '../services/api/types'

interface DateParts {
  month?: string
  day?: string
  year?: string
}

interface TimeParts {
  hour?: string
  minute?: string
  second?: string
}

const secondsFormat = new Intl.NumberFormat('en-US', {maximumFractionDigits: 2})
const pointsFormat = new Intl.NumberFormat('en-US', {maximumFractionDigits: 1})
const numberFormat = new Intl.NumberFormat('en-US')
const dateFormatter = Intl.DateTimeFormat('en-US', {month: 'short', day: 'numeric', year: 'numeric', timeZone: 'UTC'})
const longDateFormatter = Intl.DateTimeFormat('en-US', {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
  timeZone: 'UTC',
})
const timeFormatter = Intl.DateTimeFormat('en-US', {
  hour12: false,
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZone: 'UTC',
})
const isoDateFormatter = Intl.DateTimeFormat('en-US', {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
  timeZone: 'UTC',
})

export const getIntegerPartLength = (number: number | string): number => {
  return Math.trunc(+number).toString().length
}

export const formatResults = (results: number) => {
  return numberFormat.format(results)
}

export const formatPoints = (points: number) => {
  return numberFormat.format(points)
}

export const formatRuntime = (cpuSecs: number) => {
  return Duration.fromObject({seconds: +cpuSecs}).toFormat('y:ddd:hh:mm:ss')
}

export const formatResultHours = (cpuHours: number) => {
  return secondsFormat.format(cpuHours)
}

export const formatResultPoints = (points: number) => {
  return pointsFormat.format(points)
}

const formatDate = (date: Date) => {
  const parts: DateParts = {}
  dateFormatter.formatToParts(date).forEach((item) => {
    parts[item.type] = item.value
  })
  return `${parts.month}. ${parts.day}, ${parts.year}`
}

const formatLongDate = (date: Date) => {
  const parts: DateParts = {}
  longDateFormatter.formatToParts(date).forEach((item) => {
    parts[item.type] = item.value
  })
  return `${parts.month} ${parts.day}, ${parts.year}`
}

export const formatISODate = (date: Date) => {
  const parts: DateParts = {}
  isoDateFormatter.formatToParts(date).forEach((item) => {
    parts[item.type] = item.value
  })
  return `${parts.year}-${parts.month?.padStart(2, '0')}-${parts.day?.padStart(2, '0')}`
}

export const formatISOTime = (time: Date) => {
  const parts: TimeParts = {}
  timeFormatter.formatToParts(time).forEach((item) => {
    parts[item.type] = item.value
  })
  return `${parts.hour === '24' ? '0' : parts.hour}:${parts.minute?.padStart(2, '0')}:${parts.second?.padStart(2, '0')}`
}

export const formatTime = (time: Date) => {
  const parts: TimeParts = {}
  timeFormatter.formatToParts(time).forEach((item) => {
    parts[item.type] = item.value
  })
  return `${parts.hour === '24' ? '0' : parts.hour}:${parts.minute?.padStart(2, '0')}`
}

export const formatDatetime = (datetime: Date) => {
  return `${formatDate(datetime)} - ${formatTime(datetime)} UTC`
}

export const formatDateFromString = (date: string) => {
  return formatDate(new Date(date))
}

export const formatDateFromTime = (time: number) => {
  return formatDate(new Date(time))
}

export const formatISODateFromTime = (time: number) => {
  return formatISODate(new Date(time))
}

export const formatISODateTimeFromTime = (time: number) => {
  return `${formatISODate(new Date(time))}  ${formatISOTime(new Date(time))} UTC`
}

export const formatISODateTimeFromTime2 = (time: string) => {
  return `${formatISODate(new Date(time))} \n${formatISOTime(new Date(time))} UTC`
}

export const formatISODateFromDate = (date: Date) => formatISODate(date)

export const formatDatetimeFromString = (datetime: string) => {
  return formatDatetime(new Date(datetime))
}

export const formatLongDateFromString = (date: string) => {
  return formatLongDate(new Date(date))
}

export const getDateDaysFromNow = (days: number): Date => {
  return new Date(Date.now() - days * 24 * 60 * 60 * 1000)
}

export const getDateMonthsFromNow = (months: number) => {
  const date = new Date()
  return new Date(date.setMonth(date.getMonth() - months))
}

export const getDateYearsFromNow = (years: number) => {
  const date = new Date()
  return new Date(date.setFullYear(date.getFullYear() - years))
}

export const getMinDateFromStringArray = (dates: string[]): string => {
  const min = new Date(
    Math.min.apply(
      null,
      dates.map<any>((date: string) => new Date(date)),
    ),
  )
  return formatISODate(min)
}

export const getMaxDateFromStringArray = (dates: string[]): string => {
  const min = new Date(
    Math.max.apply(
      null,
      dates.map<any>((date: string) => new Date(date)),
    ),
  )
  return formatISODate(min)
}

export const dateDiff = {
  inDaysFromString: (startDate: string, endDate: string): number | undefined => {
    if (startDate && endDate) {
      const start: any = new Date(startDate)
      const end: any = new Date(endDate)
      const diffInDays = Math.floor((end - start) / (1000 * 60 * 60 * 24))
      return diffInDays
    }
  },
  inMillisecondsFromString: (startDate: string, endDate: string): number | undefined => {
    if (startDate && endDate) {
      const start: any = new Date(startDate)
      const end: any = new Date(endDate)
      const diffInMilliseconds = Math.floor(end - start)
      return diffInMilliseconds
    }
  },
}

export const convertNumberToSIFormat = (number: number) => {
  const ranges = [
    {divider: 1e9, suffix: 'G'},
    {divider: 1e6, suffix: 'M'},
    {divider: 1e3, suffix: 'k'},
    {divider: 1, suffix: ''},
  ]
  for (let i = 0; i < ranges.length; i++) {
    if (number >= ranges[i].divider) {
      const stringNumber = (number / ranges[i].divider).toString()
      const numberOfIntegerDigits = getIntegerPartLength(stringNumber)
      const truncatedNumber = numberOfIntegerDigits >= 3 || numberOfIntegerDigits === 1 ? stringNumber.slice(0, 3) : stringNumber.slice(0, 2)
      return `${truncatedNumber}${ranges[i].suffix}`
    }
  }
}

export const convertBadgeTimeToDaysOrYearString = (secs: number): string => {
  if (secs >= 3600 * 24 * 365) {
    return `${secs / (3600 * 24 * 365)} year`
  } else {
    return `${secs / (3600 * 24)} day`
  }
}

export const convertRecruitingBadgeLevel = (level: number): string => {
  switch (level) {
    case 0:
      return '1 person'
    case 1:
      return '5 person'
    case 2:
      return '10 person'
    case 3:
      return '25 person'
    case 4:
      return '100 person'
    default:
      return 'Many person'
  }
}

export const isString = (value: any): boolean => typeof value === 'string'

export const isObjectOrArray = (value: any) => (typeof value === 'object' && value !== null) || Array.isArray(value)

export const hasStringValueDeepInCollection = <T = Record<string, unknown>>(
  collection: T | ArrayLike<T> | null | undefined,
  checkedKey: string,
  searchingString: string,
): boolean =>
  _find<any>(collection, (value: any) => {
    return isObjectOrArray(value) ? hasStringValueDeepInCollection(value, checkedKey, searchingString) : isString(value) && value === searchingString
  }) !== undefined

export const findObjectByStringInside = <T = Record<string, unknown>>(
  collection: T[] | null | undefined,
  checkedKey: string,
  searchingString: string,
): T | undefined => _find<T>(collection, (obj: T) => hasStringValueDeepInCollection<T>(obj, checkedKey, searchingString))

export const isServerSideRender = () => {
  return navigator.userAgent === 'serverSideRender'
}

export const getHost = () => {
  if (isServerSideRender()) {
    // this variable is set by puppeteer when doing ssr
    // eslint-disable-next-line
    // @ts-ignore
    return serverSideHost
  }
  return window.location.host
}

const isFullUrl = (str: string) => {
  const regExp = new RegExp(FULL_URL_PATTERN, 'i')
  return regExp.test(str)
}

export const checkIfUsualLink = (url: string, location?: Location): boolean => {
  return (
    ['/forums', '.do', '.s', '.action', '.jsp', '.t', internal['Log Out'].url].some(
      (stringPart) => (location && location.pathname && location.pathname.includes(stringPart)) || (url && url.includes(stringPart)),
    ) || isFullUrl(url)
  )
}

export const goByUrl = (url: string, browserHistory: History): void => {
  const isUsualLink = checkIfUsualLink(url, browserHistory.location)
  isUsualLink ? document.location.assign(url) : browserHistory.push(url)
}

export const select = (condition: any, accordance: Record<string, unknown>): unknown => accordance[condition]

export const truncate = (str: string, length: number, withEllipsis = false): string =>
  str.length - 1 > length ? `${str.slice(0, length)}${withEllipsis ? '...' : ''}` : str

export const handleOutsideClick = (query: string, callback: (e: any) => void) => (e: any) => {
  const boxElement = document.querySelector(query)
  let targetElement = e.target
  do {
    if (boxElement === targetElement) {
      return
    }
    targetElement = targetElement && targetElement.parentNode
  } while (targetElement)
  callback(e)
}

export const makeId = (length: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const useQuery = (location: Location) => {
  return new URLSearchParams(location.search)
}

export const filterActiveProjects = (projects: Project[]) => {
  if (!Array.isArray(projects)) {
    return []
  }
  const filterConditions = ['Active', 'Intermittent']
  return projects.filter((project: Project) => filterConditions.includes(project.status))
}
