import React, {FunctionComponent, useEffect, useState} from 'react'
import {DataTable, DataTableSkeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, TooltipIcon} from 'carbon-components-react'
import {BadgeStat, MemberStatsData, ProjectStat} from '../../../../../../services/api/types/member-api'
import {formatPoints, formatResults, formatRuntime} from '../../../../../../utils'
import {Projects} from '../../../../../../services/types'
import {api} from '../../../../../../services'
import {Project} from '../../../../../../services/api/types'

interface headerType {
  header: string
  key: string
}
const dataHeaders: headerType[] = [
  {
    header: 'Project name',
    key: 'name',
  },
  {
    header: 'Status',
    key: 'status',
  },
  {
    header: 'Runtime',
    key: 'runtime',
  },
  {
    header: 'Results returned',
    key: 'results',
  },
  {
    header: 'Points earned',
    key: 'points',
  },
  {
    header: 'Badge',
    key: 'badge',
  },
]

interface ProjectRow {
  id: string
  name: string
  status: string
  badge: number
  runtime: number
  results: number
  points: number
}

const getStatus = (project: ProjectStat, wcgProjects: Project[]) => {
  const wcgProject = wcgProjects.find((wcgProject) => wcgProject.shortName === project.shortName)
  if (wcgProject) {
    return wcgProject.status
  }
  if (project.shortName === 'beta') {
    return 'Intermittent'
  }
  return ''
}

const projectToDataRow = (project: ProjectStat, wcgProjects: Project[]) => {
  const row: ProjectRow = {
    id: `${project.shortName}`,
    name: project.name,
    status: getStatus(project, wcgProjects),
    badge: project.runtimeInSeconds,
    runtime: project.runtimeInSeconds,
    results: project.results,
    points: project.points,
  }
  return row
}

const formatProjectName = (project: ProjectStat | undefined) => {
  if (project) {
    return (
      <a className="wcg-no-underline" href={`/research/${project.shortName}/overview.s`}>
        {project.name}
      </a>
    )
  }
  return 'Error'
}

const formatBadge = (project: ProjectStat | undefined, badges: BadgeStat[]) => {
  if (project) {
    const badge = badges.find((badge: BadgeStat) => badge.projectName === project.name)
    if (badge) {
      return <img src={`/images/pb/${badge.projectShortName}_${badge.badgeLevel}_48.png`} alt={badge.description} />
    }
  }
  return ''
}

const renderCell = (id: string | number | null | undefined, cell: any, projects: ProjectStat[], badges: BadgeStat[]) => {
  const project = projects.find((project) => project.shortName == id)
  const column = cell.info.header
  if (column === 'results') {
    return formatResults(cell.value)
  } else if (column === 'points') {
    return formatPoints(cell.value)
  } else if (column === 'runtime') {
    return formatRuntime(cell.value)
  } else if (column === 'name') {
    return formatProjectName(project)
  } else if (column === 'badge') {
    return formatBadge(project, badges)
  }
  return cell.value
}

export interface MyProjectContributionSectionProps {
  stats: MemberStatsData
}

const MyProjectContributionSection: FunctionComponent<MyProjectContributionSectionProps> = ({stats}) => {
  const [wcgProjects, setWcgProjects] = useState<Project[] | undefined>(undefined)

  const projects = stats.projectStats
  const badges = stats.badges

  useEffect(() => {
    const getData = async () => {
      const resp = await api.getProjects()
      setWcgProjects(resp.data)
    }
    getData()
  }, [])

  if (projects === undefined || wcgProjects === undefined) {
    return <DataTableSkeleton />
  }

  const dataRows = projects.map((project) => projectToDataRow(project, wcgProjects))
  return (
    <div className="wcg-my-project-contribution">
      <DataTable rows={dataRows} headers={dataHeaders} isSortable>
        {/* @ts-ignore */}
        {({rows, headers, getHeaderProps, getRowProps, getTableProps, getToolbarProps, getTableContainerProps}) => (
          <TableContainer {...getTableContainerProps()}>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header: headerType) => (
                    <TableHeader key={header.key} {...getHeaderProps({header})}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: {id: string | number | null | undefined; cells: any[]}) => (
                  <TableRow key={row.id} {...getRowProps({row})}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{renderCell(row.id, cell, projects, badges)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </div>
  )
}

export default MyProjectContributionSection
