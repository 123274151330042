import {internal, external} from '../urls'
import {NavItem} from './types'

const nav: NavItem[] = [
  {
    title: 'Research',
    items: [
      {title: 'Active research', url: internal['Active research'].url},
      {title: 'Completed research', url: internal['Completed research'].url},
      {title: 'Submit a proposal', url: internal['Submit a proposal'].url},
    ],
  },
  {
    title: 'About',
    items: [
      {title: 'About us', url: internal['About us'].url},
      {title: 'How it works', url: internal['How it works'].url},
      {title: 'Security', url: internal.Security.url},
      {title: 'Our partners', url: internal['Our partners'].url},
      {title: 'Spread the word', url: internal['Spread the word'].url},
    ],
  },
  {
    title: 'News',
    items: [
      {title: 'Newsletters', url: internal.Newsletters.url},
      {title: 'Articles', url: internal.News.url},
    ],
  },
  {
    title: 'Community',
    items: [
      {
        title: 'Global',
        url: internal['Global statistics'].url,
      },
      {
        title: 'Projects',
        url: internal['By project'].url,
      },
      {
        title: 'Teams',
        url: internal['By team'].url,
      },
      {
        title: 'Members',
        url: internal['By member'].url,
      },
      {
        title: 'Forums',
        url: internal.Forums.url,
      },
      {
        title: 'Geography',
        url: internal.Geography.url,
      },
    ],
  },
  {
    title: 'My contribution',
    items: [
      {
        title: 'Overview',
        url: internal['My contribution'].url,
      },
      {
        title: 'History',
        url: internal['My history'].url,
      },
      {
        title: 'Projects',
        url: internal['My projects'].url,
      },
      {
        title: 'Team',
        url: internal['My team'].url,
      },
      {
        title: 'Devices',
        url: internal['Device statistics'].url,
      },
      {
        title: 'Results',
        url: internal.Results.url,
      },
      {
        title: 'Milestones',
        url: internal['Contribution Milestones'].url,
      },
      {
        title: 'Beta testing',
        url: internal['Beta testing'].url,
      },
    ],
  },
  {
    title: 'Links',
    items: [
      {
        title: 'Forums',
        url: internal.Forums.url,
      },
      {
        title: 'Help',
        url: internal.Help.url,
      },
      {
        title: 'Contact',
        url: internal.Contact.url,
      },
      {
        title: 'Twitter',
        url: external.Twitter.url,
      },
      {
        title: 'Facebook',
        url: external.Facebook.url,
      },
      {
        title: 'YouTube',
        url: external.YouTube.url,
      },
      {
        title: 'LinkedIn',
        url: external.LinkedIn.url,
      },
    ],
  },
  {title: 'Download', url: internal.Download.url},
  {title: 'Donations', url: internal.Donations.url},
]

export default nav
