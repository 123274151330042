import React, {FunctionComponent} from 'react'
import {CodeSnippetDivProps, CodeSnippet as CarbonCodeSnippet} from 'carbon-components-react'
import cn from 'classnames'
import copy from 'copy-to-clipboard'
const CodeSnippet: FunctionComponent<CodeSnippetDivProps> = ({children, className, ...rest}) => {
  return (
    <CarbonCodeSnippet
      {...rest}
      className={cn('wcg--snippet', className)}
      onClick={(e: any) => {
        const innerHTML = e.currentTarget.parentElement.querySelector('div[role="textbox"] code pre').innerHTML
        copy(innerHTML)
      }}
    >
      {children}
    </CarbonCodeSnippet>
  )
}

export default CodeSnippet
