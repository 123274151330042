import {combineReducers, createStore, applyMiddleware, Action} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk, {ThunkAction} from 'redux-thunk'
import {userReducer} from './user/reducer'
import {projectsReducer} from './projects/reducer'
import {UserActions} from './user/types'
import {navagationReducer} from './navigation/reducer'
import {loginRedirectReducer} from './loginRedirect/reducer'
import {appStateReducer} from './appState/reducer'

const rootReducer = combineReducers({
  user: userReducer,
  projects: projectsReducer,
  navigation: navagationReducer,
  loginRedirect: loginRedirectReducer,
  appState: appStateReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export type AppThunkAction<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

export type AppActions = UserActions

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['navigation', 'projects'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const composeEnhancers = composeWithDevTools({})
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))

const persistor = persistStore(store)

export {store, persistor}
