import React, {FunctionComponent} from 'react'
import {HeaderNavigation as CarbonHeaderNavigation, HeaderMenu, HeaderMenuItem} from 'carbon-components-react/lib/components/UIShell'
import {Link} from '../../../../components'

interface TopNavActionItem {
  title: string
  url?: string
  items?: TopNavActionItem[]
}

interface TopNavProps {
  itemsData: TopNavActionItem[]
}

const TopNav: FunctionComponent<TopNavProps> = ({itemsData}) => {
  return (
    <CarbonHeaderNavigation aria-label="World Community Grid" role="menubar">
      {itemsData.map((item: TopNavActionItem, index: number) => {
        return item.items ? (
          <HeaderMenu key={index} aria-label={item.title} menuLinkName={item.title}>
            {item.items.map((subLink: TopNavActionItem, index: number) => {
              return (
                <HeaderMenuItem key={index} href={subLink.url} element={Link}>
                  {subLink.title}
                </HeaderMenuItem>
              )
            })}
          </HeaderMenu>
        ) : (
          <HeaderMenuItem key={index} href={item.url} element={Link}>
            {item.title}
          </HeaderMenuItem>
        )
      })}
    </CarbonHeaderNavigation>
  )
}

export default TopNav
