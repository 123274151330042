import React, {FunctionComponent, ReactElement} from 'react'
import cn from 'classnames'

interface LogoCardProps {
  src: string
  className?: string
  alt?: string
}

const defaultProps = {
  src: '',
}

const LogoCard: FunctionComponent<LogoCardProps> = ({src, className, alt, ...rest}) => {
  return (
    <div className={cn('card_logo', 'card-image-container bx--aspect-ratio bx--aspect-ratio--4x3', className)} {...rest}>
      <div className="bx--aspect-ratio--object">
        <img src={src} className="card-image logo" alt={alt} />
      </div>
    </div>
  )
}

LogoCard.defaultProps = defaultProps

interface LogoCardGroupProps {
  className?: string
  children: ReactElement[]
}

const LogoCardGroup: FunctionComponent<LogoCardGroupProps> = ({children, className}) => {
  return (
    <div className={cn('card-group_logo', className)}>
      {React.Children.map(children, (child, index) => (
        //@ts-ignore
        <div className={cn('card-group__card-wrapper', index % 2 === 0 ? 'bx--col-md-3' : 'bx--col-md-3 bx--offset-md-1')}>{React.cloneElement(child)}</div>
      ))}
    </div>
  )
}

export {LogoCard, LogoCardGroup}
