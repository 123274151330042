// Packages
import React, {Component, PropsWithChildren, ChangeEvent} from 'react'
import {Button} from 'carbon-components-react'
import styles from './GridHelper.module.scss'
interface State {
  isVisible: boolean
  isVisibleGrid: boolean
  gridType: string
  currentBreakpoint: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

class GridHelper extends Component<PropsWithChildren<Props>, State> {
  constructor(props: PropsWithChildren<Props>) {
    super(props)
    this.state = {
      isVisible: true,
      isVisibleGrid: false,
      gridType: 'wide',
      currentBreakpoint: 'small',
    }
    this.keyDownHandler = this.keyDownHandler.bind(this)
    this.resizeHandler = this.resizeHandler.bind(this)
    this.changeBreakpoint = this.changeBreakpoint.bind(this)
    this.changeGridType = this.changeGridType.bind(this)
    this.toggleOpacityIndicator = this.toggleOpacityIndicator.bind(this)
  }

  keyDownHandler(e: KeyboardEvent) {
    if (e.keyCode === 71 && e.ctrlKey) {
      this.setState((oldState: State) => {
        return {isVisible: !oldState.isVisible}
      })
    }
  }

  breakpoints = [
    {name: 'Small', abbrev: 'sm', size: 320},
    {name: 'Medium', abbrev: 'md', size: 672},
    {name: 'Large', abbrev: 'lg', size: 1056},
    {name: 'Extra Large', abbrev: 'xlg', size: 1312},
    {name: 'Max', abbrev: 'max', size: 1584},
  ]

  resizeHandler() {
    this.changeBreakpoint()
  }

  changeBreakpoint() {
    const newWidth = window.outerWidth
    // eslint-disable-next-line array-callback-return
    const breakpoint = this.breakpoints.find((item, ind, array) => {
      if (ind < array.length - 1) {
        return newWidth > item.size && newWidth < array[ind + 1].size
      } else if (ind === array.length - 1) {
        return newWidth > item.size
      }
    })
    if (breakpoint && breakpoint.name !== this.state.currentBreakpoint) {
      this.setState(() => {
        return {currentBreakpoint: breakpoint.name}
      })
    }
  }

  changeGridType(e: ChangeEvent<HTMLInputElement>) {
    this.setState({gridType: e && e.target && e.target.value})
  }

  toggleOpacityIndicator() {
    this.setState((oldState) => ({
      isVisibleGrid: !oldState.isVisibleGrid,
    }))
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDownHandler, false)
    this.changeBreakpoint()
    window.addEventListener('resize', this.changeBreakpoint)
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDownHandler, false)
    window.removeEventListener('resize', this.changeBreakpoint)
  }

  render() {
    return (
      <div className={`${styles['grid-helper']} ${this.state.isVisible ? styles.isVisible : ''}`}>
        <table
          className={`${styles['grid-helper-indicator']} 
          }`}
        >
          <tbody>
            <tr>
              <td>
                <Button onClick={this.toggleOpacityIndicator} className={styles['grid-helper-type-indicator']}>
                  {this.state.isVisibleGrid ? 'Invisible' : 'Visible'}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        {/* CARBON GRID */}
        <div
          className={`bx--grid${this.state.gridType === 'condensed' ? ' bx--grid--condensed' : ''} ${this.state.isVisibleGrid ? 'isVisibleGrid' : ''}`}
          style={{
            height: '100%',
            width: '100%',
            display: this.state.isVisibleGrid ? 'block' : 'none',
            backgroundColor: 'rgba(143, 143, 143, 0.09)',
          }}
        >
          <div className="bx--row" style={{height: '100%'}}>
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
            <div className={`${styles['grid-helper__col']} bx--col-sm-1 bx--col-md-1 bx--col-lg-1`} />
          </div>
        </div>
      </div>
    )
  }
}

export default GridHelper
