export enum AdminRolesEnum {
  'Content Editors' = 'Content Editors',
  'Web Administrators' = 'Web Administrators',
  'Level 1 Support' = 'Level 1 Support',
}
export enum RolesEnum {
  'Guests' = 'Guests',
  'Logged in Members' = 'Logged in Members',
  'Web Administrators' = 'Web Administrators',
  'Content Editors' = 'Content Editors',
}

export type AdminRoles = keyof typeof AdminRolesEnum
export type Roles = keyof typeof RolesEnum

export interface User {
  loggedIn: boolean
  roles?: Roles[]
  userName?: string
}

export interface SignUpValues {
  emailAddress: string
  password: string
  acceptTerms: boolean
}

export interface LoginValues {
  j_username: string
  j_password: string
  settoken?: 'on'
}

export type Projects = any

export type PrismicProjects = any
