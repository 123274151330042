import {AxiosResponse} from 'axios'
import {api} from '../../services'
import {User, SignUpValues, LoginValues} from '../../services/types'
import {AppThunkAction} from '../index'
import {clearError, setError} from '../appState/actions'
import {ACTION_TYPE, UserActions} from './types'

export const setUser = (user: User): UserActions => ({
  type: ACTION_TYPE.SET_USER,
  payload: user,
})

export const delUser = (): UserActions => ({
  type: ACTION_TYPE.DEL_USER,
})

export const fetchLoginStatus = (): AppThunkAction<void> => async (dispatch) => {
  const resp = await api.getLoginStatus()
  // @ts-ignore
  if (resp == undefined || (resp.response && resp.response.status >= 500)) {
    dispatch(setError({hasError: true, errorSource: 'loginStatus'}))
  }
  const user: User = resp && resp.data
  dispatch(setUser(user))
}

export const fetchSignUp =
  (values: SignUpValues): AppThunkAction<void> =>
  async (dispatch) => {
    const resp: AxiosResponse = await api.postUsers(values)
    // @ts-ignore
    if (resp == undefined || (resp.response && resp.response.status >= 500)) {
      dispatch(setError({hasError: true, errorSource: 'fetchSignUp'}))
    }
    if (resp.status === 400 && resp.data && resp.data.validationErrors) {
      return Promise.reject(resp.data)
    }
    const user: User = resp && resp.data
    dispatch(setUser(user))
  }

export const fetchLogin =
  (values: LoginValues): AppThunkAction<void> =>
  async (dispatch) => {
    const {j_password, j_username, settoken} = values
    const resp: AxiosResponse = await api.postLogin({j_password, j_username, settoken})

    if (resp && resp.status === 401 && resp.data) {
      return Promise.reject(resp.data)
    }

    await dispatch(fetchLoginStatus())
  }
