// @ts-ignore
import _map from 'lodash.map'
import _filter from 'lodash.filter'
// eslint-disable-next-line import/no-unresolved
import ApiSearchResponse from 'prismic-javascript/types/ApiSearchResponse'
// eslint-disable-next-line import/no-unresolved
import {Document} from 'prismic-javascript/types/documents'
import {api} from '../../index'
import {ProjectShortNames} from '../../../constants/navigation/projects'

export const getPrismic = async () => {
  const shortnamesArray = _map(ProjectShortNames, (shortname) => shortname)
  const response = await api.getPrismicProjectsByShortNames(shortnamesArray)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const final = response && response.results.map((result: any) => result.data)
}

export const prismicProjectsByShortNamesResponseContent: ApiSearchResponse = {
  page: 1,
  results_per_page: 20,
  results_size: 7,
  total_results_size: 7,
  total_pages: 1,
  next_page: 'null',
  prev_page: 'null',
  results: [
    {
      id: 'XvOF0REAACIA6H-9',
      uid: 'null',
      type: 'project',
      href: 'https://world-community-grid.cdn.prismic.io/api/v2/documents/search?ref=X_XcGxEAACMAsRlP&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22XvOF0REAACIA6H-9%22%29+%5D%5D',
      tags: [],
      first_publication_date: '2020-06-24T17:08:00+0000',
      last_publication_date: '2020-09-18T14:08:22+0000',
      slugs: ['fightaidshome---phase-2', 'fahb', 'faahb', 'openpandemics---covid-19'],
      lang: 'en-us',
      alternate_languages: [],
      data: {
        name: 'FightAIDS@Home - Phase 2',
        short_name: 'fahb',
        tiny_summary: 'Help researchers refine the results from Phase 1 of FightAIDS@Home.',
        short_summary: 'AIDS is constantly evolving. So are the tools to fight it.',
        medium_summary:
          'The first phase of FightAIDS@Home made significant advances in HIV research. As the virus evolves, the research team is now pioneering the use of new analysis techniques to better identify promising anti-HIV drugs.',
        medium_summary_header: 'FightAIDS@Home - Phase 2',
        medium_summary_header_image: {
          dimensions: {width: 608, height: 342},
          alt: null,
          copyright: null,
          url: 'https://images.prismic.io/world-community-grid/33649ded-8272-439a-a606-5a250c110cbe_fahbBig.jpg?auto=compress,format&rect=166,0,658,370&w=608&h=342',
          medium: {
            dimensions: {width: 248, height: 140},
            alt: null,
            copyright: null,
            url: 'https://images.prismic.io/world-community-grid/33649ded-8272-439a-a606-5a250c110cbe_fahbBig.jpg?auto=compress,format&rect=334,0,655,370&w=248&h=140',
          },
          small: {
            dimensions: {width: 336, height: 189},
            alt: null,
            copyright: null,
            url: '/images/prismic/33649ded-8272-439a-a606-5a250c110cbe_fahbBig.jpg?auto=compress,format&rect=116,0,658,370&w=336&h=189',
          },
        },
        my_contribution_statement: 'My contribution to help search for better HIV/AIDS treatments.',
        page_title: 'FightAIDS@Home - Phase 2 | World Community Grid',
        page_description:
          'The first phase of FightAIDS@Home made significant advances in HIV research. As the virus evolves, the research team is now pioneering the use of new analysis techniques to better identify promising anti-HIV drugs.',
        social_image: '/images/slideshowImages/large/fahbBig.jpg',
      },
    },
    {
      id: 'XtbH0RAAAB4AK7cU',
      uid: 'null',
      type: 'project',
      href: 'https://world-community-grid.cdn.prismic.io/api/v2/documents/search?ref=X_XcGxEAACMAsRlP&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22XtbH0RAAAB4AK7cU%22%29+%5D%5D',
      tags: [],
      first_publication_date: '2020-06-02T21:42:44+0000',
      last_publication_date: '2020-09-18T13:57:32+0000',
      slugs: ['africa-rainfall-project', 'crucial-data-better-crops.'],

      lang: 'en-us',
      alternate_languages: [],
      data: {
        name: 'Africa Rainfall Project',
        short_name: 'arp1',
        tiny_summary: 'Help scientists provide more accurate rainfall forecasts for farmers in sub-Saharan Africa.',
        short_summary:
          'Your computer can run high-resolution simulations of rainstorms in sub-Saharan Africa, which will help scientists provide more accurate rainfall forecasts for farmers in this important region.',
        medium_summary:
          '95% of agriculture in Africa depends on rainfall. This project uses massive computing power, data from The Weather Company, and other data to provide more accurate rainfall forecasts, which will help farmers more successfully raise crops.',
        medium_summary_header: 'Crucial data, better crops.',
        medium_summary_header_image: {
          dimensions: {width: 608, height: 342},
          alt: null,
          copyright: null,
          url: '/images/prismic/b15711e0-4ae0-41e0-b333-0e95d755ec49_arp1Big.jpg?auto=compress,format&rect=332,0,658,370&w=608&h=342',
          medium: {
            dimensions: {width: 248, height: 140},
            alt: null,
            copyright: null,
            url: '/images/prismic/b15711e0-4ae0-41e0-b333-0e95d755ec49_arp1Big.jpg?auto=compress,format&rect=334,0,655,370&w=248&h=140',
          },
          small: {
            dimensions: {width: 336, height: 189},
            alt: null,
            copyright: null,
            url: '/images/prismic/b15711e0-4ae0-41e0-b333-0e95d755ec49_arp1Big.jpg?auto=compress,format&rect=332,0,658,370&w=336&h=189',
          },
        },
        my_contribution_statement: 'My contribution to help scientists provide more accurate rainfall forecasts for farmers in sub-Saharan Africa',
        page_title: 'Africa Rainfall Project | World Community Grid',
        page_description:
          '95% of agriculture in Africa depends on rainfall. This project uses massive computing power, data from The Weather Company, and other data to provide more accurate rainfall forecasts, which will help farmers more successfully raise crops.',
        social_image: '/images/slideshowImages/large/arp1Big.jpg',
      },
    },
    {
      id: 'XtbI1xAAACMAK7u8',
      uid: 'null',
      type: 'project',
      href: 'https://world-community-grid.cdn.prismic.io/api/v2/documents/search?ref=X_XcGxEAACMAsRlP&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22XtbI1xAAACMAK7u8%22%29+%5D%5D',
      tags: [],
      first_publication_date: '2020-06-02T21:47:07+0000',
      last_publication_date: '2020-09-18T14:06:41+0000',
      slugs: ['microbiome-immunity-project', 'mapping-new-territory-inside-our-bodies'],

      lang: 'en-us',
      alternate_languages: [],
      data: {
        name: 'Microbiome Immunity Project',
        short_name: 'mip1',
        tiny_summary: 'Help scientists learn more about how the bacteria inside our bodies might play a role in disease.',
        short_summary:
          'Trillions of bacteria inside our bodies may play a large role in certain conditions such as autoimmune diseases. Help support a team of researchers who are undertaking a comprehensive study of this little-known territory.',
        medium_summary:
          'Did you know that trillions of bacteria live inside and on your body? In this comprehensive study of the human microbiome, you can help scientists understand the role these bacteria play in disease.',
        medium_summary_header: 'Mapping new territory inside our bodies',
        medium_summary_header_image: {
          dimensions: {width: 608, height: 342},
          alt: null,
          copyright: null,
          url: '/images/prismic/7af97d68-986a-457b-a207-1963f7c9e9e2_mip1Big.jpg?auto=compress,format&rect=341,0,654,368&w=608&h=342',
          medium: {
            dimensions: {width: 248, height: 140},
            alt: null,
            copyright: null,
            url: '/images/prismic/7af97d68-986a-457b-a207-1963f7c9e9e2_mip1Big.jpg?auto=compress,format&rect=343,0,652,368&w=248&h=140',
          },
          small: {
            dimensions: {width: 336, height: 189},
            alt: null,
            copyright: null,
            url: '/images/prismic/7af97d68-986a-457b-a207-1963f7c9e9e2_mip1Big.jpg?auto=compress,format&rect=341,0,654,368&w=336&h=189',
          },
        },
        my_contribution_statement: 'My contribution to help study the human microbiome',
        page_title: 'Microbiome Immunity Project | World Community Grid',
        page_description:
          'Did you know that trillions of bacteria live inside and on your body? In this comprehensive study of the human microbiome, you can help scientists understand the role these bacteria play in disease.',
        social_image: '/images/slideshowImages/large/mip1Big.jpg',
      },
    },
    {
      id: 'XtgYoBAAACMAMVaB',
      uid: 'null',
      type: 'project',
      href: 'https://world-community-grid.cdn.prismic.io/api/v2/documents/search?ref=X_XcGxEAACMAsRlP&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22XtgYoBAAACMAMVaB%22%29+%5D%5D',
      tags: [],
      first_publication_date: '2020-06-08T13:15:46+0000',
      last_publication_date: '2020-09-18T13:56:55+0000',
      slugs: ['smash-childhood-cancer'],

      lang: 'en-us',
      alternate_languages: [],
      data: {
        name: 'Smash Childhood Cancer',
        short_name: 'scc1',
        tiny_summary: 'Search for better childhood cancer treatments.',
        short_summary: 'Help an international research team find better treatments for some of the most common childhood cancers.',
        medium_summary:
          'Since 2009, World Community Grid has been part of the fight against childhood cancer. This project continues that work by expanding the search for better treatments to more types of childhood cancer.',
        medium_summary_header: 'Help researchers smash childhood cancer',
        medium_summary_header_image: {
          dimensions: {width: 608, height: 342},
          alt: null,
          copyright: null,
          url: '/images/prismic/3c6fb0af-8627-4290-93b2-7b4b39924ce8_scc1Big.jpg?auto=compress,format&rect=332,0,658,370&w=608&h=342',
          medium: {
            dimensions: {width: 248, height: 140},
            alt: null,
            copyright: null,
            url: '/images/prismic/3c6fb0af-8627-4290-93b2-7b4b39924ce8_scc1Big.jpg?auto=compress,format&rect=334,0,655,370&w=248&h=140',
          },
          small: {
            dimensions: {width: 336, height: 189},
            alt: null,
            copyright: null,
            url: '/images/prismic/3c6fb0af-8627-4290-93b2-7b4b39924ce8_scc1Big.jpg?auto=compress,format&rect=332,0,658,370&w=336&h=189',
          },
        },
        my_contribution_statement: 'My contribution to the search for better childhood cancer treatments',
        page_title: 'Smash Childhood Cancer',
        page_description:
          'Since 2009, World Community Grid has been part of the fight against childhood cancer. This project continues that work by expanding the search for better treatments to more types of childhood cancer.',
        social_image: '/images/slideshowImages/large/scc1Big.jpg',
      },
    },
    {
      id: 'XtbJvhAAACIAK7_A',
      uid: 'null',
      type: 'project',
      href: 'https://world-community-grid.cdn.prismic.io/api/v2/documents/search?ref=X_XcGxEAACMAsRlP&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22XtbJvhAAACIAK7_A%22%29+%5D%5D',
      tags: [],
      first_publication_date: '2020-06-02T21:51:46+0000',
      last_publication_date: '2020-09-18T14:00:21+0000',
      slugs: ['mapping-cancer-markers', 'help-improve-the-odds-for-cancer-patients'],

      lang: 'en-us',
      alternate_languages: [],
      data: {
        name: 'Mapping Cancer Markers',
        short_name: 'mcm1',
        tiny_summary: 'Help search for molecular markers that could lead to improved cancer detection and treatment.',
        short_summary:
          'Researchers are identifying combinations of genetic markers that will help predict cancer and its progression, leading to improved detection and treatment.',
        medium_summary:
          'Mapping Cancer Markers is analyzing millions of data points collected from thousands of healthy and cancerous patient tissue samples. By comparing these different data points, researchers aim to identify patterns of markers for different cancers and correlate them with different outcomes, including responsiveness to various treatment options.',
        medium_summary_header: 'Help improve the odds for cancer patients',
        medium_summary_header_image: {
          dimensions: {width: 608, height: 342},
          alt: null,
          copyright: null,
          url: '/images/prismic/731ff58c-8c76-4b7f-b33f-769ae76ddf4a_mcm1Big.jpg?auto=compress,format&rect=337,0,658,370&w=608&h=342',
          medium: {
            dimensions: {width: 248, height: 140},
            alt: null,
            copyright: null,
            url: '/images/prismic/731ff58c-8c76-4b7f-b33f-769ae76ddf4a_mcm1Big.jpg?auto=compress,format&rect=340,0,655,370&w=248&h=140',
          },
          small: {
            dimensions: {width: 336, height: 189},
            alt: null,
            copyright: null,
            url: '/images/prismic/731ff58c-8c76-4b7f-b33f-769ae76ddf4a_mcm1Big.jpg?auto=compress,format&rect=337,0,658,370&w=336&h=189',
          },
        },
        my_contribution_statement: 'My contribution to help search for cancer biomarkers',
        page_title: 'Mapping Cancer Markers | World Community Grid',
        page_description:
          "Mapping Cancer Markers aims to identify chemical markers associated with various types of cancer. This will help researchers detect cancer earlier and design more personalized cancer care by determining an individual's susceptibility to developing cancer and suggest the best treatment for them.",
        social_image: '/images/slideshowImages/large/mcm1Big.jpg',
      },
    },
    {
      id: 'XtbHXRAAAB0AK7UF',
      uid: 'null',
      type: 'project',
      href: 'https://world-community-grid.cdn.prismic.io/api/v2/documents/search?ref=X_XcGxEAACMAsRlP&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22XtbHXRAAAB0AK7UF%22%29+%5D%5D',
      tags: [],
      first_publication_date: '2020-06-02T21:40:57+0000',
      last_publication_date: '2020-09-18T14:07:15+0000',
      slugs: ['openpandemics---covid-19'],

      lang: 'en-us',
      alternate_languages: [],
      data: {
        name: 'OpenPandemics - COVID-19',
        short_name: 'opn1',
        tiny_summary: 'Help search for potential treatments for COVID-19.',
        short_summary:
          'Your computer, Android device, or Raspberry Pi can carry out simulated experiments on potential treatments for COVID-19, which helps accelerate research during a highly contagious global pandemic.',
        medium_summary:
          'COVID-19 is a disease caused by SARS-CoV2, a virus of the coronavirus family. These viruses cause diseases that affect mainly the human respiratory system and potentially other major organs. COVID-19 can lead to serious illness or even death. You and your computer can help scientists find potential treatments for the symptoms of this disease.',
        medium_summary_header: 'OpenPandemics - COVID-19',
        medium_summary_header_image: {
          dimensions: {width: 608, height: 342},
          alt: null,
          copyright: null,
          url: '/images/prismic/976b6d00-f25f-4522-953d-891b8398a9fc_opn1Big.jpg?auto=compress,format&rect=332,0,658,370&w=608&h=342',
          medium: {
            dimensions: {width: 248, height: 140},
            alt: null,
            copyright: null,
            url: '/images/prismic/976b6d00-f25f-4522-953d-891b8398a9fc_opn1Big.jpg?auto=compress,format&rect=334,0,655,370&w=248&h=140',
          },
          small: {
            dimensions: {width: 336, height: 189},
            alt: null,
            copyright: null,
            url: '/images/prismic/976b6d00-f25f-4522-953d-891b8398a9fc_opn1Big.jpg?auto=compress,format&rect=332,0,658,370&w=336&h=189',
          },
        },
        my_contribution_statement: 'My contribution to help search for potential treatments for COVID-19',
        page_title: 'OpenPandemics - COVID-19 | World Community Grid',
        page_description:
          'Scientists at Scripps Research are using World Community Grid to help search for potential treatments for COVID-19, and to build open-source tools to help address future pandemics quickly and early.',
        social_image: '/images/slideshowImages/large/opn1Big.jpg',
      },
    },
    {
      id: 'XvOIixEAACEA6IvL',
      uid: 'null',
      type: 'project',
      href: 'https://world-community-grid.cdn.prismic.io/api/v2/documents/search?ref=X_XcGxEAACMAsRlP&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22XvOIixEAACEA6IvL%22%29+%5D%5D',
      tags: [],
      first_publication_date: '2020-06-24T17:26:23+0000',
      last_publication_date: '2020-09-18T13:55:58+0000',
      slugs: ['help-stop-tb', 'hhelp-stop-tb', 'hst1', 'fightaidshome---phase-2'],

      lang: 'en-us',
      alternate_languages: [],
      data: {
        name: 'Help Stop TB',
        short_name: 'hst1',
        tiny_summary: 'Help researchers study the bacteria that causes tuberculosis, so they can learn how to better fight it.',
        short_summary:
          "Researchers are building an understanding of the tuberculosis bacteria's defenses, to help overcome one of the world's deadliest diseases.",
        medium_summary:
          "Tuberculosis kills approximately 1.5 million people every year, making it one of the world's deadliest infectious diseases. You can help researchers learn more about this disease and how to overcome it.",
        medium_summary_header: 'Help Stop TB',
        medium_summary_header_image: {
          dimensions: {width: 608, height: 342},
          alt: null,
          copyright: null,
          url: '/images/prismic/6f3c54b7-bf30-4c40-a2d4-c2d5bd1ca545_HSTB+Image.jpg?auto=compress,format&rect=167,0,658,370&w=608&h=342',
          medium: {
            dimensions: {width: 248, height: 140},
            alt: null,
            copyright: null,
            url: '/images/prismic/6f3c54b7-bf30-4c40-a2d4-c2d5bd1ca545_HSTB+Image.jpg?auto=compress,format&rect=167,0,655,370&w=248&h=140',
          },
          small: {
            dimensions: {width: 336, height: 189},
            alt: null,
            copyright: null,
            url: '/images/prismic/6f3c54b7-bf30-4c40-a2d4-c2d5bd1ca545_HSTB+Image.jpg?auto=compress,format&rect=166,0,658,370&w=336&h=189',
          },
        },
        my_contribution_statement: 'My contribution to help study the bacteria that causes tuberculosis.',
        page_title: 'Help Stop TB | World Community Grid',
        page_description:
          "Tuberculosis killed 1.5 million people in 2014, making it one of the world's deadliest diseases. You can help researchers learn more about this disease and how to overcome it.",
        social_image: '/images/slideshowImages/large/hst1Big.jpg',
      },
    },
  ],
}

const defaultProjectsShortNames = ['fahb', 'arp1', 'mip1', 'scc1', 'mcm1', 'opn1', 'hst1']

export const formatPrismicProjectsByShortnames = (shortnames: any): ApiSearchResponse => {
  const documents: Document[] = _filter<Document>(prismicProjectsByShortNamesResponseContent.results, (document: any) =>
    [...defaultProjectsShortNames, ...shortnames].includes(document.data.short_name),
  )
  return {...prismicProjectsByShortNamesResponseContent, results: documents}
}
