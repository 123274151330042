import React, {FunctionComponent, ReactNode} from 'react'
import cn from 'classnames'
import {SkeletonText} from 'carbon-components-react'
import {Divider} from '../../../../../../components/Divider'
import {DividerProps} from '../../../../../../components/Divider/Divider'

export interface ComponentProps {
  className?: string
}

const CardTitle: FunctionComponent<ComponentProps> = ({children, className, ...rest}) => {
  return (
    <h5 className={cn('card-title', className)} {...rest}>
      {children}
    </h5>
  )
}

const CardHeader: FunctionComponent<ComponentProps> = ({children, className, ...rest}) => {
  return (
    <div className={cn('card-header', className)} {...rest}>
      {children}
    </div>
  )
}

const CardSemiText: FunctionComponent<ComponentProps & {gray?: boolean}> = ({children, className, gray, ...rest}) => {
  return (
    <p className={cn('card-semi-text', gray && 'text__gray', className)} {...rest}>
      {children}
    </p>
  )
}

const CardText: FunctionComponent<ComponentProps> = ({children, className, ...rest}) => {
  return (
    <p className={cn('card-text', className)} {...rest}>
      {children}
    </p>
  )
}

const CardDivider: FunctionComponent<DividerProps> = ({...rest}) => {
  return <Divider {...rest} />
}

const CardInnerWrapper: FunctionComponent<ComponentProps> = ({children, className, ...rest}) => {
  return (
    <div className={cn('card-inner-wrapper', className)} {...rest}>
      {children}
    </div>
  )
}
const CardContent: FunctionComponent<ComponentProps> = ({children, className, ...rest}) => {
  return (
    <div className={cn('card-content', className)} {...rest}>
      {children}
    </div>
  )
}

const CardVerticalWrapper: FunctionComponent<ComponentProps> = ({children, className, ...rest}) => {
  return (
    <div className={cn('card-vertical-wrapper', className)} {...rest}>
      {children}
    </div>
  )
}

const CardHorizontalWrapper: FunctionComponent<ComponentProps> = ({children, className, ...rest}) => {
  return (
    <div className={cn('card-horizontal-wrapper', className)} {...rest}>
      {children}
    </div>
  )
}

const CardList: FunctionComponent<ComponentProps> = ({children, className, ...rest}) => {
  return (
    <ul className={cn(className)} {...rest}>
      {children}
    </ul>
  )
}

const CardListRowSkeleton: FunctionComponent<ComponentProps> = ({className}) => {
  return <SkeletonText className={className} />
}

const CardListSkeleton: FunctionComponent<ComponentProps> = ({className, ...rest}) => {
  return (
    <ul className={cn(className)} {...rest}>
      <li>
        <div className="card-list-row">
          <CardListRowSkeleton />
        </div>
      </li>
      <li>
        <div className="card-list-row">
          <CardListRowSkeleton />
        </div>
      </li>
    </ul>
  )
}

const CardListRow: FunctionComponent<ComponentProps & {text?: string | ReactNode; icon?: any; state?: 'active' | 'inactive'}> = ({
  className,
  icon,
  text,
  state,
  ...rest
}) => {
  const Icon = icon
  return (
    <li className={cn('card-list-row', className)} {...rest}>
      <div className="card-list-row-content">
        {icon && (
          <div className="icon">
            {typeof icon === 'string' ? (
              <div
                className="icon-svg-div"
                style={{
                  backgroundImage: `url(${icon})`,
                }}
              />
            ) : (
              <Icon />
            )}
          </div>
        )}
        {text && (
          <div className="text-wrapper">
            <Card.Text className="text">{text}</Card.Text>
          </div>
        )}
        {state && <div className={cn('state', state && `state__${state}`)} />}
      </div>
    </li>
  )
}

const Card: FunctionComponent<ComponentProps> & {
  List: typeof CardList
  ListRow: typeof CardListRow
  ListSkeleton: typeof CardListSkeleton
  ListRowSkeleton: typeof CardListRowSkeleton
  Title: typeof CardTitle
  Header: typeof CardHeader
  SemiText: typeof CardSemiText
  Text: typeof CardText
  Divider: typeof CardDivider
  Content: typeof CardContent
  InnerWrapper: typeof CardInnerWrapper
  VerticalWrapper: typeof CardVerticalWrapper
  HorizontalWrapper: typeof CardHorizontalWrapper
} = ({children, className, ...rest}) => {
  return (
    <div className={cn('card', className)} {...rest}>
      {children}
    </div>
  )
}

Card.ListSkeleton = CardListSkeleton
Card.ListRowSkeleton = CardListRowSkeleton
Card.Title = CardTitle
Card.Header = CardHeader
Card.SemiText = CardSemiText
Card.Text = CardText
Card.Divider = CardDivider
Card.Content = CardContent
Card.InnerWrapper = CardInnerWrapper
Card.Title = CardTitle
Card.VerticalWrapper = CardVerticalWrapper
Card.HorizontalWrapper = CardHorizontalWrapper
Card.List = CardList
Card.ListRow = CardListRow

export default Card
