import {ACTION_TYPE, AppStateActions, AppErrorState} from './types'

export const setError = (error: AppErrorState): AppStateActions => ({
  type: ACTION_TYPE.SET_ERROR,
  payload: error,
})

export const clearError = (): AppStateActions => ({
  type: ACTION_TYPE.CLEAR_ERROR,
})
