/**
 * Check if given value is a number
 *
 * @param {*} value Value to check
 * @return {boolean} Returns "true" if given value is number
 */
export const isNumber = (value: any): boolean => !Number.isNaN(parseFloat(value)) && Number.isFinite(value)

/**
 * Check if given value is a string
 *
 * @param {*} value Value to check
 * @return {boolean} Returns "true" if given value is string
 */
export const isString = (value: any): boolean => typeof value === 'string' || value instanceof String
