import {RenewTeam, MobileDevices, ActiveServer, ChipCircuit, Electric, SecureProfile, WirelessModem} from '@carbon/pictograms-react'

import {colors, internal, external} from '../../constants'
export default {
  updatesSection: {
    title: 'Updates',
    description: 'Discover the latest from World Community Grid',
    link: {
      text: 'Show all news →',
      src: internal.News.url,
    },
  },
  howItWorksSection: {
    title: 'How it works',
    link: {
      text: 'Learn more →',
      src: internal['How it works'].url,
    },
    subtitle: 'Donate your device’s spare computing power to help scientists solve the world’s biggest problems.',
    subsections: [
      {
        title: 'Scientists need your help',
        text: `They’re searching for answers to the world’s biggest problems, but it’s hard to know where to
          start. That’s why many use computer simulations to point them in the right direction.`,
        iconSrc: '/images/logos/research.svg',
      },
      {
        title: 'Your device makes a difference',
        text: `As a World Community Grid volunteer, your device does research calculations when it has spare power.
                    With your help, scientists can identify the most important results to study in the lab, bringing
                    them one step closer to discoveries that save lives and address global problems.`,
        iconSrc: '/images/logos/add-device.svg',
      },
    ],
  },
  withYourHelpSection: {
    cards: [
      {
        title: 'Help Fight Childhood Cancer',
        url: 'https://www.worldcommunitygrid.org/about_us/viewNewsArticle.do?articleId=342',
        text: 'Identified new drugs in the fight against neuroblastoma - a childhood cancer.',
        src: '/images/home/hfcc_cropped.jpg',
      },
      {
        title: 'Computing for Clean Water',
        url: 'https://www.worldcommunitygrid.org/about_us/viewNewsArticle.do?articleId=436',
        text: 'Discovered how nanotechnology could help provide clean water to millions.',
        src: '/images/home/c4cw_cropped.jpg',
      },
      {
        title: 'The Clean Energy Project',
        url: 'https://www.worldcommunitygrid.org/about_us/viewNewsArticle.do?articleId=450',
        text: 'Discovered new compounds for harnessing solar power.',
        src: '/images/home/cep2_cropped.jpg',
      },
    ],
  },

  byTheNumbersSection: {
    title: 'By the numbers',
    link: {
      text: 'See the data →',
      src: '/stat/viewGlobal.do',
    },
    cards: {
      users: {
        icon: RenewTeam,
        title: '--',
        text: 'Volunteers have donated their spare computing power towards research initiatives.',
      },
      devices: {
        icon: MobileDevices,
        title: '--',
        text: 'Computers and Android devices have contributed to the vast network that makes up Community Grid.',
      },
      time: {icon: ActiveServer, title: '--', text: 'Years of computing power has been contributed to date.'},
    },
  },
  featuresSection: {
    cards: [
      {
        title: 'It won’t slow you down',
        icon: ChipCircuit,
        text: 'Our software monitors your device’s performance, so whatever you are doing on your device, it stays out of the way.',
      },
      {
        title: 'It won’t drain your battery',
        icon: Electric,
        text: 'By default, World Community Grid only runs when you’re connected to power.',
      },
      {
        title: 'It’s safe and secure',
        icon: SecureProfile,
        text:
          'Your personal information will never be shared. The software cannot access your personal files.\n' +
          'Security experts constantly test the system for vulnerabilities to the latest threats.',
      },
      {
        title: 'It won’t waste your data plan',
        icon: WirelessModem,
        text: "It won't use up your mobile data plan. World Community Grid only downloads research tasks and uploads results when you’re connected to a wireless network.",
      },
    ],
  },
  researchersSaySection: {
    title: 'Researchers say…',
    quote: {
      text: `Without World Community Grid, we wouldn't have even contemplated this project.`,
      cite: `Rob Knight, PhD<br/>Professor, Departments of Pediatrics and Computer Science & Engineering<br/>Director, Center for Microbiome Innovation<br/>Co-Principal Investigator, Microbiome Immunity Project`,
    },
  },
  looksWhoTalkingSection: {
    title: "Looks who's talking",
    cards: [
      {src: '/images/home/logos/ctv.png', url: external.ctv.url},
      {src: '/images/home/logos/fast_company_logo_greyscale.png', url: external['Fast Company'].url},
      {src: '/images/home/logos/forbes_logo_grayscale.png', url: external.forbes.url},
    ],
  },

  yourSparePowerSection: {
    title: 'Your spare computing power makes a difference',
    text: 'Join now and start helping today.',
    colors: [colors.lightBlue, colors.lightGreen, colors.lightRed],
    images: ['/images/home/woman_at_computer.jpg'],
  },
}
