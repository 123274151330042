import React, {FunctionComponent, RefObject, useEffect, useMemo, useRef, useState} from 'react'
import CalendarHeatmap from 'react-calendar-heatmap'
import 'react-calendar-heatmap/dist/styles.css'
import ReactTooltip from 'react-tooltip'
import {CalendarItems} from '../../types'
import {formatISODate, formatRuntime} from '../../../../../../../../utils'
import {getDisplayedCalendarItems, getMaxCount, getMinCount, getRangeEquivalentForCount, shiftDate, withSetTimeout} from './helpers'

export interface CalendarProps {
  data?: CalendarItems
  from?: Date
  to?: Date
}

const Calendar: FunctionComponent<CalendarProps> = ({data, from, to}) => {
  const today: Date = new Date()
  const calendarBoxRef: RefObject<any> = useRef(null)
  const [numOfDaysFromToday, setNumOfDaysFromToday] = useState<number | undefined>()

  const calculateItemsAmount = (parentContainerWidth: number) => {
    const ITEM_WIDTH = 30
    const DAYS_IN_COLUMN = 7
    const columns = parentContainerWidth / ITEM_WIDTH
    return Math.floor(columns) * DAYS_IN_COLUMN
  }

  const resizeCallback = () => {
    const _containerWidth: number = calendarBoxRef.current.clientWidth
    const itemsAmount = _containerWidth && calculateItemsAmount(_containerWidth)
    itemsAmount && setNumOfDaysFromToday(itemsAmount + today.getDay() + 1)
  }

  useEffect(() => {
    const DELAY_TIME = 50
    const callbackWithSetTimeout = withSetTimeout(resizeCallback, DELAY_TIME)
    resizeCallback()
    window.addEventListener('resize', callbackWithSetTimeout, false)
    return () => {
      window.removeEventListener('resize', callbackWithSetTimeout)
    }
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const displayedCalendarItems = useMemo(() => {
    return numOfDaysFromToday && data && getDisplayedCalendarItems(numOfDaysFromToday, today, data)
  }, [numOfDaysFromToday, today, data])
  const minCount = useMemo(() => {
    return data && getMinCount(data)
  }, [data])
  const maxCount = useMemo(() => {
    return data && getMaxCount(data)
  }, [data])

  if (!data) {
    return null
  }

  return (
    <div className="calendar-chart" id="calendar-chart" ref={calendarBoxRef}>
      {displayedCalendarItems && numOfDaysFromToday && (
        <>
          <CalendarHeatmap
            gutterSize={9}
            startDate={shiftDate(today, -numOfDaysFromToday)}
            endDate={today}
            weekdayLabels={['', 'M', '', 'W', '', 'F']}
            values={displayedCalendarItems}
            classForValue={(value) => {
              return value && value.count ? `wcg-calendar-color-${getRangeEquivalentForCount(value.count, minCount, maxCount)}` : 'color-empty'
            }}
            tooltipDataAttrs={(value: any) => {
              return {
                'data-tip': `${formatISODate(value.date)} : ${formatRuntime(value.count || 0)}`,
              }
            }}
            showWeekdayLabels={true}
          />
          <ReactTooltip />
        </>
      )}
    </div>
  )
}

export default Calendar
