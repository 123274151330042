import {Reducer} from 'redux'
import {ACTION_TYPE, UserActions, UserState} from './types'

const initialState: UserState = {
  loggedIn: true,
  userName: '',
}

const loggedOutSate: UserState = {
  loggedIn: false,
}

export const userReducer: Reducer<UserState, UserActions> = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SET_USER:
      if (action.payload && action.payload.loggedIn) {
        return {...state, ...action.payload}
      } else {
        return loggedOutSate
      }
    case ACTION_TYPE.DEL_USER:
      return loggedOutSate
    default:
      return state
  }
}
