import React, {FunctionComponent} from 'react'
import cn from 'classnames'
import {Recommend20} from '@carbon/icons-react'
import {SkeletonText} from 'carbon-components-react'
import {Card} from '../Card'
import {InteractiveTooltip} from '../../../../../../components'

export interface ComponentProps {
  className?: string
  title: string
  text: string
  footerText: string
  rating: string
  tooltipContent?: string
}

const StatCard: FunctionComponent<ComponentProps> = ({className, title, footerText, rating, text, tooltipContent, ...rest}) => {
  return (
    <Card className={cn('stat-card', className)} {...rest}>
      <Card.InnerWrapper>
        <Card.Header>
          <Card.Title className="card-title">
            {title}
            <InteractiveTooltip>
              <p>{tooltipContent}</p>
            </InteractiveTooltip>
          </Card.Title>
        </Card.Header>
      </Card.InnerWrapper>
      <Card.InnerWrapper className="card-content">
        {text && text !== 'undefined' ? <div className="stat-amount">{text}</div> : <SkeletonText className="stat-amount" />}
      </Card.InnerWrapper>
      <Card.Divider />
      <Card.InnerWrapper className="card-footer horizontal-wrapper">
        {footerText && !footerText.startsWith('undefined') ? <p className="footer-text">{footerText}</p> : <SkeletonText className="footer-text" />}
        {rating && rating !== 'undefined' && (
          <p className="footer-rating">
            <Recommend20 />
            {rating}
          </p>
        )}
      </Card.InnerWrapper>
    </Card>
  )
}

export default StatCard
