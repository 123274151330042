import {Duration} from 'luxon'
export {convertNumberToSIFormat} from '../../../utils/helpers'

export const convertToScore = (value: number | undefined): number | unknown => {
  if (value === undefined) return
  if (value > 99 && value <= 100) return 1
  if (value > 95 && value <= 95) return 5
  if (value > 90 && value <= 95) return 10
  if (value > 75 && value <= 90) return 25
  if (value > 50 && value <= 75) return 50
}

export const convertSecondsToShortFormat = (seconds: number): string => {
  const durationObj = Duration.fromObject({years: 0, days: 0, hours: 0, seconds}).normalize().toObject()
  const selectedKey = Object.keys(durationObj).find((key) => durationObj[key] >= 1)
  return (selectedKey && `${durationObj[selectedKey]}${selectedKey.slice(0, 1)}`) || '0'
}

export const convertMillisecondsToShortFormat = (milliseconds: number | undefined): string => {
  const durationObj =
    milliseconds === undefined
      ? Duration.fromObject({years: 0, days: 0, hours: 0}).normalize().toObject()
      : Duration.fromObject({years: 0, days: 1, hours: 0, milliseconds}).normalize().toObject()
  const selectedKey = Object.keys(durationObj).find((key) => durationObj[key] >= 1)
  return (selectedKey && `${durationObj[selectedKey]}${selectedKey.slice(0, 1)}`) || '0'
}

export const convertMillisecondsToNormalFormat = (milliseconds: number | undefined): string => {
  const durationObj =
    milliseconds === undefined
      ? Duration.fromObject({years: 0, days: 0, hours: 0}).normalize().toObject()
      : Duration.fromObject({years: 0, days: 1, hours: 0, milliseconds}).normalize().toObject()
  const selectedKey = Object.keys(durationObj).find((key) => durationObj[key] >= 1)
  return (selectedKey && `${durationObj[selectedKey]} ${selectedKey}`) || '0'
}
