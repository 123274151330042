import React, {FunctionComponent, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import BaseLayout from '../../../layouts/BaseLayout/BaseLayout'
import {MainContentHeader} from '../../../components/layout/MainContentHeader'
import {MainContentWrapper} from '../../../components/layout/MainContentWrapper'
import {setNavigation} from '../../../store/navigation/actions'
import {PageMetadata} from '../../../components/PageMetadata'
import {metadata} from '../../../constants/metadata'

const NotFound: FunctionComponent = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setNavigation({section: 'Home', type: 'main', highlight: ''}))
  }, [])
  return (
    <BaseLayout>
      <PageMetadata {...metadata.notFound} />
      <MainContentHeader title="Page not found" />
      <MainContentWrapper>The page you have attempted to access was not found.</MainContentWrapper>
    </BaseLayout>
  )
}

export default NotFound
