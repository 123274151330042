import React, {FunctionComponent, useState} from 'react'
import {RadioButton, RadioButtonGroup} from 'carbon-components-react'
import _map from 'lodash.map'
import _get from 'lodash.get'
import {MainContentWrapper} from '../../../../../components'
import {OS_NAMES} from '../../../../../constants'
import {JoinMainHeader, NextButton} from '../index'
import {currentOsName, findOsNameData} from '../../../../../constants/osNames'

const Download: FunctionComponent = () => {
  const [selectedOsName, setSelectedOsName] = useState(currentOsName)
  const selectedDownloadName = _get(selectedOsName, 'download')
  const selectedShortName = _get(selectedOsName, 'short')

  const handleRadioButtonSelection = (newSelection: string) => {
    setSelectedOsName(findOsNameData(OS_NAMES, {short: newSelection}))
  }

  return (
    <>
      <JoinMainHeader title="Select platform" className="main-content-header__join" />
      <>
        <MainContentWrapper noVerticalPaddings>
          <RadioButtonGroup defaultSelected={selectedShortName} name="os" orientation="vertical" onChange={handleRadioButtonSelection}>
            {_map(OS_NAMES, ({text: itemLabelText, short: itemShortName}, itemName) => {
              return <RadioButton key={itemName} value={itemShortName} id={`radio-${itemShortName}`} role="group" labelText={itemLabelText} />
            })}
          </RadioButtonGroup>
        </MainContentWrapper>
        <MainContentWrapper>
          <NextButton
            isDownloadableView
            disabled={!selectedShortName}
            redirectTo={`/join/${selectedShortName}`}
            pathState={{prevPath: 'join'}}
            platformNameToDownload={selectedDownloadName}
          />
        </MainContentWrapper>
      </>
    </>
  )
}

export default Download
